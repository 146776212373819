import styled from 'styled-components'

export const BottomSheetImage = styled.img`
  width: 92px;
  display: table;
  margin: 0 auto 36px auto;
`

export const BottomSheetTitle = styled.p`
  font-family: 'Sora';
  font-weight: 600;
  font-size: 1rem;
  max-width: 60%;
  margin-bottom: 12px;
  color: #161616;
  line-height: 1.25rem;
`

export const BottomSheetText = styled.p`
  font-size: 0.875rem;
  color: #6a6c72;
  line-height: 1.063rem;
  display: flex;
`

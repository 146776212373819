import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../../utils/providerSaga'
import {
  IHealthPlansFilter,
  HealthPlanTypes,
  IHealthPlan,
  IHealthPlanDetails,
  IHealthPlanInfo,
  PlanMembershipFeeRequest,
} from './types'

const clearAllHealthPlanState = () => action(HealthPlanTypes.CLEAR_ALL)

const getAllHealthPlansRequest = (data: IHealthPlansFilter) =>
  action(HealthPlanTypes.GET_LIST_HEALTH_PLANS, data)

const getListHealthPlans = (state: RootState) => state.healthPlan.healthPlanList

const setListHealthPlans = (data: IHealthPlan[]) =>
  action(HealthPlanTypes.SET_LIST_HEALTH_PLANS, data)

const getHealthPlanDetailsRequest = (data?: IHealthPlanInfo) =>
  action(HealthPlanTypes.GET_HEALTH_PLAN_DETAILS, data)

const getHealthPlanDetails = (state: RootState) => state.healthPlan.healthPlanDetails

const setHealthPlanDetails = (data: IHealthPlanDetails) =>
  action(HealthPlanTypes.SET_HEALTH_PLAN_DETAILS, data)

const setProvidersFilter = (data: string[]) => action(HealthPlanTypes.SET_PROVIDERS_FILTER, data)

const setHealthFilteredOptions = (data: IHealthPlansFilter) =>
  action(HealthPlanTypes.SET_HEALTH_FILTERED_OPTIONS, data)

const getProvidersFilter = (state: RootState) => state.healthPlan.providersFilter

const getHealthFilteredOptions = (state: RootState) => state.healthPlan.healthPlanFilteredOptions

const setSelectedHealthPlan = (data: IHealthPlan) =>
  action(HealthPlanTypes.SET_SELECTED_HEALTH_PLAN, data)

const getSelectedHealthPlan = (state: RootState) => state.healthPlan.selectedHealthPlan

const getAvailableEffectiveDatesRequest = (data?: SagaWithHistory) =>
  action(HealthPlanTypes.GET_AVAILABLE_EFFECTIVE_DATES, data)

const getAvailableEffectiveDates = (state: RootState) => state.healthPlan.availableEffectiveDates

const setAvailableEffectiveDates = (data: string[]) =>
  action(HealthPlanTypes.SET_AVAILABLE_EFFECTIVE_DATES, data)

const getPlanMembershipFeeRequest = (data?: PlanMembershipFeeRequest) =>
  action(HealthPlanTypes.GET_PLAN_MEMBERSHIP_FEE, data)

export const HealthPlanActions = {
  clearAllHealthPlanState,
  getAllHealthPlansRequest,
  getListHealthPlans,
  setListHealthPlans,
  getHealthPlanDetailsRequest,
  getHealthPlanDetails,
  setHealthPlanDetails,
  setProvidersFilter,
  getProvidersFilter,
  setSelectedHealthPlan,
  getSelectedHealthPlan,
  getAvailableEffectiveDatesRequest,
  getAvailableEffectiveDates,
  setAvailableEffectiveDates,
  getPlanMembershipFeeRequest,
  setHealthFilteredOptions,
  getHealthFilteredOptions,
}

import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import BaseBridge from '../../../config/bridge/BaseBridge'
import HealthPlanService from '../../../services/HealthPlanService'
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import { AddressActions } from '../address/actions'
import { ClassEntityActions } from '../classEntity/actions'
import { LoadingActions } from '../loading/actions'
import { ProposalActions } from '../proposal/actions'
import { HealthPlanActions } from './actions'
import { GetHealthPlansRequest, HealthPlanTypes, IHealthPlansFilter } from './types'

function* getListHealthPlans({ payload }: AnyAction) {
  const loadedPlans = yield* select(HealthPlanActions.getListHealthPlans)
  try {
    yield* put(LoadingActions.show())

    const entity = yield* select(ClassEntityActions.getSelectedClassEntity)
    const address = yield* select(AddressActions.getSelectedAddress)
    const occupation = yield* select(ClassEntityActions.getSelectedOccupation)

    if (entity && address && occupation) {
      const { history, pathname, onFiltered, onError, page, ...filter } = (payload ||
        {}) as IHealthPlansFilter

      const requestPlans: GetHealthPlansRequest = {
        association: entity,
        city: address.city,
        district: address.district,
        page,
        occupation: occupation.name,
        ...filter,
      }

      const response = yield* call(HealthPlanService.getListHealthPlans, requestPlans)

      if (response) {
        yield* put(HealthPlanActions.setProvidersFilter(response.data.providers))

        if (onFiltered) {
          yield* put(HealthPlanActions.setListHealthPlans(response.data.plans))
          yield* call(onFiltered)
        } else {
          const newListPlans = loadedPlans.concat(response.data.plans)
          yield* put(HealthPlanActions.setListHealthPlans(newListPlans))
        }

        yield* put(LoadingActions.hide())

        redirectThroughSaga({ history, pathname })
      } else {
        if (onError) {
          yield* call(onError)
        }

        yield* put(LoadingActions.hide())
      }
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'HealthPlanActionsSaga.getListHealthPlans')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthPlanActionsSaga.getListHealthPlans')
  }
}

function* getHealthPlanDetails({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { planId } = payload

    if (planId) {
      const response = yield* call(HealthPlanService.getHealthPlanDetails, planId)

      yield* put(HealthPlanActions.setHealthPlanDetails(response.data))

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'HealthPlanActionsSaga.getHealthPlanDetails')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthPlanActionsSaga.getHealthPlanDetails')
  }
}

function* getAvailableEffectiveDates({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const proposal = yield* select(ProposalActions.getProposal)

    const response = yield* call(HealthPlanService.getAvailableEffectiveDates, proposal.plan.id)

    yield* put(
      HealthPlanActions.setAvailableEffectiveDates(response.data.availableEffectiveDates || []),
    )

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthPlanActionsSaga.getAvailableEffectiveDates')
  }
}

function* getPlanMembershipFee({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const proposal = yield* select(ProposalActions.getProposal)

    const planId = payload?.planId || proposal?.plan?.id

    BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Chama_Servico_Taxa_Assinatura_Do_Plano', {
      plan_id: planId,
    })
    const response = yield* call(HealthPlanService.getPlanMembershipFee, planId)

    yield* put(ClassEntityActions.setSelectedClassEntity(response.data.association))

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthPlanActionsSaga.getPlanMembershipFee')
  }
}

export default function* watchHealthPlan() {
  yield* takeLatest(HealthPlanTypes.GET_LIST_HEALTH_PLANS, getListHealthPlans)
  yield* takeLatest(HealthPlanTypes.GET_HEALTH_PLAN_DETAILS, getHealthPlanDetails)
  yield* takeLatest(HealthPlanTypes.GET_AVAILABLE_EFFECTIVE_DATES, getAvailableEffectiveDates)
  yield* takeLatest(HealthPlanTypes.GET_PLAN_MEMBERSHIP_FEE, getPlanMembershipFee)
}

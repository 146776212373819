import { call, put, takeLatest } from 'typed-redux-saga'

import BaseBridge from '../../../config/bridge/BaseBridge'
import UserService from '../../../services/UserService'
import { UserActions } from './actions'
import { AddressActions } from '../address/actions'
import { ClassUserTypes } from './types'
import { errorHandlingSaga } from '../../utils/providerSaga'

function* getUserDetails() {
  try {
    const userInfo = yield* call(BaseBridge.getUserInfo)

    const response = yield* call(UserService.getUserDetails)

    const userDetails = { ...response.data, cpf: userInfo.cpf }

    yield* put(UserActions.setUserDetails(userDetails))

    yield* put(AddressActions.setAddressList(userDetails.address))
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'UserActionsSaga.getUserDetails')
  }
}

export default function* watchUser() {
  yield* takeLatest(ClassUserTypes.GET_USER_DETAILS, getUserDetails)
}

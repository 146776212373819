import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIProgressBar,
  InterUIIcon,
  InterUISeparator,
  InterUIButton,
  InterUIBottomSheet,
  InterUIListDescription,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PMedium, StepHeading, P } from '../../../../styles/commons'
import { Colors } from '../../../../styles/colors'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as SimulationRoutes } from '../../../simulation/routes'
import { TypesDocumentationRoutes as DocumentationRoutes } from '../../routes'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const DocumentationList: React.FC = () => {
  const history = useHistory()

  const [bottomSheet, setBottomSheet] = useState<boolean>(false)

  const primaryColor = useTheme().colors.primary.A500

  const documentationsList = useSelector(DPSActions.getDocumentsList)

  useBasePage({
    navbarTitle: PageTitles.DOCUMENTATION,
    backNavigationHandler: history.goBack,
  })

  const haveAllDocs = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Documentacao_T_Botao', {
      content_name: 'Tenho todos os documentos',
      content_id: 'Tenho todos os documentos',
    })

    history.push(DocumentationRoutes.SHIPPING_TIPS)
  }

  const needGetDocs = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Documentacao_T_Botao', {
      content_name: 'Ainda preciso pegar os documentos',
      content_id: 'Ainda preciso pegar os documentos',
    })

    setBottomSheet(true)
  }

  const backToHome = () => {
    history.push(SimulationRoutes.SIMULATION)
  }

  const renderDocumentations = documentationsList.map((item, index) => {
    const key = `documentation-${index}`

    const iconCheck = <InterUIIcon name="check-underlined" size="sm" color={primaryColor} />

    return (
      <React.Fragment key={key}>
        <InterUIListDescription margin="0 0 16px" iconLeft={iconCheck} role="group">
          <PMedium scale={400} marginBottom="0">
            {item.title}
          </PMedium>
        </InterUIListDescription>
      </React.Fragment>
    )
  })

  const stickyFooter = (
    <>
      <InterUIContainer>
        <InterUIButton alignContent="space-between" margin="0px 0px 16px 0px" onClick={haveAllDocs}>
          <p>Tenho todos os documentos</p>

          <InterUIIcon name="arrow-right" size="md" color={Colors.white} />
        </InterUIButton>

        <InterUIButton alignContent="space-between" onClick={needGetDocs} variant="secondary">
          <p>Ainda preciso pegar os documentos</p>

          <InterUIIcon name="arrow-right" size="md" color={Colors.interOrange} />
        </InterUIButton>
      </InterUIContainer>
    </>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="70%" aria-valuenow={2} aria-valuemin={1} aria-valuemax={3} />

        <InterUIContainer>
          <InterUIContainer margin="63px 0 32px">
            <StepHeading>Chegou a hora de enviar os seguintes documentos:</StepHeading>
          </InterUIContainer>

          <InterUISeparator variant="small" />

          <InterUIContainer margin="24px 0">
            <P bold marginBottom="24px">
              Documentos obrigatórios
            </P>

            {renderDocumentations}
          </InterUIContainer>
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
        title="Ainda preciso pegar os documentos"
      >
        <PMedium scale={400}>
          Vamos salvar o seu progresso e, quando você tiver os documentos, voltaremos nesse mesmo
          passo, combinado?
        </PMedium>
        <Button margin="20px 0" onClick={backToHome}>
          Entendi
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import React from 'react'

import { useTheme } from 'styled-components'

import { InterUIContainer, InterUIIcon } from '@interco/inter-ui-react-lib'

import BaseBridge from '../../config/bridge/BaseBridge'
import { H1, Hero, PMedium } from '../../styles/commons'
import { BtnBack, ErrorContainer } from '../Error/Error.styles'
import HeroSecondImg from '../../assets/img/afro-personas-error.png'

export const FallbackError: React.FC = () => {
  const theme = useTheme().colors

  const goBack = () => BaseBridge.requestGoBack()

  return (
    <ErrorContainer>
      <InterUIContainer>
        <BtnBack
          type="button"
          onClick={goBack}
          aria-label="Botão voltar erro"
          data-testid="home-button"
        >
          <InterUIIcon name="arrow-left" size="md" color={theme.primary.A500} />
        </BtnBack>

        <Hero>
          <img src={HeroSecondImg} alt="Personas" />
        </Hero>

        <H1 marginBottom="8px">Houve um erro por aqui</H1>

        <PMedium scale={400}>
          No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns
          minutos.
        </PMedium>
      </InterUIContainer>
    </ErrorContainer>
  )
}

import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIRadio, InterUIContainer } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { PMedium, PSmall } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const UpdateInitialDate: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [effectiveDate, setEffectiveDate] = useState('')

  const availableEffectiveDates = useSelector(HealthPlanActions.getAvailableEffectiveDates)

  useBasePage({
    navbarTitle: PageTitles.UPDATE_INITIAL_DATE,
    backNavigationHandler: history.goBack,
  })

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_DataInicio_T_VoltarParHome')

    dispatch(ProposalActions.setEffectiveDate(effectiveDate))

    history.goBack()
  }

  const stickyFooter = (
    <Button onClick={onContinue} disabled={!effectiveDate}>
      Voltar para home
    </Button>
  )

  const renderAvailableDates = availableEffectiveDates.map((item, index) => {
    const key = `date-${index}`

    return (
      <InterUIRadio
        variant="choose-item"
        id={key}
        name="bond"
        value={item}
        onChange={(e) => setEffectiveDate(e.target.value)}
        checked={effectiveDate === item}
        key={key}
      >
        <PMedium bold marginBottom="0">
          {item}
        </PMedium>
      </InterUIRadio>
    )
  })

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <PSmall scale={400} marginBottom="38px">
        Escolha uma nova data de vigência, quando o seu plano começará a valer. A cada mês, os
        débitos da mensalidade serão realizados nesse mesmo dia.
      </PSmall>

      {renderAvailableDates}
    </InterUIContainer>
  )
}

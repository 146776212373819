import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import BaseBridge from '../../../../config/bridge/BaseBridge'
import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { PMedium, Hero, H1 } from '../../../../styles/commons'
import { ResolvedContainer } from './ResolvedPendings.styles'
import { PageTitles } from '../../../../enums/pageTitles'
import HeroImg from '../../../../assets/img/afro-personas-woman-success.png'

export const ResolvedPendings: React.FC = () => {
  const proposal = useSelector(ProposalActions.getProposal)

  useEffect(() => {
    BaseBridge.requestAnalytics('C_PlanoSaude_DC_Sucesso', {
      content_name: proposal?.plan?.displayName,
    })
  }, [])

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    hideHomeBtn: true,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  const goToNativeHome = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_T_VerMeusContratos', {
      content_name: proposal?.plan?.displayName,
    })

    BaseBridge.requestGoBack()
  }

  const stickyFooter = (
    <>
      <Button onClick={goToNativeHome}>
        <p>Ver meus contratos</p>
      </Button>
    </>
  )

  return (
    <>
      <ResolvedContainer>
        <InterUIContainer stickyFooter={stickyFooter}>
          <Hero>
            <img src={HeroImg} alt="Personas" />
          </Hero>

          <H1 marginBottom="8px">Tudo certo!</H1>

          <PMedium scale={400}>
            Recebemos a resolução das pendências. Agora, a equipe da Qualicorp fará a análise.
          </PMedium>
          <PMedium scale={400}>Você receberá o retorno por Whatsapp em até 3 dias úteis.</PMedium>
        </InterUIContainer>
      </ResolvedContainer>
    </>
  )
}

import BaseService from '../config/bridge/BaseService'
import { IResolutionOfPending, IPending } from '../store/ducks/pending/types'

export default class PendingService {
  static async getPending() {
    return BaseService.doExecute<IPending[]>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/pendencias`,
    })
  }

  static async sendPendingResolution(data: IResolutionOfPending) {
    return BaseService.doExecute({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/pendencias`,
      data,
    })
  }
}

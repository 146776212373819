import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { PathParamsTypes } from './types'

const getPrefixPathParams = (state: RootState) => state.pathparams.prefixPathParams

const setPrefixPathParams = (data: string) => action(PathParamsTypes.SET_PREFIX_PATH_PARAMS, data)

export const PathParamsActions = {
  getPrefixPathParams,
  setPrefixPathParams,
}

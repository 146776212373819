import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIIcon,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { H1, H3, Hero, PMedium } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { convertDateStringtoHuman, numberFormat } from '../../../../utils/commons'
import Personas from '../../../../assets/img/afro-personas-success.png'

export const PaymentReceipt: React.FC = () => {
  const primaryColor = useTheme().colors.primary.A500

  const bond = useSelector(ClassEntityActions.getSelectedBond)
  const entity = useSelector(ClassEntityActions.getSelectedClassEntity)
  const proposal = useSelector(ProposalActions.getProposal)
  const effectiveDate = useSelector(ProposalActions.getEffectiveDate)
  const checkoutProposal = useSelector(ProposalActions.getCheckoutProposal)

  const [valuePlan, setValuePlan] = useState(0)

  useBasePage({
    navbarTitle: PageTitles.RECEIPT,
    backNavigationHandler: BaseBridge.requestGoBack,
    hideHomeBtn: true,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Comprovante_DC', {
      content_id: proposal.plan.displayName,
      content_name: 'account-debit',
    })
    const membershipFee = bond === 'N' ? entity?.membershipFee || 0 : 0
    const initialValuePlan = +proposal?.plan?.price || 0

    setValuePlan(initialValuePlan + membershipFee)
  }, [])

  const iconCheck = <InterUIIcon name="check-underlined" size="sm" color={primaryColor} />

  const viewContractedInsurance = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Comprovante_T_Botao', {
      content_id: proposal.plan.displayName,
      content_name: 'account-debit',
    })

    BaseBridge.openDeepLink('bancointer://seguros')
  }

  const stickyFooter = (
    <Button margin="0 24px 24px" onClick={viewContractedInsurance}>
      Ver seguros contratados
    </Button>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <Hero>
        <img src={Personas} alt="Personas confirmando a contratação do plano" />
      </Hero>

      <InterUIContainer margin="32px 24px">
        <H1 marginBottom="8px">Plano de saúde solicitado</H1>

        <PMedium scale={400} marginBottom="16px">
          A nossa parceira Qualicorp analisará suas informações e, caso necessário, entrará em
          contato.
        </PMedium>

        <PMedium scale={400}>
          Estando tudo certo, a apólice do seu plano será emitida e seu plano de saúde começará a
          valer no dia <strong>{effectiveDate}</strong>.
        </PMedium>
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px">
        <H3 marginBottom="16px">Dados da compra</H3>

        <PMedium scale={400} marginBottom="4px">
          Plano
        </PMedium>

        <PMedium bold marginBottom="16px">
          {proposal?.plan?.displayName}
        </PMedium>

        <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
          <PMedium scale={400} marginBottom="0">
            {proposal?.plan?.participation ? 'Com' : 'Sem'} coparticipação
          </PMedium>
        </InterUIListDescription>

        <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
          <PMedium scale={400} marginBottom="0">
            Acomodação em {proposal?.plan?.accommodation?.toLowerCase()}
          </PMedium>
        </InterUIListDescription>

        <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
          <PMedium scale={400} marginBottom="0">
            {proposal?.plan?.reimbursable ? 'Com' : 'Sem'} reembolso
          </PMedium>
        </InterUIListDescription>

        <PMedium scale={400} marginBottom="4px">
          Valor do plano
        </PMedium>

        <PMedium bold marginBottom="16px">
          {numberFormat(+proposal?.plan?.price || 0)}/mês
        </PMedium>

        {bond === 'N' && (
          <>
            <PMedium scale={400} marginBottom="4px">
              Taxa de filiação
            </PMedium>

            <PMedium bold marginBottom="16px">
              {entity?.membershipFee ? `${numberFormat(entity.membershipFee)}/mês` : 'Gratuita'}
            </PMedium>
          </>
        )}

        <PMedium scale={400} marginBottom="4px">
          Valor total
        </PMedium>

        <PMedium bold marginBottom="16px" aria-label="Valor total a ser pago">
          {numberFormat(valuePlan)}/mês
        </PMedium>

        <PMedium scale={400} marginBottom="4px">
          Data de início de vigência
        </PMedium>

        <PMedium bold marginBottom="16px">
          {effectiveDate}
        </PMedium>

        <InterUISeparator variant="small" />

        <H3 margin="24px 0 16px">Detalhes da solicitação</H3>

        <PMedium scale={400} marginBottom="4px">
          Protocolo
        </PMedium>

        <PMedium bold marginBottom="24px" aria-label="Numero Protocolo">
          {checkoutProposal.protocol}
        </PMedium>

        <PMedium scale={400} marginBottom="4px">
          Emitido em
        </PMedium>

        <PMedium bold marginBottom="0" aria-label="Data da Transmissao">
          {convertDateStringtoHuman(checkoutProposal.transmitted)}
        </PMedium>
      </InterUIContainer>
    </InterUIContainer>
  )
}

import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import './transition-style.css'

import { NavbarActions } from '../store/ducks/navbar/actions'
import { MixedRoutes } from '../modules/mixedRoutes'
import { scrollToTop } from '../utils/commons'
import { PathQueryParams } from './pathQueryParams'

const Routes: React.FC = () => {
  const goTo = useSelector(NavbarActions.getDirection)

  const [direction, setDirection] = useState('pageSliderLeft')

  const handler = PathQueryParams()

  useEffect(() => {
    handler()
  }, [])

  useEffect(() => {
    setDirection(goTo === 'backward' ? 'pageSliderRight' : 'pageSliderLeft')
  }, [goTo])

  const scrollTop = () => {
    scrollToTop()
  }

  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            onEnter={scrollTop}
            timeout={500}
            key={location.key}
            classNames={direction}
          >
            <Switch>
              <Redirect exact from="/" to="/simulation" />

              <MixedRoutes />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    />
  )
}

export default withRouter(Routes)

import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { AddressTypes, IAddress, ISearchAddressRequest, UpdateAddressRequest } from './types'

const setAddressList = (data: IAddress[]) => action(AddressTypes.SET_ADDRESS_LIST, data)

const getAddressList = (state: RootState) => state.address.addressList

const getSelectedAddress = (state: RootState) => state.address.selectedAddress

const setSelectedAddress = (data: IAddress | null) =>
  action(AddressTypes.SET_SELECTED_ADDRESS, data)

const updateAddressRequest = (data: UpdateAddressRequest) =>
  action(AddressTypes.UPDATE_ADDRESS, data)

const getAddressByCep = (data: ISearchAddressRequest | null) =>
  action(AddressTypes.GET_ADDRESS_BY_CEP, data)

const setSearchedAddress = (data: IAddress | null) =>
  action(AddressTypes.SET_SEARCHED_ADDRESS, data)

const getSearchedAddress = (state: RootState) => state.address.searchedAddress

export const AddressActions = {
  getAddressList,
  setAddressList,
  getSelectedAddress,
  setSelectedAddress,
  updateAddressRequest,
  getAddressByCep,
  setSearchedAddress,
  getSearchedAddress,
}

import { SagaWithHistory } from '../../utils/providerSaga'
import { IAddress } from '../address/types'
import { ICheckoutProposal } from '../proposal/types'

export enum PendingTypes {
  GET_PENDING_REQUEST = '@pending/GET_PENDING_REQUEST',
  GET_PENDING = '@pending/GET_PENDING',
  SET_PENDING = '@pending/SET_PENDING',
  SEND_PENDING_RESOLUTION = '@pending/SEND_PENDING_RESOLUTION',
  SEND_RESOLUTION_OF_PENDING = '@pending/SEND_RESOLUTION_OF_PENDING',
  LOAD_CUSTOMER_PROPOSAL_REQUEST = '@pending/LOAD_CUSTOMER_PROPOSAL_REQUEST',
}

export interface IPending {
  pending: Array<string>
  type: string
}

export interface IPendingRequest {
  status: number
  data: IPending[]
}

export interface IResolutionOfPending {
  name: string
  mothersName: string
  gender: string
  maritalStatus: string
  rg: string
  address: IAddress
  email: string
  effectiveDate: string
}

export interface IPendingResolutionRequest extends SagaWithHistory {
  onTransmission?: (data: ICheckoutProposal) => void
}

export interface PendingState {
  pendings: IPending[]
  resolutionOfPending: IResolutionOfPending
}

import { SagaWithHistory } from '../../utils/providerSaga'

export enum ClassEntityTypes {
  GET_LIST_OCCUPATIONS = `@class_entity/GET_LIST_OCCUPATIONS`,
  SET_LIST_OCCUPATIONS = `@class_entity/SET_LIST_OCCUPATIONS`,

  GET_LIST_CLASS_ENTITIES = `@class_entity/GET_LIST_CLASS_ENTITIES`,
  SET_LIST_CLASS_ENTITIES = `@class_entity/SET_LIST_CLASS_ENTITIES`,

  SET_SELECTED_BOND = `@class_entity/SET_SELECTED_BOND`,
  SET_SELECTED_OCCUPATION = `@class_entity/SET_SELECTED_OCCUPATION`,
  SET_SELECTED_CLASS_ENTITY = `@class_entity/SET_SELECTED_CLASS_ENTITY`,

  GET_PROPOSAL_CLASS_ENTITY = `@class_entity/GET_PROPOSAL_CLASS_ENTITY`,
}

export interface GetOccupationRequest {
  district: string
  city: string
}

export interface GetEntitiesRequest extends SagaWithHistory {
  digitalMembership?: boolean
}

export interface IOccupation {
  name: string
  associations?: IEntity[]
}

export interface OccupationsResponse {
  occupations: IOccupation[]
}

export interface IEntity {
  name: string
  tradingName: string
  membershipFee?: number
  frequency?: string
  minMembershipFee?: number
  maxMembershipFee?: number
}

export interface EntitiesResponse {
  associations: IEntity[]
}

export interface ClassEntityState {
  readonly selectedBond?: string

  readonly listOccupations: IOccupation[]
  readonly selectedOccupation?: IOccupation

  readonly listEntities: IEntity[]
  readonly selectedEntity?: IEntity
}

export interface IClassEntityResponse {
  association: IEntity
}

export interface IClassEntityInfo {
  planId: number
  history?: History
  pathname?: string
}

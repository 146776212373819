import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIContainer, InterUIIcon, InterUIBottomSheet } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { NavbarActions } from '../../../../store/ducks/navbar/actions'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { PMedium, Hero, H1 } from '../../../../styles/commons'
import { Colors } from '../../../../styles/colors'
import { PageTitles } from '../../../../enums/pageTitles'
import { PageDirections } from '../../../../routes/pageDirections'
import HeroImg from '../../../../assets/img/afro-personas-doubt.png'
import { isNoneOfThese } from '../../../../utils/commons'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const WithoutCover: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [bottomSheet, setBottomSheet] = useState<boolean>(false)

  const classEntity = useSelector(ClassEntityActions.getSelectedClassEntity)

  useBasePage({
    navbarTitle: PageTitles.CLASS_ENTITY,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics('C_PlanoSaude_EntidaClasse_DC_CatSemCober')
  }, [])

  const btnLabel = () =>
    isNoneOfThese(classEntity?.name) ? `Escolher nova entidade` : `Editar categoria`

  const btnEdit = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_EntidaClasse_T_Botao', {
      content_name: btnLabel(),
    })
    dispatch(NavbarActions.setDirection(PageDirections.BACKWARD))

    history.goBack()

    setTimeout(() => {
      dispatch(NavbarActions.setDirection(PageDirections.FORWARD))
    }, 550)
  }

  const stickyFooter = (
    <>
      <Button alignContent="space-between" margin="0px 0px 16px 0px" onClick={btnEdit}>
        <p>{btnLabel()}</p>

        <InterUIIcon name="arrow-right" size="md" color={Colors.white} />
      </Button>

      <Button alignContent="space-between" onClick={() => setBottomSheet(true)} variant="secondary">
        <p>Ver outros seguros</p>

        <InterUIIcon name="arrow-right" size="md" color={Colors.interOrange} />
      </Button>
    </>
  )

  const renderContentVariant = isNoneOfThese(classEntity?.name) ? (
    <>
      <H1 marginBottom="8px">Entidade sem cobertura</H1>

      <PMedium scale={400}>
        No momento, nossas opções de plano de saúde são oferecidas através das entidades de classe
        listadas.
      </PMedium>
      <PMedium scale={400}>
        Você pode escolher uma nova entidade à qual se vincular ou conhecer nossas outras opções de
        seguros.
      </PMedium>
    </>
  ) : (
    <>
      <H1 marginBottom="8px">Categoria sem cobertura</H1>

      <PMedium scale={400}>
        No momento, nossas opções de plano de saúde cobrem as categorias profissionais listadas.
      </PMedium>
      <PMedium scale={400}>
        Você pode editar sua categoria profissional ou conhecer nossas outras opções de seguro.
      </PMedium>
    </>
  )

  return (
    <>
      <InterUIContainer stickyFooter={stickyFooter}>
        <Hero>
          <img src={HeroImg} alt="Personas" />
        </Hero>

        {renderContentVariant}
      </InterUIContainer>

      <InterUIBottomSheet
        title="Quer mesmo sair?"
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
      >
        <PMedium scale={400}>
          Saindo agora, você perderá todo o progresso na contratação do plano.
        </PMedium>

        <Button margin="20px 0" onClick={() => setBottomSheet(false)}>
          Continuar minha contratação
        </Button>

        <Button
          margin="20px 0"
          variant="secondary"
          onClick={() => BaseBridge.openDeepLink('bancointer://seguros')}
        >
          Sair da contratação
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import { IWbISafeResponse } from '@interco/inter-webview-bridge'

import BaseService from '../config/bridge/BaseService'
import { IEntity } from '../store/ducks/classEntity/types'
import {
  BindingDocsResponse,
  CreateAssociationSignUpRequest,
  CreateFiliationRequest,
  InfoToBondResponse,
  CheckFiliationResponse,
} from '../store/ducks/binding/types'

export default class BindingService {
  static async getDocumentsList() {
    return BaseService.doExecute<BindingDocsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/documentos?grupo=Elegibilidade`,
    })
  }

  static async getDynamicQuestions(cpf: string, entity: IEntity) {
    const headers = {
      'x-inter-cpf': cpf,
    }

    return BaseService.doExecute<InfoToBondResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/entidades/${entity.tradingName}/campos`,
      headers,
    })
  }

  static async createFiliation(
    isafeResponse: IWbISafeResponse,
    association: string,
    data: CreateFiliationRequest,
  ) {
    const headers = {
      'x-isafe-token': isafeResponse.authenticationToken,
      'x-isafe-type': `${isafeResponse.authenticationType}`,
      'x-isafe-value': isafeResponse.authenticationValue,
    }

    const options = {
      timeout: 60,
    }

    try {
      return await BaseService.doExecute<void>({
        method: 'POST',
        endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/entidades/${association}/filiacoes`,
        headers,
        data,
        options,
      })
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        return error
      }
      return Promise.reject(error)
    }
  }

  static async checkFiliation(association: string) {
    return BaseService.doExecute<CheckFiliationResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/entidades/${association}/filiacoes`,
    })
  }

  static async createAssociationSignUp(association: string, data: CreateAssociationSignUpRequest) {
    return BaseService.doExecute<void>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/entidades/${association}/assinaturas`,
      data,
    })
  }
}

import { call, put, takeLatest } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import AddressService from '../../../services/AddressService'
import { AddressTypes, IAddress } from './types'
import { AddressActions } from './actions'
import { LoadingActions } from '../loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'

function* getAddressByCep({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { zipcode, syncActionWithSaga } = payload

    const response = yield* call(AddressService.getAddressByCep, zipcode)

    const addressData = response.data?.address as IAddress

    if (addressData?.zipcode) {
      addressData.zipcode = addressData.zipcode.replace(/\D/g, '')
    }

    yield* put(AddressActions.setSearchedAddress(addressData))

    if (syncActionWithSaga) {
      yield* call(syncActionWithSaga, addressData)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AddressActionsSaga.getAddressByCep')
  }
}

function* updateAddress({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    yield* call(AddressService.updateAddress, payload.address)

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'AddressActionsSaga.updateAddress')
  }
}

export default function* watchAddress() {
  yield* takeLatest(AddressTypes.GET_ADDRESS_BY_CEP, getAddressByCep)

  yield* takeLatest(AddressTypes.UPDATE_ADDRESS, updateAddress)
}

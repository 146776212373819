import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIProgressBar,
  InterUITag,
  InterUISeparator,
  InterUIBottomSheet,
  InterUIAlert,
  InterUILoading,
  InterUIIcon,
} from '@interco/inter-ui-react-lib'

import { PlanCard } from '../../../../components'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { IHealthPlan, IHealthPlansFilter } from '../../../../store/ducks/healthPlan/types'
import { useBasePage } from '../../../../hooks/useBasePage'
import { HealthPlanFilter } from '../../components'
import { ContainerFullPage, PSmall, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as Routes } from '../../routes'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { LoadingActions } from '../../../../store/ducks/loading/actions'
import { ContainerSelect, LoadingContainer, AroundIcon } from './HealthPlanList.styles'

export const HealthPlanList: React.FC = () => {
  const [bottomSheet, setBottomSheet] = useState<boolean>(false)
  const [numberFilters, setNumberFilters] = useState<number>(0)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({} as IHealthPlansFilter)

  const dispatch = useDispatch()
  const theme = useTheme().colors
  const history = useHistory()

  const entity = useSelector(ClassEntityActions.getSelectedClassEntity)
  const healthPlanList = useSelector(HealthPlanActions.getListHealthPlans)
  const filteredOptions = useSelector(HealthPlanActions.getHealthFilteredOptions)

  const loading = useSelector(LoadingActions.get)

  const isCashback = false

  useEffect(() => {
    if (filteredOptions) {
      filteredOptions.page = 1
      setNumberFilters(filteredOptions?.countFiltered || 0)
      setFilters(filteredOptions)
    }
    if (page > 1) {
      getMoreHealthPlans()
    }
  }, [page])

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setPage((currentValue) => currentValue + 1)
      }
    })
    const element: Element | null = document.querySelector('#endOfPlanList')

    if (element !== null) {
      intersectionObserver.observe(element)
    }

    return () => intersectionObserver.disconnect()
  }, [])

  const getMoreHealthPlans = () => {
    filters.page = page
    dispatch(HealthPlanActions.getAllHealthPlansRequest(filters))
  }

  useBasePage({
    navbarTitle: PageTitles.HEALTH_PLAN,
    backNavigationHandler: history.goBack,
  })

  const hideBottomSheet = () => setBottomSheet(false)

  const onClickedDetail = (item: IHealthPlan) => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Planos_T_VerDetalhes')

    dispatch(HealthPlanActions.setSelectedHealthPlan(item))

    dispatch(
      HealthPlanActions.getHealthPlanDetailsRequest({
        planId: item.id,
        history,
        pathname: Routes.HEALTH_PLAN_DETAILS,
      }),
    )
  }

  const onFilter = (filter: IHealthPlansFilter) => {
    const amount = Object.keys(filter)?.filter((e) => {
      const key = e as keyof IHealthPlansFilter
      const hasToCount = key.toString() !== 'page' && filter[key]

      if (
        hasToCount &&
        Object.prototype.toString.call(hasToCount) === '[object Array]' &&
        (filter[key] as Array<string>).length === 0
      ) {
        filter[key] = undefined
        return false
      }

      return hasToCount
    })?.length

    filter.countFiltered = amount
    setFilters(filter)

    dispatch(HealthPlanActions.setHealthFilteredOptions(filter))
    setNumberFilters(amount)

    dispatch(
      HealthPlanActions.getAllHealthPlansRequest({
        ...filter,
        onFiltered: hideBottomSheet,
      }),
    )
    setPage(1)
  }

  const planListLength = healthPlanList.length - 1

  const renderHealthPlans = healthPlanList.map((item, index) => {
    const showSeparator = planListLength !== index

    return (
      <React.Fragment key={item.id}>
        <PlanCard
          plan={item}
          onClickedDetail={() => onClickedDetail(item)}
          showBadges={numberFilters > 0}
          hasCashback
        />

        {showSeparator && <InterUISeparator variant="small" />}
      </React.Fragment>
    )
  })

  const emptyListAlert = (
    <InterUIAlert type="attention" margin="32px 24px">
      <PSmall bold marginBottom="4px">
        Não encontramos nenhum plano com as preferências que você escolheu
      </PSmall>

      <PSmall marginBottom="0">
        Você pode alterar as suas preferências editando os filtros da sua busca
      </PSmall>
    </InterUIAlert>
  )

  const cashbackAlert = (
    <>
      <ContainerSelect data-testid="cashback-container">
        <AroundIcon>
          <InterUIIcon
            name="cashback"
            className="cashback-icon"
            size="md"
            color={theme.feedback.success.A500}
          />
        </AroundIcon>

        <span className="cashback-message">Você ganhará 10% de cashback na primeira parcela</span>
      </ContainerSelect>
    </>
  )

  return (
    <ContainerFullPage>
      <InterUIProgressBar progress="100%" />

      <InterUIContainer margin="24px 24px 32px 24px">
        <PSmall scale={300} bold>
          Passo 3/3
        </PSmall>

        <StepHeading>
          {`Confira os detalhes dos planos coletivos disponíveis pela entidade ${entity?.tradingName}`}
        </StepHeading>

        <InterUITag
          variant="action-button"
          margin="0px 8px 0px 0px"
          onClick={() => setBottomSheet(true)}
          counter={numberFilters || undefined}
        >
          Filtros
        </InterUITag>
      </InterUIContainer>

      <InterUIContainer margin="0 24px">
        <InterUISeparator variant="small" />
      </InterUIContainer>

      {healthPlanList.length === 0 && !loading ? emptyListAlert : isCashback && cashbackAlert}

      <InterUIContainer margin="32px 24px 24px">{renderHealthPlans}</InterUIContainer>

      <div id="endOfPlanList" style={{ height: '15px' }} />

      {loading && (
        <LoadingContainer>
          <InterUILoading size="ld" />
        </LoadingContainer>
      )}

      <InterUIBottomSheet
        title="Filtros"
        toggle={bottomSheet}
        onHide={hideBottomSheet}
        link="Fechar"
        onClickedLink={hideBottomSheet}
      >
        <HealthPlanFilter onFilter={onFilter} />
      </InterUIBottomSheet>
    </ContainerFullPage>
  )
}

/**
 * Rotas disponíveis no fluxo de pendencia.
 */
export enum TypesPendingRoutes {
  PENDING = '/pending',
  CHECK_PENDINGS = '/pending/check',
  UPDATE_INITIAL_DATE = '/pending/check/update-initial-date',
  UPDATE_ADDRESS = '/pending/check/update-address',
  RESOLVED_PENDINGS = '/pending/check/resolved-pendings',
}

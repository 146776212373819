import styled from 'styled-components'

/**
 * Div principal.
 */
export const PdfContainer = styled.div<{ width: string; height?: string }>`
  overflow-y: scroll;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`

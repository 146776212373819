import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { AddressTypes } from '../../../enums/AddressTypes'
import ClassEntityService from '../../../services/ClassEntityService'
import HealthPlanService from '../../../services/HealthPlanService'
import PendingService from '../../../services/PendingService'
import ProposalService from '../../../services/ProposalService'
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import { AddressActions } from '../address/actions'
import { IAddress } from '../address/types'
import { ClassEntityActions } from '../classEntity/actions'
import { DPSActions } from '../dps/actions'
import { HealthPlanActions } from '../healthPlan/actions'
import { LoadingActions } from '../loading/actions'
import { ProposalActions } from '../proposal/actions'
import { PendingActions } from './actions'
import { PendingTypes } from './types'

function* getPendings({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(PendingService.getPending)

    yield* put(PendingActions.setPending(response.data))

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PendingActionsSaga.getPendings')
  }
}

function* sendPendingResolution({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const documents = yield* select(DPSActions.getDocumentsAttached)
    const address = yield* select(AddressActions.getSelectedAddress)
    const effectiveDate = yield* select(ProposalActions.getEffectiveDate)
    const resolution = yield* select(PendingActions.getResolutionOfPending)

    if (address) {
      if (documents?.length > 0 && documents[0]?.files?.length > 0) {
        yield* put(DPSActions.sendDocumentsAttachedRequest())
      }

      yield* call(PendingService.sendPendingResolution, {
        ...resolution,
        address,
        effectiveDate,
      })

      yield* put(ProposalActions.getCheckoutProposalRequest(payload))
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'PendingActionsSaga.sendPendingResolution')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PendingActionsSaga.sendPendingResolution')
  }
}

function* loadCustomerProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const resPending = yield* call(PendingService.getPending)

    yield* put(PendingActions.setPending(resPending.data))

    const resProposal = yield* call(ProposalService.getProposal)

    yield* put(ProposalActions.setProposal(resProposal.data))

    const proposal = resProposal.data
    const planId = proposal?.plan?.id
    const addressList = proposal?.insuranceHolder?.address || []
    let selectedAddress: IAddress | null = null

    const addressFormat = addressList.map((item) => {
      const address = item

      address.zipcode = address.zipcode.replace(/\D/g, '')

      if (address.typeAddress === AddressTypes.RESIDENTIAL) {
        selectedAddress = address
      }

      return address
    })

    yield* put(AddressActions.setAddressList(addressFormat))

    yield* put(AddressActions.setSelectedAddress(selectedAddress))

    const resDetails = yield* call(HealthPlanService.getHealthPlanDetails, planId)

    yield* put(HealthPlanActions.setHealthPlanDetails(resDetails.data))

    const resEntity = yield* call(ClassEntityService.getClassEntityByPlanId, planId)

    yield* put(ClassEntityActions.setSelectedClassEntity(resEntity.data.association))

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PendingActionsSaga.loadCustomerProposal')
  }
}

export default function* watchPending() {
  yield* takeLatest(PendingTypes.GET_PENDING_REQUEST, getPendings)
  yield* takeLatest(PendingTypes.SEND_PENDING_RESOLUTION, sendPendingResolution)
  yield* takeLatest(PendingTypes.LOAD_CUSTOMER_PROPOSAL_REQUEST, loadCustomerProposal)
}

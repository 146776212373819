import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUIContainer,
  InterUIIcon,
  InterUILoading,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { Button, PlanDetails } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { PageTitles } from '../../../../enums/pageTitles'
import { useBasePage } from '../../../../hooks/useBasePage'
import { AddressActions } from '../../../../store/ducks/address/actions'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { PendingActions } from '../../../../store/ducks/pending/actions'
import { IResolutionOfPending } from '../../../../store/ducks/pending/types'
import { P, PMedium } from '../../../../styles/commons'
import { LoadContainer } from '../../../payment/pages/ProposalContract/ProposalContract.styles'
import { TypesPaymentRoutes } from '../../../payment/routes'
import { Flex, PhoneNumber } from './HealthPlanDetails.styles'
import { AddressTypes } from '../../../../enums/AddressTypes'
import { TypesPendingRoutes as Routes } from '../../routes'
import { ProposalStatus } from '../../../../enums/ProposalStatus'

export const HealthPlanDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const primaryColor = useTheme().colors.primary.A500

  const proposal = useSelector(ProposalActions.getProposal)
  const addressList = useSelector(AddressActions.getAddressList) || []
  const healthPlan = useSelector(HealthPlanActions.getHealthPlanDetails)
  const pendingList = useSelector(PendingActions.getPending)
  const entity = useSelector(ClassEntityActions.getSelectedClassEntity)

  const [bottomSheet, setBottomSheet] = useState(false)

  useBasePage({
    navbarTitle: PageTitles.HEALTH_PLAN,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  useEffect(() => {
    dispatch(PendingActions.loadCustomerProposalRequest())
  }, [dispatch])

  const openProposalContractPdf = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Home_T_ContratoPlano', {
      content_name: proposal.plan.displayName,
    })

    history.push(TypesPaymentRoutes.PROPOSAL_CONTRACT)
  }

  const cancelHealthPlan = (): void => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Cancelar_T_Botao', {
      content_name: proposal.plan.displayName,
      content_id: 'Cancelar Plano',
    })

    dispatch(
      ProposalActions.cancelProposalRequest({
        goToNative: true,
      }),
    )
  }

  const goToPendingPage = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Home_T_ResolverPendencia', {
      content_name: proposal.plan.displayName,
    })

    dispatch(PendingActions.setResolutionOfPending({} as IResolutionOfPending))

    dispatch(
      AddressActions.setSelectedAddress(
        addressList.find((item) => item.typeAddress === AddressTypes.RESIDENTIAL) || null,
      ),
    )

    dispatch(ProposalActions.setEffectiveDate(undefined))

    dispatch(DPSActions.setDocumentGroup('Outros'))

    dispatch(
      DPSActions.getDocumentsListRequest({
        history,
        pathname: Routes.CHECK_PENDINGS,
      }),
    )
  }

  const cancelPlan = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Home_T_CancelarPlano', {
      content_name: proposal.plan.displayName,
    })
    setBottomSheet(true)
  }

  const stickyFooter = (
    <InterUIContainer margin="0 24px">
      <Button margin="0 0 16px 0" onClick={openProposalContractPdf}>
        Contrato do plano
      </Button>

      {proposal?.status === ProposalStatus.PENDENCIA.toString() && (
        <Button variant="secondary" onClick={cancelPlan} data-testid="cancel-plan-btn">
          Cancelar plano
        </Button>
      )}
    </InterUIContainer>
  )

  return proposal && healthPlan ? (
    <>
      <InterUIContainer margin="0px 0px 24px" stickyFooter={stickyFooter}>
        {pendingList?.length > 0 && (
          <>
            <InterUIContainer>
              <InterUIAlert type="attention" margin="0 0 24px 0">
                <PMedium marginBottom="4px" scale={600}>
                  Há pendências para resolver
                </PMedium>
                <PMedium scale={400}>
                  A Qualicorp verificou algumas inconsistências nas suas documentações ou
                  informações. Para continuar, é preciso que você as resolva.
                </PMedium>
              </InterUIAlert>
              <Button small onClick={goToPendingPage}>
                Resolver pendências
              </Button>
            </InterUIContainer>

            <InterUISeparator />
          </>
        )}

        <InterUIContainer>
          <P bold marginBottom="24px">
            Qualicorp
          </P>
          <Flex flexDirection="row">
            <InterUIIcon name="whatsapp" size="md" color={primaryColor} />

            <Flex flexDirection="column" margin="0 0 0 19px">
              <PhoneNumber bold>+55 11 99660-5218</PhoneNumber>
              <PMedium scale={400}>
                Atendimento: 2ª a 5ª de 9h às 18h e as sextas-feiras de 9h às 16h (exceto feriados).
              </PMedium>
            </Flex>
          </Flex>
        </InterUIContainer>

        <InterUISeparator />

        <PlanDetails
          plan={proposal.plan}
          planDetails={healthPlan}
          entity={entity}
          showEntityInfo={!!entity?.tradingName}
          showAdditionalBenefits={false}
          showAdditionalCoverages={false}
          showCollectivePlans={false}
          footerHeight="0"
        />
      </InterUIContainer>

      <InterUIBottomSheet
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
        title="Cancelar plano de saúde"
        data-testid="bottom-sheet-cancel"
      >
        <PMedium scale={400}>
          O seu pedido está em análise neste exato momento e se você optar por cancelar o seu plano,
          perderá todo o progresso feito até aqui.
        </PMedium>

        <Button margin="20px 0" onClick={cancelHealthPlan} data-testid="cancel-btn-bts">
          Cancelar plano
        </Button>

        <Button
          variant="secondary"
          onClick={() => setBottomSheet(false)}
          data-testid="back-btn-bts"
        >
          Voltar
        </Button>
      </InterUIBottomSheet>
    </>
  ) : (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )
}

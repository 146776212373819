import React from 'react'

import { InterUIContainer, InterUIButton, InterUITag } from '@interco/inter-ui-react-lib'

import { PXSmall, PSmall, PMedium } from '../../styles/commons'
import {
  PriceTag,
  InfoContent,
  PlanLogo,
  ANSNumbersContainer,
  CashbackLabel,
} from './PlanCard.styles'
import { IHealthPlan } from '../../store/ducks/healthPlan/types'
import { numberFormat } from '../../utils/commons'

interface PlanCardProps {
  plan: IHealthPlan
  onClickedDetail: () => void
  showBadges?: boolean
  isDetails?: boolean
  hasCashback?: boolean
}

export const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  onClickedDetail,
  showBadges = false,
  isDetails = false,
  hasCashback = false,
}) => {
  const renderBadges = plan.badges.map((item, index) => {
    const key = `badge-${index}`

    return (
      <InterUITag key={key} margin="0 8px 8px 0px" type="radio" name="badge" id={item} value={item}>
        {item}
      </InterUITag>
    )
  })

  const getCashbackValue = () => {
    const value = plan.cashback || 0
    return numberFormat(value)
  }

  const cashbackLabel = <CashbackLabel>Você ganhará {getCashbackValue()} de cashback</CashbackLabel>

  return (
    <InterUIContainer margin="24px 0px">
      {!isDetails && <PXSmall scale={300}>ANS Nº{plan.ansCode}</PXSmall>}

      <InfoContent showBadges={isDetails || showBadges}>
        <div>
          <h3>{plan.displayName}</h3>
          <PSmall scale={400} marginBottom="0">
            {plan.description}
          </PSmall>
        </div>
        <PlanLogo>
          <img src={plan.logo} alt={plan.displayName} />
        </PlanLogo>
      </InfoContent>

      {(isDetails || showBadges) && renderBadges}

      <PriceTag>
        <PMedium scale={400}> Preço do plano de saúde </PMedium>
        <h2>{numberFormat(+plan.price)} por mês</h2>
        {hasCashback && plan?.cashback && cashbackLabel}
      </PriceTag>

      {isDetails && (
        <ANSNumbersContainer>
          <PXSmall scale={300}>ANS Nº{plan.ansCode}</PXSmall>
          {plan.regulatoryNumber !== 0 && (
            <PXSmall scale={300}>ANS Nº{plan.regulatoryNumber}</PXSmall>
          )}
        </ANSNumbersContainer>
      )}

      {!isDetails && (
        <InterUIButton small margin="16px 0 0 0" onClick={onClickedDetail}>
          Ver Detalhes
        </InterUIButton>
      )}
    </InterUIContainer>
  )
}

import React, { useState, useEffect, ChangeEvent } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InterUIContainer,
  InterUIProgressBar,
  InterUISeparator,
  InterUIButton,
  InterUIBottomSheet,
  InterUIAttachmentCard,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { IDocInfoAttached } from '../../../../store/ducks/dps/types'
import { StepHeading, P, PMedium } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const DocumentationFile: React.FC = () => {
  const history = useHistory()

  const selectedDocAttached = useSelector(DPSActions.getSelectedDocAttached)

  const [bottomSheet, setBottomSheet] = useState<boolean>(false)
  const [attachedFiles, setAttachedFiles] = useState<IDocInfoAttached[]>([])
  const [indexRemoveFile, setIndexRemoveFile] = useState(0)
  const [bottomSheetError, setBottomSheetError] = useState<boolean>(false)
  const [bottomSheetErrorMessage, setBottomSheetErrorMessage] = useState<string>('')

  useBasePage({
    navbarTitle: PageTitles.DOCUMENTATION,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    const files = Object.assign([], selectedDocAttached.files)

    setAttachedFiles(files)
  }, [])

  const onAttach = (e: ChangeEvent<HTMLInputElement>, base64: string | undefined) => {
    if (e?.target?.files && e.target.files.length > 0) {
      const { name } = e.target.files[0]
      const extension = e.target.files[0].type

      const fileInfo = {
        name,
        extension,
        stream: base64,
        size: base64?.length,
      }

      setAttachedFiles((prev) => [...prev, fileInfo as IDocInfoAttached])

      BaseBridge.requestAnalytics('C_PlanoSaude_Documentacao_T_Anexar', {
        content_id: fileInfo.name,
      })
    }
  }

  const onRemove = (index: number) => {
    setIndexRemoveFile(index)

    setBottomSheet(true)
  }

  const onRemoveFile = () => {
    setAttachedFiles((prev) => {
      prev.splice(indexRemoveFile, 1)

      return [...prev]
    })

    setBottomSheet(false)
  }

  const onContinue = () => {
    let sizeAll = 0

    attachedFiles.forEach((file) => {
      if (file.size) {
        sizeAll += file.size
      }
    })

    if (sizeAll > 10485760) {
      setBottomSheetErrorMessage('O conjunto de arquivos não pode ultrapassar 10mb')
      setBottomSheetError(true)
    } else {
      selectedDocAttached.files = attachedFiles

      const filesNameList = attachedFiles.map((file) => file.name)

      BaseBridge.requestAnalytics('C_PlanoSaude_Documentacao_T_ContCarreg', {
        content_id: filesNameList.toString(),
      })

      history.goBack()
    }
  }

  const notifyError = (error: unknown) => {
    if (
      (error as { message: string })?.message &&
      ((error as { message: string }).message as string).includes('limite')
    ) {
      setBottomSheetErrorMessage('Documento excede o limite máximo de 5mb')
    } else {
      setBottomSheetErrorMessage('Tipo de arquivo inválido')
    }

    setBottomSheetError(true)
  }

  const calculateSize = (size?: number) => {
    if (size) {
      const sizeFormated = size * 0.001

      return sizeFormated >= 1000
        ? `${(sizeFormated / 1000).toFixed(2)}mb`
        : `${sizeFormated.toFixed(2)}kb`
    }

    return undefined
  }

  const renderFiles = attachedFiles.map((item, index) => {
    const itsImg = item.extension.includes('image/')

    return (
      <InterUIAttachmentCard
        key={`${item.name}-${index + 1}`}
        margin="16px 0"
        status="filled"
        fileName={item.name}
        subtitle={itsImg ? 'Imagem anexada' : 'Arquivo anexado'}
        size={calculateSize(item.size)}
        imageSrc={itsImg ? item.stream : undefined}
        onRemove={() => onRemove(index)}
        onError={notifyError}
        compress
      />
    )
  })

  const stickyFooter = (
    <>
      <InterUIContainer>
        <InterUIButton
          margin="0px 0px 16px 0px"
          disabled={attachedFiles.length === 0}
          onClick={onContinue}
        >
          <p>Continuar</p>
        </InterUIButton>
      </InterUIContainer>
    </>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="60%" aria-valuenow={2} aria-valuemin={1} aria-valuemax={3} />

        <InterUIContainer>
          <InterUIContainer margin="63px 0 32px">
            <StepHeading>
              Tire uma foto de cada página do seu documento. Lembre-se do verso, se houver.
            </StepHeading>
          </InterUIContainer>

          <InterUISeparator variant="small" />

          <InterUIContainer margin="24px 0">
            <P bold marginBottom="24px">
              {selectedDocAttached.title}
            </P>

            {renderFiles}

            <InterUIAttachmentCard onAttach={onAttach} onError={notifyError} compress />
          </InterUIContainer>
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
        title="Excluir arquivo"
      >
        <PMedium scale={400}>
          Tem certeza que deseja excluir esse arquivo da lista de documentos?
        </PMedium>

        <Button margin="20px 0" onClick={onRemoveFile}>
          Excluir arquivo
        </Button>

        <Button variant="secondary" margin="20px 0" onClick={() => setBottomSheet(false)}>
          Agora não
        </Button>
      </InterUIBottomSheet>
      <InterUIBottomSheet
        data-testid="invalid-file-bottom-sheet"
        toggle={bottomSheetError}
        onHide={() => setBottomSheetError(false)}
      >
        <P scale={600}>Erro ao anexar arquivo</P>
        <PMedium scale={400} margin="12px 0px 32px 0px">
          {bottomSheetErrorMessage}
        </PMedium>
        <Button variant="primary" margin="20px 0 0 0  " onClick={() => setBottomSheetError(false)}>
          Fechar
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

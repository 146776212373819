import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../../utils/providerSaga'
import { IResolutionOfPending, IPending, PendingTypes, IPendingResolutionRequest } from './types'

const getPendingRequest = () => action(PendingTypes.GET_PENDING_REQUEST)

const getPending = (state: RootState) => state.pending.pendings

const setPending = (data: IPending[]) => action(PendingTypes.SET_PENDING, data)

const setResolutionOfPending = (data: IResolutionOfPending) =>
  action(PendingTypes.SEND_RESOLUTION_OF_PENDING, data)

const getResolutionOfPending = (state: RootState) => state.pending.resolutionOfPending

const sendPendingResolutionRequest = (data?: IPendingResolutionRequest) =>
  action(PendingTypes.SEND_PENDING_RESOLUTION, data)

const loadCustomerProposalRequest = (data?: SagaWithHistory) =>
  action(PendingTypes.LOAD_CUSTOMER_PROPOSAL_REQUEST, data)

export const PendingActions = {
  getPendingRequest,
  getPending,
  setPending,
  setResolutionOfPending,
  getResolutionOfPending,
  sendPendingResolutionRequest,
  loadCustomerProposalRequest,
}

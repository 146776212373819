import NewRelicBrowser from 'new-relic-browser'

import BaseBridge from '../bridge/BaseBridge'
import packageJson from '../../../package.json'

// A variável newrelic deve possuir o mesmo nome da variável definida no script do NewRelic
declare const newrelic: typeof NewRelicBrowser

class NewRelicUtils {
  static setCustomAttribute(name: string, value: string | number): void {
    if (newrelic) {
      newrelic.setCustomAttribute(name, value)
    }
  }

  static noticeError(error: Error, customAttributes?: { [key: string]: string | number }) {
    if (newrelic && process.env.REACT_APP_NEW_RELIC_LOG === 'true') {
      // TODO test
      const { errorCodeRef, errorLogRef } = error as unknown as {
        errorCodeRef: string
        errorLogRef: string
      }

      const attributes = { errorCodeRef, errorLogRef, ...customAttributes }

      newrelic.noticeError(error, attributes)
    }
  }

  static setInitSettings() {
    if (newrelic && process.env.REACT_APP_NEW_RELIC_LOG === 'true') {
      const spaVersion = packageJson?.version || 'unknown'

      NewRelicUtils.setCustomAttribute('spaVersion', spaVersion)

      BaseBridge.getAppInfo().then((resp) => {
        const deviceName =
          (resp as { nomeAparelho: string })?.nomeAparelho ||
          (resp as { deviceName: string })?.deviceName ||
          'unknown'
        const superAppVersion =
          (resp as { versao: string })?.versao ||
          (resp as { version: string })?.version ||
          'unknown'

        NewRelicUtils.setCustomAttribute('deviceName', deviceName)

        NewRelicUtils.setCustomAttribute('superAppVersion', superAppVersion)
      })

      BaseBridge.getUserInfo().then((resp) => {
        NewRelicUtils.setCustomAttribute('userAccount', resp.account)
      })
    }
  }
}

export default NewRelicUtils

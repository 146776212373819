import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIBottomSheet, InterUIButton } from '@interco/inter-ui-react-lib'

import { AddressForm } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { AddressActions } from '../../../../store/ducks/address/actions'
import { IAddress } from '../../../../store/ducks/address/types'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { BottomSheetImage, BottomSheetText, BottomSheetTitle } from './UpdateAddress.styles'
import { PageTitles } from '../../../../enums/pageTitles'
import { AddressMessages } from '../../../../enums/AddressMessages'
import { TypesPendingRoutes as Routes } from '../../routes/types'
import Persona from '../../../../assets/img/afro-personas-man-error.png'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const UpdateAddress: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const selectedAddress = useSelector(AddressActions.getSelectedAddress)
  const searchedAddress = useSelector(AddressActions.getSearchedAddress)

  const [zipCodeSameCityBS, setZipCodeSameCityBS] = useState(false)
  const [zipCodeSameCity, setZipCodeSameCity] = useState(false)
  const proposal = useSelector(ProposalActions.getProposal)

  useBasePage({
    navbarTitle: PageTitles.ADDRESS,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    if (
      searchedAddress?.city &&
      selectedAddress?.city &&
      normalizeString(searchedAddress?.city) !== normalizeString(selectedAddress?.city)
    ) {
      setZipCodeSameCityBS(true)
      setZipCodeSameCity(true)
      if (proposal) {
        BaseBridge.requestAnalytics('C_PlanoSaude_Erro_DC', {
          content_name: proposal?.plan?.displayName,
          content_id: AddressMessages.TITLE_CEP_NOT_SAME_CITY,
        })
      }
    } else {
      setZipCodeSameCity(false)
    }
  }, [selectedAddress, searchedAddress])

  const normalizeString = (str: string) =>
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

  const onSubmit = (address: IAddress) => {
    dispatch(AddressActions.setSelectedAddress(address))

    history.goBack()
  }

  return (
    <>
      <AddressForm
        disableBtn={zipCodeSameCity}
        onSubmit={onSubmit}
        goBackHome={() => history.push(Routes.PENDING)}
        defaultValues={selectedAddress}
        gtmEvent="C_PlanoSaude_Endereco_T_SolicitarAtual"
        blockCEP
      />

      <InterUIBottomSheet
        toggle={zipCodeSameCityBS}
        onHide={() => setZipCodeSameCityBS(false)}
        id="bottomsheet-not-same-city"
      >
        <BottomSheetImage src={Persona} alt="Afro Persona Man Error" />

        <BottomSheetTitle>{AddressMessages.TITLE_CEP_NOT_SAME_CITY}</BottomSheetTitle>

        <BottomSheetText>{AddressMessages.MSG_CEP_NOT_SAME_CITY}</BottomSheetText>

        <InterUIButton
          margin="32px 0 0 0"
          onClick={() => setZipCodeSameCityBS(false)}
          type="button"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </>
  )
}

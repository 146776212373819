import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { ICheckoutProposal } from '../../../../store/ducks/proposal/types'
import { PendingActions } from '../../../../store/ducks/pending/actions'
import { PMedium } from '../../../../styles/commons'
import { TypesPendingRoutes as Routes } from '../../routes/types'
import BaseBridge from '../../../../config/bridge/BaseBridge'

interface EndPendingIssuesProps {
  toggle: boolean
  setToggle: React.Dispatch<React.SetStateAction<boolean>>
  planName: string
}

export const EndPendingIssues: React.FC<EndPendingIssuesProps> = ({
  toggle,
  setToggle,
  planName,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const checkout = useSelector(ProposalActions.getCheckoutProposal)

  const [readyTransBS, setReadyTransBS] = useState(false)

  const onFinalizeHiring = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_T_FinalizarContracao', {
      content_name: planName,
    })

    dispatch(
      PendingActions.sendPendingResolutionRequest({
        onTransmission,
      }),
    )
  }

  const onTransmission = (checkoutResponse: ICheckoutProposal) => {
    if (checkoutResponse && !checkoutResponse.readyTransmission) {
      setToggle(false)

      setReadyTransBS(true)
    } else {
      history.push(Routes.RESOLVED_PENDINGS)
    }
  }

  const renderPendings = checkout?.pending?.map((item, index) => {
    const key = `pending-${index}`

    return (
      <PMedium scale={400} key={key}>
        &ndash;&nbsp;{item}
      </PMedium>
    )
  })

  return (
    <>
      <InterUIBottomSheet
        title="Confirmar transação"
        toggle={toggle}
        onHide={() => setToggle(false)}
        id="confirm-transaction"
        aria-labelledby="confirm-transaction"
      >
        <PMedium scale={400} marginBottom="0">
          Confirme a transação para autenticá-la com o i-safe.
        </PMedium>

        <Button type="button" margin="32px 0px 0px" onClick={onFinalizeHiring}>
          Finalizar contratação
        </Button>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        toggle={readyTransBS}
        onHide={() => setReadyTransBS(false)}
        title="Há uma ou mais pendências na contratação"
        id="no-ready-transmission"
        aria-labelledby="no-ready-transmission"
      >
        {renderPendings}

        <Button margin="20px 0 0" onClick={() => setReadyTransBS(false)}>
          Fechar
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

export enum NavbarTypes {
  SET_TITLE = `@navbar/SET_TITLE`,
  TOGGLE_HOME_BTN = `@navbar/TOGGLE_HOME_BTN`,
  SET_DIRECTION = `@navbar/SET_DIRECTION`,
  SET_BACK_NAVIGATION_HANDLER = `@navbar/SET_BACK_NAVIGATION_HANDLER`,
}

export interface NavbarState {
  readonly title: string
  readonly toggleHomeBtn: boolean
  readonly direction: string

  readonly backNavigationHandler: () => void
}

import { Reducer } from 'redux'

import { HealthPlanState, HealthPlanTypes } from './types'

const INITIAL_STATE: HealthPlanState = {
  providersFilter: [],
  healthPlanFilteredOptions: undefined,
  healthPlanList: [],
  selectedHealthPlan: undefined,
  healthPlanDetails: undefined,
  availableEffectiveDates: [],
}

const reducer: Reducer<HealthPlanState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HealthPlanTypes.CLEAR_ALL:
      return INITIAL_STATE

    case HealthPlanTypes.SET_LIST_HEALTH_PLANS:
      return { ...state, healthPlanList: action.payload }

    case HealthPlanTypes.SET_HEALTH_PLAN_DETAILS:
      return { ...state, healthPlanDetails: action.payload }

    case HealthPlanTypes.SET_SELECTED_HEALTH_PLAN:
      return { ...state, selectedHealthPlan: action.payload }

    case HealthPlanTypes.SET_PROVIDERS_FILTER:
      return { ...state, providersFilter: action.payload }

    case HealthPlanTypes.SET_HEALTH_FILTERED_OPTIONS:
      return { ...state, healthPlanFilteredOptions: action.payload }

    case HealthPlanTypes.SET_AVAILABLE_EFFECTIVE_DATES:
      return { ...state, availableEffectiveDates: action.payload }

    default:
      return state
  }
}

export default reducer

/**
 * Cores disponíveis na aplicação.
 */
export const Colors = {
  white: '#ffffff',
  black: '#333333',

  interOrange: '#ff7a00',

  yellowOrange: '#ff9d42',

  shuttleGray: '#6a6c72',
  cornflowerBlue: '#6195ed',
}

import { AnyAction } from 'redux'
import { call, put, select, take, takeLatest } from 'typed-redux-saga'

import BaseBridge from '../../../config/bridge/BaseBridge'
import ProposalService from '../../../services/ProposalService'
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import { AddressActions } from '../address/actions'
import { BindingActions } from '../binding/actions'
import { ClassEntityActions } from '../classEntity/actions'
import { HealthPlanActions } from '../healthPlan/actions'
import { LoadingActions } from '../loading/actions'
import { PersistedHiringActions } from '../persistedHiring/actions'
import { UserActions } from '../user/actions'
import { ProposalActions } from './actions'
import { CreateProposalRequest, ICheckoutProposalRequest, ProposalTypes } from './types'

function* createProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const user = yield* select(UserActions.getUserDetails)
    const plan = yield* select(HealthPlanActions.getSelectedHealthPlan)
    const association = yield* select(ClassEntityActions.getSelectedClassEntity)
    const occupation = yield* select(ClassEntityActions.getSelectedOccupation)
    const address = yield* select(AddressActions.getSelectedAddress)
    const hasBond = yield* select(ClassEntityActions.getSelectedBond)

    if (user && plan && association && occupation && address) {
      const body: CreateProposalRequest = {
        planId: `${plan.id}`,
        association: association.tradingName,
        occupation: occupation.name,
        address,
      }

      try {
        BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Chama_Servico_Cancelar_Proposta')
        yield* call(ProposalService.cancelProposal)
      } catch (error) {
        const errorType = error as Error
        const errorObject = JSON.parse(errorType.message)

        BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Nao_Tem_Proposta', {
          error_date: errorObject.dateTime,
          error_message: errorObject.message,
          error_status: errorObject.status,
        })
      }

      BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Chama_Servico_Cria_Proposta', {
        planId: body.planId,
        association: body.association,
        occupation: body.occupation,
      })

      yield* call(ProposalService.createProposal, user.cpf, body)

      if (hasBond === 'N') {
        BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Tem_Vinculo')

        yield* put(HealthPlanActions.getPlanMembershipFeeRequest({ planId: plan.id }))

        yield* put(BindingActions.getDocumentsListRequest(payload))
      } else {
        BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Nao_Tem_Vinculo')
        BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Chama_Persistencia_Da_Simulacao')
        yield* put(PersistedHiringActions.setSimulationStateRequest(payload))
      }

      yield* put(LoadingActions.hide())
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'ProposalActionsSaga.createProposal')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.createProposal')
  }
}

function* getProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(ProposalService.getProposal)

    yield* put(ProposalActions.setProposal(response.data))

    const hasBond = yield* select(ClassEntityActions.getSelectedBond)

    yield* put(ProposalActions.getProposalPDFRequest())

    const address = response.data.insuranceHolder.address[0]

    yield* put(AddressActions.setSelectedAddress(address))

    yield take(ProposalTypes.SET_PROPOSAL_PDF)

    if (hasBond === 'N') {
      yield* put(HealthPlanActions.getPlanMembershipFeeRequest(payload))
    } else {
      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.getProposal')
  }
}

function* getProposalPDFFile({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(ProposalService.getProposalPDFContract)

    yield* put(ProposalActions.setProposalPDF(response.data))

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.getProposalPDFFile')
  }
}

function* cancelProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    yield* call(ProposalService.cancelProposal)

    yield* put(ProposalActions.setProposal())

    yield* put(PersistedHiringActions.clearAllState())

    yield* put(LoadingActions.hide())

    if (payload.goToNative) {
      BaseBridge.requestGoBack()
    } else {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.cancelProposal')
  }
}

function* updateProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { effectiveDate } = payload
    const { hasPreviousPlan } = payload

    yield* put(ProposalActions.setEffectiveDate(effectiveDate))

    const response = yield* call(ProposalService.updateProposal, effectiveDate, hasPreviousPlan)

    const proposal = yield* select(ProposalActions.getProposal)

    if (effectiveDate) {
      proposal.plan.readjustmentLetter = response.data.plan.readjustmentLetter

      if (
        Number.parseFloat(proposal.plan.price) !==
        Number.parseFloat(response.data.plan.valuePerMonth)
      ) {
        proposal.plan.price = response.data.plan.valuePerMonth
        proposal.plan.cashback = response.data.plan.cashback
        proposal.plan.changedValue = true
      }

      yield* put(ProposalActions.setProposal(proposal))
    }

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.updateProposal')
  }
}

function* checkoutProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { history, pathname, onTransmission } = (payload || {}) as ICheckoutProposalRequest

    const iSafeRequest = {
      category: '16',
      checkType: '5',
      feature: 'AUTORIZACAO_SEGURO',
      value: 0,
    }

    const iSafeResponse = yield* call(BaseBridge.requestISafe, iSafeRequest)

    const address = yield* select(AddressActions.getSelectedAddress)

    const response = yield* call(ProposalService.checkoutProposal, iSafeResponse, address?.city)

    const checkoutResponse = response.data

    yield* put(ProposalActions.setCheckoutProposal(checkoutResponse))

    if (onTransmission) {
      yield* call(onTransmission, checkoutResponse)
    }

    yield* put(LoadingActions.hide())

    redirectThroughSaga({ history, pathname })
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ProposalActionsSaga.checkoutProposal')
  }
}

export default function* watchProposal() {
  yield* takeLatest(ProposalTypes.CREATE_PROPOSAL, createProposal)
  yield* takeLatest(ProposalTypes.GET_PROPOSAL, getProposal)
  yield* takeLatest(ProposalTypes.CANCEL_PROPOSAL, cancelProposal)
  yield* takeLatest(ProposalTypes.UPDATE_PROPOSAL_REQUEST, updateProposal)
  yield* takeLatest(ProposalTypes.GET_CHECKOUT_PROPOSAL, checkoutProposal)
  yield* takeLatest(ProposalTypes.GET_PROPOSAL_PDF, getProposalPDFFile)
}

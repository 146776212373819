import React from 'react'

import { Route } from 'react-router-dom'

import { TypesContinueHiringRoutes as Routes } from './types'
import { ContinueHiring } from '../pages'

export const ContinueHiringRoutes: React.FC = () => (
  <>
    <Route path={Routes.CONTINUE_HIRING} exact component={ContinueHiring} />
  </>
)

export * from './types'

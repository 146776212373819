import { put } from 'typed-redux-saga'
import { History } from 'history'

import NewRelicUtils from '../../config/monitoring/NewRelicUtils'
import { ErrorActions } from '../ducks/error/actions'
import { LoadingActions } from '../ducks/loading/actions'

export interface SagaWithHistory {
  history?: History
  pathname?: string
  goBack?: boolean
}

/**
 * Realiza o redirecionamento utilizando o useHistory recebido por parâmetro.
 * @param payload Payload recebido através da action.
 */
export function redirectThroughSaga(payload: SagaWithHistory): void {
  if (payload && payload.history && (payload.pathname || payload.goBack)) {
    const { history } = payload

    if (payload.goBack) {
      history.goBack()
    } else if (payload?.pathname) {
      history.push(payload.pathname)
    }
  }
}

/**
 * Tratativa de erro a ser realizada no Saga.
 */
export function* errorHandlingSaga(error: Error, errorCodeRef: string) {
  NewRelicUtils.noticeError(error, { errorCodeRef })

  yield* put(ErrorActions.show())

  yield* put(LoadingActions.hide())
}

import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIContainer, InterUICheckBox } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { PMedium, PSmall, H1, BtnLink } from '../../../../styles/commons'
import { Hero } from './DocumentationHome.style'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesDocumentationRoutes as Routes } from '../../routes'
import HeroPersonaImg from '../../../../assets/img/personas-gift.png'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const DocumentationHome: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)

  useEffect(() => {
    BaseBridge.requestAnalytics('C_PlanoSaude_OrientBenef_T_LiAceito')
  }, [])

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    hideHomeBtn: true,
    backNavigationHandler: history.goBack,
  })

  const onReply = () => {
    dispatch(
      DPSActions.getQuizInfoRequest({
        history,
        pathname: Routes.HISTORIC,
      }),
    )
  }

  const openOrientationLetter = () => {
    history.push(Routes.ORIENTATION_LETTER)
  }

  const onReplyOffline = () => {
    history.push(Routes.CONFIRM_DPS_OFFLINE)
  }

  const stickyFooter = (
    <>
      <InterUICheckBox
        value=" "
        name="no-number"
        id="no-number"
        onChange={() => setAcceptTerms(!acceptTerms)}
        checked={acceptTerms}
      >
        <PSmall>
          Li e concordo com a
          <BtnLink onClick={openOrientationLetter}> carta de orientação ao beneficiário</BtnLink> da
          ANS.
        </PSmall>
      </InterUICheckBox>

      <Button margin="0px 0px 16px 0px" disabled={!acceptTerms} onClick={onReply}>
        <p>Responder no app</p>
      </Button>

      <Button variant="secondary" onClick={onReplyOffline}>
        <p>Responder offline</p>
      </Button>
    </>
  )

  return (
    <>
      <InterUIContainer stickyFooter={stickyFooter}>
        <Hero>
          <img src={HeroPersonaImg} alt="Persona segurando uma caixa de presente" />
        </Hero>

        <H1 marginBottom="16px">Seus hábitos e sua saúde</H1>

        <PMedium scale={400} marginBottom="16px">
          Agora, você precisa responder as perguntas sobre seus hábitos e sua saúde. Pra facilitar,
          você pode responder aqui pelo app. Se quiser responder offline, ligue para nossa parceira,
          a Qualicorp.
        </PMedium>
        <PMedium scale={400}>
          Antes disso, leia a orientação da ANS (Agência Nacional de Saúde).
        </PMedium>
      </InterUIContainer>
    </>
  )
}

import React from 'react'

import { useHistory } from 'react-router-dom'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PMedium, Hero, H1 } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesDocumentationRoutes as DPSRoutes } from '../../../documentation/routes/types'
import HeroImg from '../../../../assets/img/afro-personas-woman-success.png'

export const BindingConfirmed: React.FC = () => {
  const history = useHistory()

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    backNavigationHandler: history.goBack,
  })

  const onConfirm = () => {
    history.push(DPSRoutes.DOCUMENTATION)
  }

  const stickyFooter = (
    <Button onClick={onConfirm}>
      <p>Continuar contratação</p>
    </Button>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Hero>
        <img src={HeroImg} alt="Persona" />
      </Hero>

      <H1 marginBottom="8px" textAlign="center">
        Filiação concluída
      </H1>

      <PMedium scale={400} textAlign="center">
        Você conseguiu se filiar com sucesso! O comprovante de filiação foi enviado pela Qualicorp
        para seu e-mail.
      </PMedium>
    </InterUIContainer>
  )
}

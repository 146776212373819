import React, { ChangeEvent, useState } from 'react'

import { useSelector } from 'react-redux'

import {
  InterUIAttachmentCard,
  InterUIBottomSheet,
  InterUIContainer,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import BaseBridge from '../../../../config/bridge/BaseBridge'
import { Button } from '../../../../components'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { H3, P, PMedium } from '../../../../styles/commons'
import { IDocInfoAttached } from '../../../../store/ducks/dps/types'

interface IAttachPendingFilesProps {
  planName: string
}

export const AttachPendingFiles: React.FC<IAttachPendingFilesProps> = ({ planName }) => {
  const documentsAttached = useSelector(DPSActions.getDocumentsAttached)

  const [attachedFiles, setAttachedFiles] = useState<IDocInfoAttached[]>(
    documentsAttached[0]?.files || [],
  )
  const [bottomSheetError, setBottomSheetError] = useState<boolean>(false)
  const [bottomSheetErrorMessage, setBottomSheetErrorMessage] = useState<string>('')

  const onAttach = (e: ChangeEvent<HTMLInputElement>, base64: string | undefined) => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Pendecias_T_Anexar', {
      content_name: planName,
    })

    if (e?.target?.files && e.target.files.length > 0) {
      const { name } = e.target.files[0]
      const extension = e.target.files[0].type

      const fileInfo = {
        name,
        extension,
        stream: base64 as string,
        size: base64?.length,
      }

      const files = [...attachedFiles, fileInfo]

      let sizeAll = 0

      files.forEach((file) => {
        if (file.size) {
          sizeAll += file.size
        }
      })

      if (sizeAll > 10485760) {
        setBottomSheetErrorMessage('O conjunto de arquivos não pode ultrapassar 10mb')
        setBottomSheetError(true)
      } else {
        setAttachedFiles(files)

        if (documentsAttached?.length > 0) {
          documentsAttached[0].files = files
        }
      }
    }
  }

  const onRemove = (index: number) => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Pendecias_T_Arquivo', {
      content_name: planName,
      content_id: 'Remover',
    })

    attachedFiles.splice(index, 1)

    const files = [...attachedFiles]

    setAttachedFiles(files)

    if (documentsAttached?.length > 0) {
      documentsAttached[0].files = files
    }
  }

  const notifyError = (error: unknown) => {
    if (
      (error as { message: string })?.message &&
      ((error as { message: string }).message as string).includes('limite')
    ) {
      setBottomSheetErrorMessage('Documento excede o limite máximo de 5mb')
    } else {
      setBottomSheetErrorMessage('Tipo de arquivo inválido')
    }

    setBottomSheetError(true)
  }

  const calculateSize = (size?: number) => {
    if (size) {
      const sizeFormated = size * 0.001

      return sizeFormated >= 1000
        ? `${(sizeFormated / 1000).toFixed(2)}mb`
        : `${sizeFormated.toFixed(2)}kb`
    }

    return undefined
  }

  const renderFiles = attachedFiles.map((item, index) => {
    const itsImg = item.extension.includes('image/')

    return (
      <InterUIAttachmentCard
        key={`${item.name}`}
        margin="16px 0"
        status="filled"
        fileName={item.name}
        subtitle={itsImg ? 'Imagem anexada' : 'Arquivo anexado'}
        size={calculateSize(item.size)}
        imageSrc={itsImg ? item.stream : undefined}
        onRemove={() => onRemove(index)}
        onError={notifyError}
        compress
      />
    )
  })

  return (
    <>
      <InterUIContainer>
        <H3 marginBottom="16px">
          Envie fotos ou anexe arquivos dos documentos que estiverem com pendências:
        </H3>

        {renderFiles}

        <InterUIAttachmentCard onAttach={onAttach} onError={notifyError} compress />
      </InterUIContainer>

      <InterUISeparator />
      <InterUIBottomSheet
        toggle={bottomSheetError}
        data-testid="invalid-file-bottom-sheet-pending"
        onHide={() => setBottomSheetError(false)}
        id="invalid-file"
      >
        <P scale={600}>Erro ao anexar arquivo</P>
        <PMedium scale={400} margin="12px 0px 32px 0px">
          {bottomSheetErrorMessage}
        </PMedium>
        <Button
          variant="primary"
          margin="20px 0"
          onClick={() => setBottomSheetError(false)}
          type="button"
        >
          Fechar
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import { AnyAction } from 'redux'
import { all, CallEffect, SagaReturnType, take } from 'redux-saga/effects'
import { call, put, SagaGenerator, select, takeLatest } from 'typed-redux-saga'

import DPSService from '../../../services/DPSService'
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import { DPSActions } from './actions'
import { DPSDocsAttachedRequest, DPSTypes, IDocsAttached } from './types'
import { LoadingActions } from '../loading/actions'
import { PersistedHiringActions } from '../persistedHiring/actions'
import { PersistedHiringTypes } from '../persistedHiring/types'
import { ProposalActions } from '../proposal/actions'
import { BaseResponseBack } from '../../../config/bridge/BaseService'

function* getQuizInfo({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(DPSService.getQuizInfo)

    yield* put(DPSActions.setQuizInfo(response.data))

    const length = response.data?.quiz?.length - 2 || 0
    const step = {
      current: 1,
      length,
      amountProgress: 100 / (length + 2),
    }

    yield* put(DPSActions.setQuizStep(step))

    const quizAnswers = { ...response.data, quiz: [] }

    yield* put(DPSActions.setQuizAnswers(quizAnswers))

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DPSActions.getQuizInfo')
  }
}

function* sendQuizAnswers({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const quiz = yield* select(DPSActions.getQuizList)
    const answers = yield* select(DPSActions.getQuizAnswers)
    const heightWeight = yield* select(DPSActions.getHeightWeight)

    const last = quiz.slice(quiz.length - 2)

    last?.forEach((item) => {
      const question = answers.quiz.findIndex((e) => e.code === item.code)
      let currentAnswer = 0

      if (item.type.toLowerCase() === 'integer') {
        currentAnswer = heightWeight.weight
      } else if (item.type.toLowerCase() === 'float') {
        currentAnswer = heightWeight.height
      }

      if (currentAnswer) {
        if (question !== -1) {
          answers.quiz[question].answer = currentAnswer
        } else {
          answers.quiz.push({
            code: item.code,
            question: item.question,
            answer: currentAnswer,
          })
        }
      }
    })

    yield* call(DPSService.sendQuizAnswers, answers)

    yield* put(DPSActions.getDocumentsListRequest(payload))
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DPSActions.sendQuizAnswers')
  }
}

function* getDocumentsList({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const group = yield* select(DPSActions.getDocumentGroup)

    const response = yield* call(DPSService.getDocumentsList, group)

    const documents = response.data.arquivos
    const docsAttached: IDocsAttached[] = []

    yield* put(DPSActions.setDocumentsList(documents))

    documents.map((item) => docsAttached.push({ ...item, files: [] }))

    yield* put(DPSActions.setDocumentsAttached(docsAttached))

    yield* put(PersistedHiringActions.setDPSStateRequest(payload))
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DPSActions.getDocumentsList')
  }
}

function* sendDocumentsAttached({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const docsAttached = yield* select(DPSActions.getDocumentsAttached)

    const sendAll: SagaGenerator<
      SagaReturnType<(data: DPSDocsAttachedRequest) => Promise<BaseResponseBack<void>>>,
      CallEffect<SagaReturnType<(data: DPSDocsAttachedRequest) => Promise<BaseResponseBack<void>>>>
    >[] = []

    docsAttached.forEach((item) => {
      const newFiles = item.files.map((file) => {
        const { stream } = file
        const { extension } = file

        const extensionSplit = extension.split('/')
        const splitLength = extensionSplit.length
        const ponto = splitLength > 1 ? '.' : ''
        const regex = `${ponto}${extensionSplit[splitLength - 1]}`

        return {
          ...file,
          stream: stream.replace(/(data:)(.*?)(base64,)/g, ''),
          extension: regex,
        }
      })

      const body = { documents: { ...item, files: newFiles } }

      sendAll.push(call(DPSService.sendDocumentsAttached, body))
    })

    yield all(sendAll)

    yield* put(PersistedHiringActions.setDocumentationStateRequest())

    yield take(PersistedHiringTypes.SET_DOC_STATE)

    yield* put(ProposalActions.getProposalRequest(payload))
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DPSActions.sendDocumentsAttached')
  }
}

export default function* watchDPS() {
  yield* takeLatest(DPSTypes.GET_DISEASES_CONDITIONS_QUESTIONS, getQuizInfo)
  yield* takeLatest(DPSTypes.SEND_QUIZ_ANSWERS, sendQuizAnswers)
  yield* takeLatest(DPSTypes.GET_DOCUMENTS_LIST, getDocumentsList)
  yield* takeLatest(DPSTypes.SEND_DOCUMENTS_ATTACHED, sendDocumentsAttached)
}

import BaseService from '../config/bridge/BaseService'
import {
  GetHealthPlansRequest,
  HealthPlansResponse,
  IAvailableEffectiveDatesResponse,
  IHealthPlanDetails,
  PlanMembershipFeeResponse,
} from '../store/ducks/healthPlan/types'

export default class HealthPlanService {
  static async getListHealthPlans(data: GetHealthPlansRequest) {
    const {
      accommodation,
      participation,
      association,
      coverage,
      reimbursable,
      providers,
      city,
      district,
      page,
      occupation,
    } = data

    let queryString = '?'

    queryString += `page=${page || 1}`

    if (accommodation && accommodation.length === 1) {
      queryString += `&accommodation=${accommodation}`
    }

    if (participation && participation.length === 1) {
      queryString += `&participation=${encodeURIComponent(participation[0] === 'S')}`
    }

    let associationEncodedName

    if (association?.tradingName) {
      associationEncodedName = encodeURIComponent(association.tradingName)
    }

    queryString += `&association=${associationEncodedName}`

    queryString += coverage ? `&coverage=${coverage}` : ''

    queryString += reimbursable ? `&reimbursable=${reimbursable === 'S'}` : ''

    if (providers) {
      if (providers instanceof Array) {
        queryString += `&providers=${encodeURIComponent(providers.join(','))}`
      } else {
        queryString += `&providers=${encodeURIComponent(providers)}`
      }
    }

    queryString += `&city=${encodeURIComponent(city)}`

    queryString += `&district=${encodeURIComponent(district)}`

    if (occupation) {
      queryString += `&occupation=${encodeURIComponent(occupation)}`
    }

    try {
      return await BaseService.doExecute<HealthPlansResponse>({
        method: 'GET',
        endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/planos${queryString}`,
      })
    } catch (error) {
      if ((error as { status: number }).status === 400) {
        return undefined
      }
      return Promise.reject(error)
    }
  }

  static async getHealthPlanDetails(planId: number) {
    return BaseService.doExecute<IHealthPlanDetails>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/planos/${planId}/informacoes`,
    })
  }

  static async getAvailableEffectiveDates(planId: number) {
    return BaseService.doExecute<IAvailableEffectiveDatesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/planos/${planId}/vigencias`,
    })
  }

  static async getPlanMembershipFee(planId: number) {
    return BaseService.doExecute<PlanMembershipFeeResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/planos/${planId}/taxa`,
    })
  }
}

import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InterUIAlert,
  InterUIContainer,
  InterUIProgressBar,
  InterUIRadio,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PSmall, PMedium, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as Routes } from '../../routes'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { IEntity, IOccupation } from '../../../../store/ducks/classEntity/types'
import { PanelMessage, PanelTitle } from '../../../binding/pages/EntityTax/EntityTax.styles'

export const SelectBond: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const selectedBond = useSelector(ClassEntityActions.getSelectedBond)
  const [radioBond, setRadioBond] = useState(selectedBond)

  useBasePage({
    navbarTitle: PageTitles.CLASS_ENTITY,
    backNavigationHandler: history.goBack,
  })

  const onContinue = () => {
    const bondParam = radioBond === 'S' ? 'Já sou vinculado' : 'Ainda preciso me vincular'
    BaseBridge.requestAnalytics('C_PlanoSaude_EntidaClasse_T_Continuar', {
      content_opcao: bondParam,
    })
    if (radioBond) {
      dispatch(ClassEntityActions.setSelectedBond(radioBond))
      dispatch(ClassEntityActions.setSelectedOccupation({} as IOccupation))
      dispatch(ClassEntityActions.setSelectedClassEntity({} as IEntity))

      dispatch(
        ClassEntityActions.getListOccupationsRequest({
          history,
          pathname: Routes.SELECT_CLASS_ENTITY,
        }),
      )
    }
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!radioBond}>
      Continuar
    </Button>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="70%" aria-valuenow={2} aria-valuemin={1} aria-valuemax={3} />

        <InterUIContainer>
          <PSmall scale={300} bold>
            Passo 2/3
          </PSmall>

          <StepHeading>
            Para continuar a simulação do plano coletivo, precisamos saber se você está em uma
            entidade de classe.
          </StepHeading>

          <InterUIRadio
            variant="choose-item"
            id="bond-s"
            name="bond"
            value="S"
            onChange={(e) => setRadioBond(e.target.value)}
            checked={radioBond === 'S'}
          >
            <PMedium bold marginBottom="4px">
              Já sou vinculado
            </PMedium>
            <PSmall scale={400}>Você nos informará sua entidade.</PSmall>
          </InterUIRadio>

          <InterUIRadio
            variant="choose-item"
            id="bond-n"
            name="bond"
            value="N"
            onChange={(e) => setRadioBond(e.target.value)}
            checked={radioBond === 'N'}
          >
            <PMedium bold marginBottom="4px">
              Ainda preciso me vincular
            </PMedium>
            <PSmall scale={400}>
              Informaremos as entidades disponíveis pra sua categoria. Escolha uma a seguir para
              simular seu plano.
            </PSmall>
          </InterUIRadio>

          <InterUIAlert margin="24px 0">
            <PanelTitle>Entidades de classe</PanelTitle>

            <PanelMessage>
              São associações profissionais, sem fins lucrativos, que disponibilizam serviços para
              categorias das áreas abrangidas. Encontraremos uma que se encaixe para você.
            </PanelMessage>
          </InterUIAlert>
        </InterUIContainer>
      </InterUIContainer>
    </>
  )
}

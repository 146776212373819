import styled from 'styled-components'

export const InfoContent = styled.div<{ showBadges: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => (props.showBadges ? `32px` : `16px`)};

  div {
    width: 70%;
  }

  h3 {
    margin-bottom: 4px;
  }
`

export const PlanLogo = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-left: auto;

  img {
    width: 100%;
    height: auto;
  }
`

export const PriceTag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
`

export const ANSNumbersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`
export const CashbackLabel = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 16px;
  color: ${(props) => props.theme.colors.feedback.success.A500};

  margin-top: 4px;
`

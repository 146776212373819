import { IAddress } from '../address/types'

export enum ClassUserTypes {
  GET_USER_DETAILS = `@class_user/GET_USER_DETAILS`,
  SET_USER_DETAILS = `@class_user/SET_USER_DETAILS`,
}

export interface UserState {
  name: string
  cpf: string
  birthday: string
  child: boolean
  activeInsurance: boolean
  address: IAddress[]
}

import styled from 'styled-components'

export const HomeSection = styled.section`
  height: calc(100vh - 56px - 97px);
  margin-bottom: 24px;
  overflow: scroll;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.neutral.grayscale.A100}`};
`

export const TextContent = styled.div`
  margin: 24px;

  .width-align {
    width: 28px;
    display: inline-block;
    text-align: center;
  }
`

export const Hero = styled.figure`
  padding: 24px 0;

  img {
    width: 100%;
  }
`

import React from 'react'

import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import { InterUIContainer, InterUITag, InterUICheckBox } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { IHealthPlansFilter } from '../../../../store/ducks/healthPlan/types'
import { PMedium } from '../../../../styles/commons'
import { FormContent } from './HealthPlanFilter.styles'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const HealthPlanFilter: React.FC<{
  onFilter: (data: IHealthPlansFilter) => void
}> = ({ onFilter }) => {
  const providers = useSelector(HealthPlanActions.getProvidersFilter)

  const filteredOptions = useSelector(HealthPlanActions.getHealthFilteredOptions)

  const defaultValues = {
    accommodation: filteredOptions?.accommodation,
    participation: filteredOptions?.participation,
    providers: filteredOptions?.providers,
    coverage: filteredOptions?.coverage,
    reimbursable: filteredOptions?.reimbursable,
    page: 1,
  }

  const { register, getValues, handleSubmit, setValue } = useForm<IHealthPlansFilter>({
    defaultValues,
  })

  BaseBridge.requestAnalytics('C_PlanoSaude_EntidaClasse_T_EntidadeCont', {
    content_name: getValues() as string,
  })

  const onReset = () => {
    setValue('accommodation', undefined)
    setValue('participation', undefined)
    setValue('providers', undefined)
    setValue('coverage', undefined)
    setValue('reimbursable', undefined)
    setValue('page', 1)
    onFilter(getValues())
  }

  const renderProviders = providers?.map((item, index) => {
    const key = `provider-${index}`

    return (
      <InterUICheckBox
        {...register('providers')}
        key={key}
        id={key}
        value={item}
        variant="border-bottom"
      >
        <p>{item}</p>
      </InterUICheckBox>
    )
  })

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <FormContent>
        <InterUIContainer margin="0">
          <PMedium bold>Acomodações</PMedium>

          <InterUITag
            {...register('accommodation')}
            margin="9px 10px 0px 0px"
            variant="action-check"
            type="checkbox"
            name="accommodation"
            id="enfermaria"
            value="ENFERMARIA"
          >
            Enfermaria
          </InterUITag>

          <InterUITag
            {...register('accommodation')}
            margin="9px 0px 0px"
            variant="action-check"
            type="checkbox"
            name="accommodation"
            id="apartamento"
            value="APARTAMENTO"
          >
            Apartamento
          </InterUITag>
        </InterUIContainer>

        <InterUIContainer margin="24px 0">
          <PMedium bold>Coparticipação</PMedium>

          <InterUITag
            {...register('participation')}
            margin="9px 10px 0px 0px"
            variant="action-check"
            type="checkbox"
            id="com-coparticipacao"
            value="S"
          >
            Com coparticipação
          </InterUITag>

          <InterUITag
            {...register('participation')}
            margin="9px 0px 0px"
            variant="action-check"
            type="checkbox"
            id="sem-coparticipacao"
            value="N"
          >
            Sem coparticipação
          </InterUITag>
        </InterUIContainer>

        <InterUIContainer margin="24px 0">
          <PMedium bold>Operadores</PMedium>

          {renderProviders}
        </InterUIContainer>

        <InterUIContainer margin="24px 0">
          <PMedium bold>Abrangência</PMedium>

          <InterUITag
            {...register('coverage')}
            margin="9px 10px 0px 0px"
            variant="action-check"
            type="radio"
            id="municipal"
            value="MUNICIPAL"
          >
            Municipal
          </InterUITag>

          <InterUITag
            {...register('coverage')}
            margin="9px 10px 0px 0px"
            variant="action-check"
            type="radio"
            id="estadual"
            value="ESTADUAL"
          >
            Estadual
          </InterUITag>

          <InterUITag
            {...register('coverage')}
            margin="9px 0px 0px"
            variant="action-check"
            type="radio"
            id="nacional"
            value="NACIONAL"
          >
            Nacional
          </InterUITag>
        </InterUIContainer>

        <InterUIContainer margin="24px 0 0">
          <PMedium bold>Reembolso</PMedium>

          <InterUITag
            {...register('reimbursable')}
            margin="9px 10px 0px 0px"
            variant="action-check"
            type="radio"
            id="com-reembolso"
            value="S"
          >
            Com reembolso
          </InterUITag>

          <InterUITag
            {...register('reimbursable')}
            margin="9px 0px 0px"
            variant="action-check"
            type="radio"
            id="sem-reembolso"
            value="N"
          >
            Sem reembolso
          </InterUITag>
        </InterUIContainer>
      </FormContent>

      <Button margin="24px 0 16px">Filtrar</Button>

      <Button type="button" variant="secondary" onClick={onReset}>
        Limpar
      </Button>
    </form>
  )
}

import React, { useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { InterUISeparator } from '@interco/inter-ui-react-lib'

import { Button, DynamicField, FormWithHook } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { PendingActions } from '../../../../store/ducks/pending/actions'
import { PageTitles } from '../../../../enums/pageTitles'
import { FieldTypes } from '../../../../enums/DynamicFieldConfig'
import { ValidationMessages } from '../../../../enums/ValidationMessages'
import { BtnLink, H3, PSmall } from '../../../../styles/commons'
import {
  AttachPendingFiles,
  EditAddress,
  EndPendingIssues,
  PendingList,
  UpdateEffectiveDate,
  ViewPendingIssuesBS,
} from '../../components'
import { IResolutionOfPending } from '../../../../store/ducks/pending/types'

interface ICheckPendingsForm {
  name?: string
  mothersName?: string
  gender?: string
  maritalStatus?: string
  rg?: number | string
  isTermsAccepted?: boolean
}

export const CheckPendings: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { insuranceHolder, plan } = useSelector(ProposalActions.getProposal)
  const pendencies = useSelector(PendingActions.getPending)
  const resolution = useSelector(PendingActions.getResolutionOfPending)

  const [showPendencies, setShowPendencies] = useState(false)
  const [confirmResolutionBS, setConfirmResolutionBS] = useState(false)

  const genderEnum = [
    { label: 'Masculino', value: 'Masculino' },
    { label: 'Feminino', value: 'Feminino' },
  ]

  const maritalStatusEnum = [
    { label: 'Solteiro', value: 'Solteiro' },
    { label: 'Casado', value: 'Casado' },
    { label: 'Viúvo', value: 'Viúvo' },
    { label: 'Divorciado', value: 'Divorciado' },
    { label: 'Outros', value: 'Outros' },
  ]

  const defaultValues = {
    name: resolution?.name || insuranceHolder?.name,
    mothersName: resolution?.mothersName || insuranceHolder?.mothersName,
    gender: resolution?.gender || insuranceHolder?.gender || 'Masculino',
    maritalStatus: resolution?.maritalStatus || insuranceHolder?.maritalStatus || 'Solteiro',
    rg: resolution?.rg || insuranceHolder?.rg,
    isTermsAccepted: false,
  } as ICheckPendingsForm

  const methods = useForm<ICheckPendingsForm>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })

  const { watch, formState } = methods
  const { isValid } = formState

  let currentValuesForm: ICheckPendingsForm = defaultValues

  useMemo(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    currentValuesForm = {
      ...currentValuesForm,
      name: watch('name') || '',
      mothersName: watch('mothersName') || '',
      gender: watch('gender') || '',
      maritalStatus: watch('maritalStatus') || '',
      rg: watch('rg') || '',
    }

    delete currentValuesForm?.isTermsAccepted

    // TODO testar
    dispatch(PendingActions.setResolutionOfPending(currentValuesForm as IResolutionOfPending))
  }, [watch('name'), watch('mothersName'), watch('gender'), watch('maritalStatus'), watch('rg')])

  const watchAcceptTerm = watch('isTermsAccepted')

  const isTermsAccepted = useMemo(() => watchAcceptTerm, [watchAcceptTerm])

  useBasePage({
    navbarTitle: PageTitles.PENDINGS,
    backNavigationHandler: history.goBack,
  })

  const onSubmit = (data: ICheckPendingsForm) => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Pendecias_T_Continuar', {
      content_name: plan.displayName,
    })

    const newData = data

    delete newData.isTermsAccepted

    dispatch(PendingActions.setResolutionOfPending(newData as IResolutionOfPending))
  }

  const onContinue = () => {
    if (isTermsAccepted && isValid) {
      setConfirmResolutionBS(true)
    }
  }

  const header = <PendingList pendencies={pendencies} planName={plan.displayName} />

  const stickyFooter = (
    <Button margin="0 24px 24px" disabled={!isTermsAccepted || !isValid} onClick={onContinue}>
      Continuar
    </Button>
  )

  return (
    <>
      <FormWithHook
        margin="0"
        header={header}
        stickyFooter={stickyFooter}
        methods={methods}
        onSubmit={onSubmit}
      >
        <H3 margin="16px 24px">Se necessário, atualize esses dados:</H3>

        <DynamicField
          margin="0 24px 12px"
          name="name"
          description="Nome do titular"
          placeholder="Digite o nome completo"
          type={FieldTypes.STRING}
          disabled
        />

        <DynamicField
          margin="0 24px 12px"
          name="mothersName"
          description="Nome da mãe"
          placeholder="Digite o nome completo"
          type={FieldTypes.STRING}
          validation={{
            required: { value: true, message: ValidationMessages.REQUIRED },
          }}
        />

        <DynamicField
          margin="0 24px 28px"
          name="gender"
          description="Sexo"
          type={FieldTypes.ENUM}
          enumValues={genderEnum}
        />

        <DynamicField
          margin="0 24px 28px"
          name="maritalStatus"
          description="Estado civil"
          type={FieldTypes.ENUM}
          enumValues={maritalStatusEnum}
        />

        <DynamicField
          margin="0 24px 12px"
          name="rg"
          description="RG"
          placeholder="Digite apenas números"
          type={FieldTypes.NUMBER}
          validation={{
            required: { value: true, message: ValidationMessages.REQUIRED },
          }}
        />

        <InterUISeparator />

        <EditAddress />

        <AttachPendingFiles planName={plan.displayName} />

        <UpdateEffectiveDate />

        <DynamicField margin="24px 24px 0" name="isTermsAccepted" type={FieldTypes.CHECKBOX}>
          <PSmall>
            Revisei as informações e resolvi todas as{' '}
            <BtnLink onClick={() => setShowPendencies(true)}>pêndencias em aberto.</BtnLink>
          </PSmall>
        </DynamicField>
      </FormWithHook>

      <ViewPendingIssuesBS
        pendencies={pendencies}
        toggle={showPendencies}
        setToggle={setShowPendencies}
        planName={plan.displayName}
      />

      <EndPendingIssues
        toggle={confirmResolutionBS}
        setToggle={setConfirmResolutionBS}
        planName={plan.displayName}
      />
    </>
  )
}

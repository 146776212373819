import styled from 'styled-components'

import { P } from '../../../../styles/commons'

export const HeaderCenter = styled.h3`
  text-align: center;
  margin: 0 49px 0;
`

export const TotalTag = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin: ${(props) => props.margin || `18px 0`};
`

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 0;

  .cashback-message {
    font-size: 0.75rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.feedback.success.A500};

    margin-left: 16px;
  }

  .icon {
    justify-content: flex-end;
    display: flex;
    flex: 1;
  }
`

export const AroundIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  height: 48px;
  width: 48px;
  background-color: ${(props) => props.theme.colors.feedback.success.A100};
`

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 24px;

  .actived {
    border: 1px solid ${(props) => props.theme.colors.primary.A500} !important;
  }

  .invalid-properties {
    align-self: flex-end;
    margin-top: 8px;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.feedback.error.A500};
  }
`

export const SelectAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A200};
  border-radius: 8px;

  font-size: 1rem;
  font-weight: 700;
  line-height: 19px;
  white-space: break-spaces;
`

export const EffectiveDate = styled.span`
  padding: 6px 0;
`

export const ContainerBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 14px 19px;
`

export const TitleAndSubTitle = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 0.75rem;

  margin-left: 14px;

  span {
    margin-bottom: 2px;
    margin-top: 2px;
  }
`

export const ContainerFormPayment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: 1rem;
  font-weight: 700;
  line-height: 19.36px;
`

export const ContrastLine = styled.span`
  margin-bottom: 4px;
`

export const NormalLine = styled.span`
  font-weight: 400;
  margin-bottom: 4px;
`

export const HeroBottomSheet = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0;
`

export const CashbackTitle = styled(P)`
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral.grayscale.A500};
`

import { IAddress } from '../address/types'
import { IBindingDoc } from '../binding/types'
import { IEntity, IOccupation } from '../classEntity/types'
import { IDocsAttached, IDPSDoc } from '../dps/types'
import { IHealthPlan, IHealthPlanDetails } from '../healthPlan/types'

export enum PersistedHiringTypes {
  VALIDATE_PERSISTENCE = `@persistedHiring/VALIDATE_PERSISTENCE`,
  LOAD_STORE_WITH_DATA = `@persistedHiring/LOAD_STORE_WITH_DATA`,
  SET_TIMESTAMP = `@persistedHiring/SET_TIMESTAMP`,
  SET_FIRST_PAGE = `@persistedHiring/SET_FIRST_PAGE`,
  SET_SIMULATION_STATE_REQUEST = `@persistedHiring/SET_SIMULATION_STATE_REQUEST`,
  SET_SIMULATION_STATE = `@persistedHiring/SET_SIMULATION_STATE`,
  SET_BINDING_STATE_REQUEST = `@persistedHiring/SET_BINDING_STATE_REQUEST`,
  SET_BINDING_STATE = `@persistedHiring/SET_BINDING_STATE`,
  SET_DPS_STATE_REQUEST = `@persistedHiring/SET_DPS_STATE_REQUEST`,
  SET_DPS_STATE = `@persistedHiring/SET_DPS_STATE`,
  SET_DOC_STATE_REQUEST = `@persistedHiring/SET_DOC_STATE_REQUEST`,
  SET_DOC_STATE = `@persistedHiring/SET_DOC_STATE`,
  CLEAR_ALL = `@persistedHiring/CLEAR_ALL`,
}

export interface SimulationStepData {
  selectedAddress: IAddress
  selectedBond: string
  selectedOccupation: IOccupation
  selectedEntity: IEntity
  selectedHealthPlan: IHealthPlan
  selectedPlanDetails: IHealthPlanDetails
  bindingDoc?: IBindingDoc[]
}

export interface BindingStepData {
  documentList: IBindingDoc[]
}

export interface DPSStepData {
  documentList: IDPSDoc[]
  docsAttached: IDocsAttached[]
}

export interface DocumentationStepData {
  documentList: IDPSDoc[]
}

export interface PersistedHiringState {
  readonly timestamp?: number
  readonly firstPage?: string

  readonly simulation?: SimulationStepData
  readonly binding?: BindingStepData
  readonly dps?: DPSStepData
  readonly documentation?: DocumentationStepData
}

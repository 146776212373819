import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIProgressBar,
  InterUIIcon,
  InterUIListDescription,
  InterUIAlert,
} from '@interco/inter-ui-react-lib'

import BaseBridge from '../../../../config/bridge/BaseBridge'
import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { PMedium, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesDocumentationRoutes as DocumentationRoutes } from '../../routes'
import { TypesPaymentRoutes as PaymentRoutes } from '../../../payment/routes'

export const SendDocumentation: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const primaryColor = useTheme().colors.primary.A500
  const successColor = useTheme().colors.feedback.success.A500

  const documentsList = useSelector(DPSActions.getDocumentsList)
  const docsAttached = useSelector(DPSActions.getDocumentsAttached)

  const hasAffiliatedInApp = useSelector(ClassEntityActions.getSelectedBond)

  const [hasAllFiles, setHasAllFiles] = useState(true)

  useBasePage({
    navbarTitle: PageTitles.SEND_DOCUMENTATION,
    backNavigationHandler: history.goBack,
  })

  const goToDocFile = (index: number, fileName: string) => {
    BaseBridge.requestAnalytics('C_PlanoSaude_EnvioDocumentacao_T_Botao', {
      content_name: fileName,
    })

    dispatch(DPSActions.setSelectedDocAttached(docsAttached[index]))

    history.push(DocumentationRoutes.FILE_DOCUMENTATION)
  }

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Documentacao_T_Continuar', {
      content_id: 'Continuar',
    })

    if (docsAttached) {
      dispatch(ProposalActions.setEffectiveDate(undefined))
      dispatch(
        DPSActions.sendDocumentsAttachedRequest({
          history,
          pathname: PaymentRoutes.CLOSE_ORDER,
        }),
      )
    }
  }

  const documentsLength = documentsList.length - 1

  const iconArrow = <InterUIIcon name="arrow-chevron-right" size="sm" color={primaryColor} />
  const iconCheck = <InterUIIcon name="check" size="sm" color={successColor} />

  const renderDocumentations = documentsList.map((item, index) => {
    const key = `documentation-${index}`

    const showBorderBottom = documentsLength !== index

    const hasFiles = docsAttached[index]?.files?.length > 0

    if (!hasFiles && hasAllFiles) {
      setHasAllFiles(false)
    }

    return (
      <React.Fragment key={key}>
        <InterUIListDescription
          margin="16px 0"
          iconRight={!hasFiles ? iconArrow : iconCheck}
          role="group"
          borderBottom={showBorderBottom}
          onClick={() => goToDocFile(index, item.title)}
        >
          <PMedium scale={400} marginBottom="0">
            {item.title}
          </PMedium>
        </InterUIListDescription>
      </React.Fragment>
    )
  })

  const stickyFooter = (
    <>
      <InterUIContainer>
        <Button margin="0px 0px 16px 0px" disabled={!hasAllFiles} onClick={onContinue}>
          <p>Continuar</p>
        </Button>
      </InterUIContainer>
    </>
  )

  const affiliatedAlert = (
    <InterUIAlert margin="32px 0 32px 0">
      <PMedium bold marginBottom="4px">
        Comprovante de filiação
      </PMedium>
      <PMedium marginBottom="0">
        Se você se filiou pelo app, o comprovante foi enviado pela Qualicorp para seu e-mail.
      </PMedium>
    </InterUIAlert>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="50%" aria-valuenow={2} aria-valuemin={1} aria-valuemax={3} />

        <InterUIContainer>
          <InterUIContainer margin="63px 0 50px">
            <StepHeading>Selecione o documento e envie os arquivos ou tire as fotos.</StepHeading>
          </InterUIContainer>
          {renderDocumentations}

          {hasAllFiles && (
            <InterUIAlert>
              <PMedium bold marginBottom="4px">
                Quer revisar ou adicionar arquivos?
              </PMedium>
              <PMedium marginBottom="0">
                Você ainda pode selecionar os documentos para revisar os arquivos enviados ou
                adicionar novos.
              </PMedium>
            </InterUIAlert>
          )}

          {(!hasAffiliatedInApp || hasAffiliatedInApp === 'S') && affiliatedAlert}
        </InterUIContainer>
      </InterUIContainer>
    </>
  )
}

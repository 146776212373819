import styled from 'styled-components'

export const ErrorContainer = styled.section`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  background-color: ${(props) => props.theme.colors.theme};

  z-index: 999;
`

export const BtnBack = styled.button`
  position: absolute;
  top: 19px;
  left: 24px;
`

import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUICheckBox,
  InterUIContainer,
  InterUIIcon,
  InterUISeparator,
  InterUITag,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { PageTitles } from '../../../../enums/pageTitles'
import { useBasePage } from '../../../../hooks/useBasePage'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { PersistedHiringActions } from '../../../../store/ducks/persistedHiring/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { ICheckoutProposal } from '../../../../store/ducks/proposal/types'
import { BtnLink, P, PMedium, PSmall } from '../../../../styles/commons'
import { isStringNotEmpty, numberFormat } from '../../../../utils/commons'
import { PanelMessage, PanelTitle } from '../../../binding/pages/EntityTax/EntityTax.styles'
import { TypesPaymentRoutes as Routes } from '../../routes'
import {
  AroundIcon,
  CashbackTitle,
  ContainerAction,
  ContainerBadges,
  ContainerFormPayment,
  ContainerSelect,
  ContrastLine,
  EffectiveDate,
  HeaderCenter,
  HeroBottomSheet,
  NormalLine,
  SelectAction,
  TitleAndSubTitle,
  TotalTag,
} from './CloseOrder.styles'
import HeroImg from '../../../../assets/img/afro-personas-man-grateful.png'

export const CloseOrder: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const theme = useTheme().colors

  const paymentPropose = useSelector(ProposalActions.getProposal)
  const effectiveDate = useSelector(ProposalActions.getEffectiveDate)
  const formPayment = useSelector(ProposalActions.getFormPayment)
  const checkout = useSelector(ProposalActions.getCheckoutProposal)

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [pendingBottomSheet, setPendingBottomSheet] = useState(false)
  const [cashbackBottomSheet, setCashbackBottomSheet] = useState(false)

  const { plan } = paymentPropose

  const formValid = acceptTerms && formPayment && effectiveDate

  useBasePage({
    navbarTitle: PageTitles.PAYMENT,
    backNavigationHandler: history.goBack,
  })

  const showBottomSheet = (checkoutResponse: ICheckoutProposal) => {
    dispatch(PersistedHiringActions.clearAllState())

    if (checkoutResponse && !checkoutResponse.readyTransmission) {
      setPendingBottomSheet(true)
    } else {
      history.push(Routes.RECEIPT)
    }
  }

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_T_Contratar', {
      content_id: plan.displayName,
      tela: 'Pagamento',
    })

    dispatch(
      ProposalActions.getCheckoutProposalRequest({
        onTransmission: showBottomSheet,
      }),
    )
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!formValid}>
      Contratar - {numberFormat(+paymentPropose?.plan?.price)}
    </Button>
  )

  const openProposalContractPdf = (sender: string) => {
    if (sender === 'icon') {
      BaseBridge.requestAnalytics('C_PlanoSaude_Contrato_DC')
    }
    BaseBridge.requestAnalytics('C_PlanoSaude_TermoCondicoes_DC')
    history.push(Routes.PROPOSAL_CONTRACT)
  }

  const openReadjustmentLetter = () => {
    history.push(Routes.READJUSTMENT_LETTER)
  }

  const planValueAlert = (
    <InterUIAlert type="attention" margin="24px 0 32px 0">
      <PanelTitle>Valor do plano atualizado</PanelTitle>

      <PanelMessage>
        Devido ao acréscimo ou variação dos dados de dependentes, ou atualizações dos valores e
        taxas da operadora, o valor do seu plano está diferente do exibido na simulação inicial.
      </PanelMessage>
    </InterUIAlert>
  )

  const getCashbackValue = () => {
    const value = paymentPropose?.plan?.cashback || 0
    return numberFormat(value)
  }

  const planCashback = (
    <>
      <InterUISeparator variant="small" />
      <ContainerSelect
        onClick={() => setCashbackBottomSheet(true)}
        data-testid="cashback-container"
      >
        <AroundIcon>
          <InterUIIcon name="cashback" size="md" color={theme.feedback.success.A500} />
        </AroundIcon>

        <span className="cashback-message">
          Você ganhará {getCashbackValue()} de cashback na primeira parcela
        </span>

        <InterUIIcon
          className="icon"
          name="arrow-chevron-right"
          size="md"
          color={theme.primary.A500}
        />
      </ContainerSelect>

      <InterUISeparator variant="small" />
    </>
  )

  const renderBadges = plan?.badges?.map((item, index) => {
    const key = `badge-${index}`

    return (
      <InterUITag key={key} margin="0 8px 8px 0px" type="radio" name="badge" id={item} value={item}>
        {item}
      </InterUITag>
    )
  })

  const renderPendings = checkout?.pending?.map((item, index) => {
    const key = `pending-${index}`

    return (
      <PMedium scale={400} key={key}>
        &ndash;&nbsp;{item}
      </PMedium>
    )
  })

  const goSelectEffectiveDate = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Pag_T_DataInicioFormaPag')
    dispatch(
      HealthPlanActions.getAvailableEffectiveDatesRequest({
        history,
        pathname: Routes.EFFECTIVE_DATE,
      }),
    )
  }

  const goSelectFormPayment = () => {
    if (effectiveDate) {
      history.push(Routes.FORM_PAYMENT)
    }
  }

  const readjustmentConditions = () => (
    <>
      e das{' '}
      <BtnLink
        onClick={() => {
          openReadjustmentLetter()
        }}
        data-testid="readjustment-letter-link"
      >
        Condições de Reajuste
      </BtnLink>
    </>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIContainer margin="25px 24px">
          <HeaderCenter>{plan?.displayName}</HeaderCenter>

          <ContainerBadges>{renderBadges}</ContainerBadges>

          <TotalTag>
            <P scale={400}> Total </P>
            <h1>{numberFormat(+paymentPropose?.plan?.price)} por mês</h1>
          </TotalTag>

          {paymentPropose?.plan?.cashback ? planCashback : null}

          {paymentPropose?.plan?.changedValue ? planValueAlert : null}
        </InterUIContainer>

        <InterUISeparator />

        <ContainerAction onClick={goSelectEffectiveDate}>
          <SelectAction className={`${effectiveDate ? 'actived' : ''}`}>
            <EffectiveDate>
              {effectiveDate || 'Data de início e do débito mensal do plano'}
            </EffectiveDate>
            <InterUIIcon name="arrow-chevron-right" size="ld" color={theme.primary.A500} />
          </SelectAction>
        </ContainerAction>

        <InterUISeparator />

        <ContainerAction onClick={goSelectFormPayment}>
          <SelectAction className={`${formPayment ? 'actived' : ''}`}>
            {formPayment ? (
              <ContainerFormPayment>
                <ContrastLine>Débito em conta</ContrastLine>
                <NormalLine>Debitado todo dia {effectiveDate.split('/')[0]}</NormalLine>
                <span>{numberFormat(+paymentPropose.plan.price)}</span>
              </ContainerFormPayment>
            ) : (
              'Forma de pagamento'
            )}
            <InterUIIcon name="arrow-chevron-right" size="ld" color={theme.primary.A500} />
          </SelectAction>
        </ContainerAction>

        <InterUISeparator />

        <InterUIContainer margin="24px 24px 16px 24px">
          <ContainerSelect
            onClick={() => {
              BaseBridge.requestAnalytics('C_PlanoSaude_Pag_T_DetalhesPlano')
              history.push(Routes.HEALTH_PLAN_DETAILS)
            }}
          >
            <InterUIIcon name="goal" size="md" color={theme.neutral.grayscale.A500} />
            <TitleAndSubTitle>
              <span>
                <strong>Detalhes do plano</strong>
              </span>
              <span>{plan?.displayName}</span>
            </TitleAndSubTitle>
            <InterUIIcon
              className="icon"
              name="arrow-chevron-right"
              size="md"
              color={theme.primary.A500}
            />
          </ContainerSelect>
        </InterUIContainer>

        <InterUIContainer margin="0 24px">
          <InterUISeparator variant="small" />
        </InterUIContainer>

        <InterUIContainer
          margin="24px 24px 21px 24px"
          onClick={() => {
            openProposalContractPdf('icon')
          }}
        >
          <ContainerSelect>
            <InterUIIcon name="pdf-file" size="md" color={theme.neutral.grayscale.A500} />
            <TitleAndSubTitle>
              <span>
                <strong>Termos e Condições</strong>
              </span>
              <span>Contrato de Seguro de Saúde {plan?.provider}</span>
            </TitleAndSubTitle>
            <InterUIIcon
              className="icon"
              name="arrow-chevron-right"
              size="md"
              color={theme.primary.A500}
            />
          </ContainerSelect>
        </InterUIContainer>

        <InterUISeparator height="1px" />

        <InterUICheckBox
          margin="8px 24px 0"
          id="acceptTerms"
          name="acceptTerms"
          value=" "
          onChange={() => setAcceptTerms(!acceptTerms)}
          checked={acceptTerms}
        >
          <PSmall>
            Estou ciente do{' '}
            <BtnLink
              onClick={() => {
                openProposalContractPdf('phrase')
              }}
            >
              Termo de Adesão
            </BtnLink>{' '}
            {isStringNotEmpty(plan?.readjustmentLetter) && readjustmentConditions()}.
          </PSmall>
        </InterUICheckBox>
      </InterUIContainer>

      <InterUIBottomSheet
        toggle={pendingBottomSheet}
        onHide={() => setPendingBottomSheet(false)}
        title="Há uma ou mais pendências na contratação"
        data-testid="pending-bottomsheet"
      >
        {renderPendings}
        <Button margin="20px 0" onClick={() => setPendingBottomSheet(false)}>
          Fechar
        </Button>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        toggle={cashbackBottomSheet}
        onHide={() => setCashbackBottomSheet(false)}
        data-testid="cashback-bottomsheet"
      >
        <HeroBottomSheet>
          <img src={HeroImg} alt="Personas" />
        </HeroBottomSheet>
        <CashbackTitle>Você ganhará {getCashbackValue()} de cashback</CashbackTitle>
        <PMedium marginBottom="0" color={theme.neutral.grayscale.A400}>
          Esse valor cairá direto na sua conta do Inter! Você poderá conferir no seu extrato logo
          após o pagamento da primeira parcela.
        </PMedium>
        <Button margin="32px 0 0 0" onClick={() => setCashbackBottomSheet(false)}>
          Entendi
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import BaseService from '../config/bridge/BaseService'
import {
  DPSDocsAttachedRequest,
  DPSDocsResponse,
  IDPSQuiz,
  IDPSQuizAnswers,
} from '../store/ducks/dps/types'

export default class DPSService {
  static async getQuizInfo() {
    return BaseService.doExecute<IDPSQuiz>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/dps/perguntas`,
    })
  }

  static async sendQuizAnswers(data: IDPSQuizAnswers) {
    return BaseService.doExecute<void>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/dps/respostas`,
      data,
    })
  }

  static async getDocumentsList(group?: string) {
    const queryString = group ? `?grupo=${group}` : ''

    return BaseService.doExecute<DPSDocsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/documentos${queryString}`,
    })
  }

  static async sendDocumentsAttached(data: DPSDocsAttachedRequest) {
    return BaseService.doExecute<void>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/documentos`,
      data,
    })
  }
}

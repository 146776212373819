/**
 * Títulos e mensagens da Home.
 */
export enum HomeMessages {
  TITLE_ERROR_ACTIVE_PLAN = 'Você já tem um plano de saúde contratado',
  MSG_ERROR_ACTIVE_PLAN = 'Nossos planos de saúde são oferecidos em parceria com a Qualicorp, e verificamos que você já tem um plano contratado através de lá. Por isso, não é possível fazer uma nova contratação por aqui. Para mais informações, entre em contato com a Qualicorp.',

  TITLE_ERROR_AGE = 'Exclusivo para maiores de 18 anos',
  MSG_ERROR_AGE = 'No momento não há entidades de classe e planos de saúde coletivos por adesão disponíveis para beneficiários titulares menores de 18 anos. Para mais informações sobre esse tipo de contratação, entre em contato com a Qualicorp. Enquanto isso, te convidamos a ver nossas outras opções de planos.',
}

/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

/**
 * Verifica se uma string é valida
 * @param str String a ser verificada
 * @returns Booleano informando se a String é valida ou não
 */
export const isStringNotEmpty = (str?: string) =>
  str !== undefined && str !== null && str !== '' && str.length > 0

/**
 * Remove as acentuações da string.
 * @param data String a ser removida a acentuação.
 * @param lowercase Indicador para informar se deseja transformar em lowercase.
 * @returns String sem acentuação.
 */
export const RemoveAccents = (data: string, lowercase = true) => {
  let format = data

  try {
    if (lowercase) {
      format = format.toLowerCase()
    }

    return format
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  } catch {
    return format
  }
}

/**
 * Informa se a string é referente a "nenhuma dessas".
 * @param data String a ser validada.
 * @returns Boleano informando a condição.
 */
export const isNoneOfThese = (data?: string | null) =>
  data?.trim().toLowerCase() === 'nenhuma dessas'

export const numberFormat = (data: number) => {
  try {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    return formatter.format(data)
  } catch {
    return data.toString()
  }
}

/**
 * Verifica se a data informada é superior a data atual.
 * @param date Data a ser comparada.
 * @returns Boleano informando se a data informada é superior a data atual
 */
export const greaterCurrentDate = (date: string) => {
  const today = new Date()
  const varDate = new Date(date)

  today.setHours(0, 0, 0, 0)

  return varDate > today
}

/**
 * Verifica se a data informada é superior a data uma outra data.
 * @param date1 Data a ser comparada.
 * @param date2 Data a ser comparada.
 * @returns Boleano informando se a data informada é superior a data atual
 */
export const dateOlderThan = (date1: string, date2: string) => {
  const firstDate = new Date(`${date1} 00:00:00`)
  const secondDate = new Date(`${date2} 00:00:00`)

  return firstDate > secondDate
}

/**
 * Retorna o timestamp atual somado a 15 dias. Prazo referente a persistência dos dados.
 * @returns Timestamp atual mais 15 dias.
 */
export const getTimestampForPersistence = () => {
  const date = new Date()

  date.setDate(date.getDate() + 15)
  date.setHours(23, 59, 59)

  return date.getTime()
}

/**
 * Informa se o prazo para persistência está dentro do esperado.
 * @param savedTime Último timestamp salvo na persistência.
 * @returns Boleano informando a validade.
 */
export const persistenceIsValid = (savedTime: number) => {
  const currentTime = new Date().getTime()

  return currentTime < savedTime
}

/**
 * Converte uma data string para formato de leitura humana
 * @param dateString Data em String.
 * @returns Formato de data e hora legíveis
 */

export const convertDateStringtoHuman = (dateString: string) => {
  const dateObj = new Date(dateString)
  const humanDate = `${`0${dateObj.getDate()}`.slice(-2)}/${`0${dateObj.getMonth() + 1}`.slice(
    -2,
  )}/${dateObj.getFullYear()} às ${dateObj.getUTCHours()}:${dateObj.getMinutes()}`

  return humanDate
}

import React from 'react'

import { useSelector } from 'react-redux'

import { InterUIOverlay, IInterUIOverlayProps } from '@interco/inter-ui-react-lib'

import { LoadingActions } from '../../store/ducks/loading/actions'

export const Overlay: React.FC<IInterUIOverlayProps> = ({ ...props }) => {
  const loading = useSelector(LoadingActions.get)

  return <InterUIOverlay {...props} show={loading} />
}

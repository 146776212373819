/**
 * Status disponíveis da proposta
 */

export enum ProposalStatus {
  COTACAO = 'COTACAO',
  EM_ANALISE = 'EM ANALISE',
  PENDENCIA = 'PENDENCIA',
  ATIVO = 'ATIVO',
  CANCELADO = 'CANCELADO',
}

import { Reducer } from 'redux'

import {
  DPSState,
  DPSTypes,
  IDocsAttached,
  IDPSQuiz,
  IDPSQuizAnswers,
  IHeightWeight,
  IQuizStep,
} from './types'

const INITIAL_STATE: DPSState = {
  quiz: {} as IDPSQuiz,
  quizStep: {} as IQuizStep,
  quizAnswers: {} as IDPSQuizAnswers,
  documentsList: [],
  heightWeight: {} as IHeightWeight,
  hasPreviousPlan: undefined,
  documentsAttached: [],
  selectedDocAttached: {} as IDocsAttached,
}

const reducer: Reducer<DPSState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DPSTypes.SET_DISEASES_CONDITIONS_QUESTIONS:
      return { ...state, quiz: action.payload }
    case DPSTypes.SET_QUIZ_STEP:
      return { ...state, quizStep: action.payload }
    case DPSTypes.SET_DISEASES_CONDITIONS_ANSWERS:
      return { ...state, quizAnswers: action.payload }
    case DPSTypes.SET_DOCUMENTS_LIST:
      return { ...state, documentsList: action.payload }
    case DPSTypes.SET_HAS_PREVIOUS_PLAN:
      return { ...state, hasPreviousPlan: action.payload }
    case DPSTypes.SET_HEIGHT_WEIGHT:
      return { ...state, heightWeight: action.payload }
    case DPSTypes.SET_DOCUMENTS_ATTACHED:
      return { ...state, documentsAttached: action.payload }
    case DPSTypes.SET_SELECTED_DOC_ATTACHED:
      return { ...state, selectedDocAttached: action.payload }
    case DPSTypes.SET_DOCUMENT_GROUP:
      return { ...state, documentGroup: action.payload }

    default:
      return state
  }
}

export default reducer

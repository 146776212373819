import React from 'react'

import { useHistory } from 'react-router-dom'

import { InterUIPdfReader } from '../../../../components/InterUIPdfReader/InterUIPdfReader'
import pdfFile from './rn162anexo.pdf'
import { PageTitles } from '../../../../enums/pageTitles'
import { useBasePage } from '../../../../hooks/useBasePage'

export const OrientationPdfLetter: React.FC = () => {
  const history = useHistory()

  useBasePage({
    navbarTitle: PageTitles.ORIENTATION_LETTER,
    backNavigationHandler: history.goBack,
  })

  return <InterUIPdfReader file={pdfFile} loadingMessage=" " />
}

import React from 'react'

import { Route } from 'react-router-dom'

import { TypesDocumentationRoutes as Routes } from './types'
import {
  DocumentationHome,
  InformHistoric,
  DocumentationList,
  HeightAndWeight,
  DiseasesConditionsForm,
  SendDocumentation,
  ShippingTips,
  DocumentationFile,
  ConfirmDpsOffline,
  ShutDownDpsOffline,
} from '../pages'
import { OrientationPdfLetter } from '../pages/OrientationPdfLetter/OrientationPdfLetter'

export const DocumentationRoutes: React.FC = () => (
  <>
    <Route path={Routes.DOCUMENTATION} exact component={DocumentationHome} />
    <Route path={Routes.ORIENTATION_LETTER} exact component={OrientationPdfLetter} />
    <Route path={Routes.HISTORIC} exact component={InformHistoric} />
    <Route path={Routes.DOCUMENT_LIST} exact component={DocumentationList} />
    <Route path={Routes.HEIGHT_WEIGHT} exact component={HeightAndWeight} />
    <Route path={Routes.DISEASES_CONDITIONS_FORM} exact component={DiseasesConditionsForm} />
    <Route path={Routes.SEND_DOCUMENTATION} exact component={SendDocumentation} />
    <Route path={Routes.SHIPPING_TIPS} exact component={ShippingTips} />
    <Route path={Routes.FILE_DOCUMENTATION} exact component={DocumentationFile} />
    <Route path={Routes.CONFIRM_DPS_OFFLINE} exact component={ConfirmDpsOffline} />
    <Route path={Routes.SHUTDOWN_DPS_OFFLINE} exact component={ShutDownDpsOffline} />
  </>
)

export * from './types'

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InterUIBottomSheet,
  InterUIContainer,
  InterUIProgressBar,
  InterUIRadio,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { AddressActions } from '../../../../store/ducks/address/actions'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PSmall, PMedium, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as Routes } from '../../routes'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const SelectAddress: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const addressList = useSelector(AddressActions.getAddressList)
  const selectedAddress = useSelector(AddressActions.getSelectedAddress)

  const [radioAddress, setRadioAddress] = useState<string | null>()
  const [bottomSheet, setBottomSheet] = useState<boolean>(false)

  useBasePage({
    navbarTitle: PageTitles.ADDRESS,
    backNavigationHandler: () => history.push(Routes.SIMULATION),
  })

  useEffect(() => {
    if (selectedAddress) {
      const addressIndex = addressList.findIndex((item) => item === selectedAddress)

      if (addressIndex !== -1) {
        setRadioAddress(addressIndex.toString())
      }
    }
  }, [])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioAddress(e.target.value)
  }

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Endereco_T_Continuar')

    if (radioAddress === '999') {
      dispatch(AddressActions.setSelectedAddress(null))
      dispatch(AddressActions.setSearchedAddress(null))

      setBottomSheet(true)
    } else if (radioAddress) {
      const addressFound = addressList[+radioAddress]

      dispatch(AddressActions.setSelectedAddress(addressFound))

      history.push(Routes.SELECT_BOND)
    }
  }

  const updateAddress = () => {
    setBottomSheet(false)

    history.push(Routes.UPDATE_ADDRESS)
  }

  const renderAddress = addressList.map((item, index) => {
    const key = `address-${index}`

    return (
      <InterUIRadio
        variant="choose-item"
        key={key}
        id={key}
        name="address"
        value={index}
        onChange={onChange}
        checked={radioAddress === `${index}`}
      >
        <PMedium bold marginBottom="4px">
          Moro em {item.address} {item.number && `, ${item.number}`}
        </PMedium>
        <PSmall scale={400} marginBottom="0">
          {item.neighborhood} {item.complement && ` - ${item.complement}`}
        </PSmall>
        <PSmall scale={400} marginBottom="0">
          CEP {item.zipcode}
        </PSmall>
        <PSmall scale={400} marginBottom="0">
          {item.city} | {item.district}
        </PSmall>
      </InterUIRadio>
    )
  })

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!radioAddress}>
      Continuar
    </Button>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="35%" />

        <InterUIContainer>
          <PSmall scale={300} bold>
            Passo 1/3
          </PSmall>

          <StepHeading>
            Seu endereço continua o mesmo ou mudou? Encontraremos os planos que melhor atendem sua
            localização
          </StepHeading>

          {renderAddress}

          <InterUIRadio
            variant="choose-item"
            id="address-x"
            name="address"
            value="999"
            onChange={onChange}
            checked={radioAddress === '999'}
          >
            <PMedium bold marginBottom="0">
              Outro endereço
            </PMedium>
          </InterUIRadio>
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        title="Outro endereço"
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
      >
        <PMedium scale={400}>
          Esse endereço será válido para a consulta dos planos e você enviará o comprovante lá na
          frente. O endereço do seu cadastro aqui no Inter continuará o mesmo, ok?
        </PMedium>

        <Button margin="20px 0 0" onClick={updateAddress}>
          Informar outro endereço
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { ErrorTypes } from './types'

const show = () => action(ErrorTypes.SHOW, true)

const hide = () => action(ErrorTypes.HIDE, false)

const get = (state: RootState) => state.error.toggle

const setViewedHero = (data: string) => action(ErrorTypes.SET_VIEWED_HERO, data)

const getViewedHero = (state: RootState) => state.error.viewedHero

const setNumberAttempts = (data: number) => action(ErrorTypes.SET_NUMBER_ATTEMPTS, data)

const getNumberAttempts = (state: RootState) => state.error.numberAttempts

export const ErrorActions = {
  show,
  hide,
  get,
  setViewedHero,
  getViewedHero,
  setNumberAttempts,
  getNumberAttempts,
}

import { Reducer } from 'redux'

import { PendingState, PendingTypes } from './types'

const INITIAL_STATE: PendingState = {
  pendings: [],
  resolutionOfPending: {
    name: '',
    mothersName: '',
    gender: '',
    maritalStatus: '',
    rg: '',
    email: '',
    address: {
      zipcode: '',
      typeAddress: '',
      address: '',
      number: undefined,
      city: '',
      neighborhood: '',
      district: '',
      complement: '',
    },
    effectiveDate: '',
  },
}

const reducer: Reducer<PendingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PendingTypes.SET_PENDING:
      return { ...state, pendings: action.payload }

    case PendingTypes.SEND_RESOLUTION_OF_PENDING:
      return { ...state, resolutionOfPending: action.payload }

    default:
      return state
  }
}

export default reducer

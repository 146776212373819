import { Reducer } from 'redux'

import { BindingState, BindingTypes, IBondFieldGroup } from './types'
import { QuestionGroups } from '../../../enums/DynamicFieldConfig'

const INITIAL_STATE: BindingState = {
  documentsList: [],

  currentGroupQuestions: QuestionGroups.PROFESSIONAL_DATA,
  dynamicQuestions: [],
  questionsProfessional: {} as IBondFieldGroup,
  questionsPersonal: {} as IBondFieldGroup,
  questionsAcademic: {} as IBondFieldGroup,
  answersDynamicQuestions: undefined,
  checkFiliation: undefined,
}

const reducer: Reducer<BindingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BindingTypes.SET_DOCUMENTS_LIST:
      return { ...state, documentsList: action.payload }

    case BindingTypes.SET_CURRENT_GROUP_QUESTIONS:
      return { ...state, currentGroupQuestions: action.payload }

    case BindingTypes.SET_DYNAMIC_QUESTIONS:
      return { ...state, dynamicQuestions: action.payload }

    case BindingTypes.SET_QUESTIONS_OCCUPATION:
      return { ...state, questionsProfessional: action.payload }

    case BindingTypes.SET_QUESTIONS_PERSONAL:
      return { ...state, questionsPersonal: action.payload }

    case BindingTypes.SET_QUESTIONS_ACADEMIC:
      return { ...state, questionsAcademic: action.payload }

    case BindingTypes.SET_ANSWERS_DYNAMIC_QUESTIONS:
      return { ...state, answersDynamicQuestions: action.payload }

    case BindingTypes.SET_CHECK_FILIATION:
      return { ...state, checkFiliation: action.payload }

    default:
      return state
  }
}

export default reducer

import React from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { useBasePage } from '../../../../hooks/useBasePage'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { Button } from '../../../../components'
import { H1, Hero, PMedium } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesDocumentationRoutes as Routes } from '../../routes'
import Personas from '../../../../assets/img/personas-error.png'

export const ConfirmDpsOffline: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const onConfirm = () => {
    dispatch(
      ProposalActions.cancelProposalRequest({
        history,
        pathname: Routes.SHUTDOWN_DPS_OFFLINE,
      }),
    )
  }

  const stickyFooter = (
    <>
      <Button margin="0px 0px 16px 0px" onClick={onConfirm}>
        Confirmar resposta offline
      </Button>

      <Button variant="secondary" onClick={history.goBack}>
        Responder no app
      </Button>
    </>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Hero>
        <img src={Personas} alt="Personas" />
      </Hero>

      <H1 marginBottom="8px" textAlign="center">
        Responder offline
      </H1>

      <PMedium scale={400} marginBottom="17px" textAlign="center">
        Para responder offline, é preciso que você ligue para nossa parceira, a Qualicorp, no (11)
        4004-4400 para capitais e regiões metropolitana ou 0800 016 2000 para as demais regiões, e
        converse com um dos corretores, recomeçando sua contratação do zero.
      </PMedium>

      <PMedium scale={400} textAlign="center">
        Você quer mesmo responder offline?
      </PMedium>
    </InterUIContainer>
  )
}

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InterUIBottomSheet,
  InterUIContainer,
  InterUIProgressBar,
  InterUIRadio,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { useBasePage } from '../../../../hooks/useBasePage'
import { MembershipFee } from './BindToEntity.styles'
import { PSmall, PMedium, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as Routes } from '../../routes'
import { isNoneOfThese, numberFormat } from '../../../../utils/commons'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const BindToEntity: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const entities = useSelector(ClassEntityActions.getListEntities)
  const selectedEntity = useSelector(ClassEntityActions.getSelectedClassEntity)

  const [entity, setEntity] = useState<string>()
  const [unhedgedBottomSheet, setUnhedgedBottomSheet] = useState<boolean>(false)

  useBasePage({
    navbarTitle: PageTitles.CLASS_ENTITY,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    if (selectedEntity) {
      const entityIndex = entities.findIndex((item) => item === selectedEntity)

      if (entityIndex !== -1) {
        setEntity(entityIndex.toString())
      }
    }
  }, [])

  const showUnhedgedBottomSheet = () => setUnhedgedBottomSheet(true)

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_EntidaClasse_T_EntidadeCont')
    const selectEntity = entity ? entities[+entity] : null

    if (isNoneOfThese(selectEntity?.name)) {
      history.push(Routes.WITHOUT_COVER)
    } else if (selectEntity) {
      dispatch(ClassEntityActions.setSelectedClassEntity(selectEntity))

      dispatch(HealthPlanActions.clearAllHealthPlanState())

      dispatch(
        HealthPlanActions.getAllHealthPlansRequest({
          history,
          pathname: Routes.HEALTH_PLAN_LIST,
          onError: showUnhedgedBottomSheet,
        }),
      )
    }
  }

  const renderEntities = entities.map((item, index) => {
    const key = `entity-${index}`

    const getNumberFormat = (object?: number) => {
      const num = object || 0

      return numberFormat(num)
    }

    const renderMemberFee = () => {
      if (!item.membershipFee && !item.maxMembershipFee && !item.minMembershipFee) {
        return <MembershipFee>R$ 0,00 por mês</MembershipFee>
      }
      if (item.minMembershipFee === item.maxMembershipFee) {
        return (
          <MembershipFee>
            {`${getNumberFormat(item.membershipFee || item.maxMembershipFee)}/mês`}
          </MembershipFee>
        )
      }
      return (
        <MembershipFee>
          {`Entre ${getNumberFormat(item.minMembershipFee)}
          e ${getNumberFormat(item.maxMembershipFee)} por mês`}
        </MembershipFee>
      )
    }

    return (
      <InterUIRadio
        variant="choose-item"
        key={key}
        id={key}
        name="entity"
        value={index}
        onChange={(e) => setEntity(e.target.value)}
        checked={entity === index.toString()}
      >
        {!isNoneOfThese(item.name) ? (
          <>
            <PMedium bold marginBottom="4px">
              {item.tradingName}
            </PMedium>

            <PSmall scale={400} marginBottom="8px">
              {item.name}
            </PSmall>

            <MembershipFee>Taxa de filiação da entidade de classe</MembershipFee>

            {renderMemberFee()}
          </>
        ) : (
          <PSmall marginBottom="0" bold>
            {item.name}
          </PSmall>
        )}
      </InterUIRadio>
    )
  })

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!entity}>
      Continuar
    </Button>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="70%" aria-valuenow={2} aria-valuemin={1} aria-valuemax={3} />

        <InterUIContainer>
          <PSmall scale={300} bold>
            Passo 2/3
          </PSmall>

          <StepHeading>
            Escolha uma entidade de classe para continuar a simulação e ver as opções de planos.
          </StepHeading>

          <PMedium margin="-8px 0 16px">
            A entidade de classe permite a sua entrada em um plano coletivo e a filiação é
            rapidinho, junto com a contratação do seu plano.
          </PMedium>

          {renderEntities}
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        toggle={unhedgedBottomSheet}
        onHide={() => setUnhedgedBottomSheet(false)}
        title="Entidade sem cobertura no endereço"
      >
        <PMedium margin="0 0 12px 0" scale={400}>
          No momento não há cobertura diponível nesse endereço para a entidade selecionada. Tente
          escolher outra entidade.
        </PMedium>
        <Button margin="20px 0" onClick={() => setUnhedgedBottomSheet(false)}>
          Entendi
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { ClassUserTypes, UserState } from './types'

const getUserDetailsRequest = (data?: UserState | null) =>
  action(ClassUserTypes.GET_USER_DETAILS, data)

const getUserDetails = (state: RootState) => state.user

const setUserDetails = (data: UserState | null) => action(ClassUserTypes.SET_USER_DETAILS, data)

export const UserActions = {
  getUserDetailsRequest,
  getUserDetails,
  setUserDetails,
}

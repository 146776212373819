import React from 'react'

import { Route } from 'react-router-dom'

import {
  CloseOrder,
  EffectiveDate,
  FormPayment,
  HealthPlanDetails,
  PaymentReceipt,
  ProposalContract,
  ReadjustmentLetter,
} from '../pages'
import { TypesPaymentRoutes as Routes } from './types'

export const PaymentRoutes: React.FC = () => (
  <>
    <Route path={Routes.CLOSE_ORDER} exact component={CloseOrder} />
    <Route path={Routes.EFFECTIVE_DATE} exact component={EffectiveDate} />
    <Route path={Routes.FORM_PAYMENT} exact component={FormPayment} />
    <Route path={Routes.PROPOSAL_CONTRACT} exact component={ProposalContract} />
    <Route path={Routes.HEALTH_PLAN_DETAILS} exact component={HealthPlanDetails} />
    <Route path={Routes.RECEIPT} exact component={PaymentReceipt} />
    <Route path={Routes.READJUSTMENT_LETTER} exact component={ReadjustmentLetter} />
  </>
)

export * from './types'

import { FieldTypes, QuestionGroups } from '../../../enums/DynamicFieldConfig'

export enum BindingTypes {
  GET_DOCUMENTS_LIST = `@binding/GET_DOCUMENTS_LIST`,
  SET_DOCUMENTS_LIST = `@binding/SET_DOCUMENTS_LIST`,

  GET_DYNAMIC_QUESTIONS = `@binding/GET_DYNAMIC_QUESTIONS`,
  SET_CURRENT_GROUP_QUESTIONS = `@binding/SET_CURRENT_GROUP_QUESTIONS`,
  SET_DYNAMIC_QUESTIONS = `@binding/SET_DYNAMIC_QUESTIONS`,
  SET_QUESTIONS_OCCUPATION = `@binding/SET_QUESTIONS_OCCUPATION`,
  SET_QUESTIONS_PERSONAL = `@binding/SET_QUESTIONS_PERSONAL`,
  SET_QUESTIONS_ACADEMIC = `@binding/SET_QUESTIONS_ACADEMIC`,
  SET_ANSWERS_DYNAMIC_QUESTIONS = `@binding/SET_ANSWERS_DYNAMIC_QUESTIONS`,

  CREATE_FILIATION = `@binding/CREATE_FILIATION`,
  CREATE_ASSOCIATION_SIGN_UP = `@binding/CREATE_ASSOCIATION_SIGN_UP`,

  GET_CHECK_FILIATION = `@binding/GET_CHECK_FILIATION`,
  SET_CHECK_FILIATION = `@binding/SET_CHECK_FILIATION`,
}

export interface IBindingDoc {
  id: string
  title: string
}

export interface BindingDocsResponse {
  arquivos: IBindingDoc[]
}

export interface IBondField {
  name: string
  type: FieldTypes
  /**
   * @example
   * ###.###.###-##
   */
  format?: string
  description?: string
  value?: string
  enumValues?: {
    label: string
    value: string
  }[]
}

export interface IBondFieldGroup {
  group: QuestionGroups
  fields: IBondField[]
}

export interface InfoToBondResponse {
  questions: IBondFieldGroup[]
}

export interface CreateAssociationSignUpRequest {
  documentNumber: string
  signature: string
  signatureLocation?: string
}

export interface CreateFiliationRequest {
  fields: IBondField[]
}

export interface CheckFiliationResponse {
  filiated: boolean
}

export interface BindingState {
  readonly documentsList: IBindingDoc[]

  readonly currentGroupQuestions: QuestionGroups
  readonly dynamicQuestions: IBondFieldGroup[]
  readonly questionsProfessional: IBondFieldGroup
  readonly questionsPersonal: IBondFieldGroup
  readonly questionsAcademic: IBondFieldGroup
  readonly answersDynamicQuestions?: IBondField[]
  readonly checkFiliation?: CheckFiliationResponse
}

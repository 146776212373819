import { createGlobalStyle } from 'styled-components'

import { Colors } from './colors'

export const GlobalStyle = createGlobalStyle`
  .orange-page {
    &, * {
      background-color: ${Colors.interOrange};
      color: ${Colors.white} !important;
    }
  }
`

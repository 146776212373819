import styled from 'styled-components'

import { PSmall } from '../../styles/commons'

export const NumberContainer = styled.div`
  display: flex;
  margin: 0 0 35px;
`

export const ErrorMessage = styled(PSmall)`
  margin: 4px 0 16px;
  min-height: 0.9375rem;
  color: ${(props) => `${props.theme.colors.feedback.error.A500}`};
`

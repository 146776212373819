import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import { InterUIIcon } from '@interco/inter-ui-react-lib'

import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { IHealthPlan, IHealthPlanDetails } from '../../../../store/ducks/healthPlan/types'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PageTitles } from '../../../../enums/pageTitles'
import { Button, PlanDetails } from '../../../../components'
import { TypesBindingRoutes as BindingRoutes } from '../../../binding/routes'
import { TypesDocumentationRoutes as DocumentationRoutes } from '../../../documentation/routes'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const HealthPlanDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const hasBond = useSelector(ClassEntityActions.getSelectedBond)
  const plan = useSelector(HealthPlanActions.getSelectedHealthPlan)
  const planDetails = useSelector(HealthPlanActions.getHealthPlanDetails)

  useBasePage({
    navbarTitle: PageTitles.HEALTH_PLAN_DETAILS,
    backNavigationHandler: history.goBack,
  })

  const onHireNow = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_DetalhesPlano_BTN_Continuar')
    const proposalDestiny =
      hasBond === 'N' ? BindingRoutes.BINDING : DocumentationRoutes.DOCUMENTATION

    dispatch(
      ProposalActions.createProposalRequest({
        history,
        pathname: proposalDestiny,
      }),
    )
  }

  const footer = (
    <Button margin="16px 24px 24px" alignContent="space-between" onClick={onHireNow}>
      <p>Continuar</p>

      <InterUIIcon name="arrow-chevron-right" size="md" color={theme.colors.base.white} />
    </Button>
  )

  return (
    <PlanDetails
      plan={plan || ({} as IHealthPlan)}
      planDetails={planDetails || ({} as IHealthPlanDetails)}
      footer={footer}
    />
  )
}

import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIIcon,
  InterUISeparator,
  InterUIListDescription,
  InterUIButton,
} from '@interco/inter-ui-react-lib'

import { useBasePage } from '../../../../hooks/useBasePage'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { BindingActions } from '../../../../store/ducks/binding/actions'
import { PMedium, StepHeading, P } from '../../../../styles/commons'
import { Colors } from '../../../../styles/colors'
import { PageTitles } from '../../../../enums/pageTitles'
import { QuestionGroups } from '../../../../enums/DynamicFieldConfig'
import { TypesBindingRoutes as Routes } from '../../routes/types'
import { TypesSimulationRoutes as SimulationRoutes } from '../../../simulation/routes/types'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const BindingHome: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const primaryColor = useTheme().colors.primary.A500

  const entity = useSelector(ClassEntityActions.getSelectedClassEntity)
  const documentationsList = useSelector(BindingActions.getDocumentsList)

  useBasePage({
    navbarTitle: PageTitles.DOCUMENTS,
    backNavigationHandler: history.goBack,
  })

  const loadDynamicQuestions = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Documentos_T_Botao', {
      content_name: 'Tenho todos os documentos',
    })

    dispatch(BindingActions.setCurrentGroupQuestions(QuestionGroups.PROFESSIONAL_DATA))

    dispatch(
      BindingActions.getDynamicQuestionsRequest({
        history,
        pathname: Routes.DYNAMIC_QUESTIONS,
      }),
    )
  }

  const backToSelectBond = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Documentos_T_Botao', {
      content_name: 'Nova simulação',
    })
    history.push(SimulationRoutes.SELECT_BOND)
  }

  const renderDocumentations = documentationsList.map((item, index) => {
    const key = `documentation-${index}`

    const iconCheck = <InterUIIcon name="check-underlined" size="sm" color={primaryColor} />

    return (
      <React.Fragment key={key}>
        <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
          <PMedium scale={400} marginBottom="0">
            {item.title}
          </PMedium>
        </InterUIListDescription>
      </React.Fragment>
    )
  })

  const stickyFooter = (
    <>
      <InterUIContainer>
        <InterUIButton
          alignContent="space-between"
          margin="0px 0px 16px 0px"
          onClick={loadDynamicQuestions}
        >
          <p>Tenho todos os documentos</p>

          <InterUIIcon name="arrow-right" size="md" color={Colors.white} />
        </InterUIButton>

        <InterUIButton alignContent="space-between" onClick={backToSelectBond} variant="secondary">
          <p>Nova simulação</p>

          <InterUIIcon name="arrow-right" size="md" color={Colors.interOrange} />
        </InterUIButton>
      </InterUIContainer>
    </>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIContainer>
          <InterUIContainer margin="0 0 32px">
            <StepHeading>
              Pra contratar esse plano, você precisa se vincular à entidade
              {` ${entity?.tradingName}`}. Confirmaremos alguns dados e, ao final da contratação,
              você precisará enviar alguns documentos:
            </StepHeading>
          </InterUIContainer>

          <InterUISeparator variant="small" />

          <InterUIContainer margin="24px 0">
            <P bold marginBottom="24px">
              Documentos obrigatórios
            </P>

            {renderDocumentations}
          </InterUIContainer>
        </InterUIContainer>
      </InterUIContainer>
    </>
  )
}

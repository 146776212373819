import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AddressForm } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { AddressActions } from '../../../../store/ducks/address/actions'
import { IAddress } from '../../../../store/ducks/address/types'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as Routes } from '../../routes'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const UpdateAddress: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useBasePage({
    navbarTitle: PageTitles.ADDRESS,
    backNavigationHandler: history.goBack,
  })

  const onSubmit = (address: IAddress) => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Endereco_T_Alterar')

    dispatch(AddressActions.setSelectedAddress(address))

    dispatch(
      AddressActions.updateAddressRequest({
        history,
        pathname: Routes.SELECT_BOND,
        address,
      }),
    )
  }

  return (
    <>
      <AddressForm onSubmit={onSubmit} goBackHome={() => history.push(Routes.SIMULATION)} />
    </>
  )
}

import { Reducer } from 'redux'

import { ClassUserTypes, UserState } from './types'

const INITIAL_STATE: UserState = {
  birthday: '',
  activeInsurance: false,
  child: false,
  name: '',
  cpf: '',
  address: [],
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClassUserTypes.GET_USER_DETAILS:
      return { ...state, ...action.payload }
    case ClassUserTypes.SET_USER_DETAILS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default reducer

/**
 * Rotas disponíveis na vinculação.
 */
export enum TypesBindingRoutes {
  BINDING = '/binding',
  DYNAMIC_QUESTIONS = '/binding/dynamic-questions',
  ENTITY_TAX = '/binding/entity-tax',
  WHATSAPP = '/binding/whatsapp',
  PENDING_CONFIRMATION = '/binding/whatsapp/pending-confirmation',
  RESENT_MESSAGE = '/binding/whatsapp/resent-message',
  BINDING_CONFIRMED = '/binding/confirmed',
}

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { InterUIContainer, InterUIProgressBar, InterUISeparator } from '@interco/inter-ui-react-lib'

import { useBasePage } from '../../../../hooks/useBasePage'
import { UserActions } from '../../../../store/ducks/user/actions'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { IQuizAnswer } from '../../../../store/ducks/dps/types'
import { Button, InputWithHook } from '../../../../components'
import { PSmall } from '../../../../styles/commons'
import { ConditionalInfo, QuestionHeader } from './DiseasesConditionsForm.styles'
import { PageTitles } from '../../../../enums/pageTitles'
import { dateOlderThan, greaterCurrentDate } from '../../../../utils/commons'
import { TypesDocumentationRoutes as Routes } from '../../routes'

export const DiseasesConditionsForm: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const userData = useSelector(UserActions.getUserDetails)
  const quizList = useSelector(DPSActions.getQuizList)
  const quizStep = useSelector(DPSActions.getQuizStep)
  const quizAnswers = useSelector(DPSActions.getQuizAnswers)

  const [question] = useState(quizList[quizStep.current - 1])

  const { register, handleSubmit, setValue, reset, watch } = useForm()

  const answer = watch('answer')
  const justDate = watch('justification.date')
  const justDesc = watch('justification.description')

  const backNavigationHandler = () => {
    if (quizStep.current > 1) {
      const step = { ...quizStep, current: quizStep.current - 1 }

      dispatch(DPSActions.setQuizStep(step))
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.DISEASES_AND_CONDITIONS,
    backNavigationHandler,
  })

  useEffect(() => {
    const current = quizStep?.current - 1

    if (quizAnswers?.quiz?.length > 0 && quizAnswers?.quiz[current]) {
      const oldAnswer = quizAnswers?.quiz[current]
      const formValues = { ...oldAnswer }

      formValues.answer = formValues.answer ? 'S' : 'N'

      reset(formValues)
    }
  }, [])

  const onChangeAnswer = (answerValue: boolean) => {
    setValue('answer', answerValue ? 'S' : 'N')

    setValue('justification.date', undefined)
    setValue('justification.description', undefined)
  }

  const disableButton = () =>
    !answer ||
    (answer === 'S' &&
      (!justDate ||
        !justDesc ||
        (justDate && (greaterCurrentDate(justDate) || dateOlderThan(userData.birthday, justDate)))))

  const onSubmit = (data: IQuizAnswer) => {
    const currentStep = quizStep.current - 1

    const newAnswers = { ...quizAnswers }
    const anwserPayload = { ...data, answer: data.answer === 'S' }

    if (newAnswers) {
      if (newAnswers?.quiz?.length > 0 && newAnswers?.quiz[currentStep]) {
        newAnswers.quiz[currentStep] = anwserPayload
      } else {
        newAnswers?.quiz.push(anwserPayload)
      }

      dispatch(DPSActions.setQuizAnswers(newAnswers))

      if (quizStep.current < quizStep.length) {
        const step = { ...quizStep, current: quizStep.current + 1 }

        dispatch(DPSActions.setQuizStep(step))

        history.push(Routes.DISEASES_CONDITIONS_FORM)
      } else {
        dispatch(
          DPSActions.sendQuizAnswersRequest({
            history,
            pathname: Routes.DOCUMENT_LIST,
          }),
        )
      }
    }
  }

  const stickyFooter = (
    <Button type="submit" margin="24px" disabled={disableButton()}>
      Continuar
    </Button>
  )

  const diagnosisJustification = (
    <>
      <InterUISeparator variant="small" />

      <ConditionalInfo marginBottom="0" scale={400}>
        Informe a data do diagnóstico e, se necessário, descreva o tipo, o tratamento, as
        complicações e/ou manifestações, etc.
      </ConditionalInfo>

      <InputWithHook
        register={register}
        margin="40px 0"
        label="Data do diagnóstico"
        type="date"
        id="justification.date"
        name="justification.date"
        autoComplete="off"
        disabled={answer !== 'S'}
      />

      <InputWithHook
        register={register}
        margin="40px 0 24px"
        layout="textarea"
        label="Descreva a doença ou condição"
        id="justification.description"
        name="justification.description"
        autoComplete="off"
        rows={10}
        placeholder="Informe o que for necessário. (Ex: tipo, tratamento, complicações, etc.)"
        disabled={answer !== 'S'}
      />
    </>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar
          progress={`${quizStep?.amountProgress * (quizStep?.current + 2)}%`}
          aria-valuenow={Number(quizStep?.current || 0)}
          aria-valuemin={1}
          aria-valuemax={Number(quizStep?.length || 0)}
        />

        <InterUIContainer>
          <PSmall scale={300} bold>
            Pergunta {quizStep?.current}/{quizStep?.length}
          </PSmall>

          <QuestionHeader>
            Responda sobre as doenças ou condições que você tem ou já teve.
          </QuestionHeader>

          <PSmall scale={400}>{question.question}</PSmall>

          <InputWithHook
            register={register}
            validation={{ valueAsNumber: true }}
            type="hidden"
            id="code"
            name="code"
            defaultValue={question.code}
          />

          <InputWithHook
            register={register}
            type="hidden"
            id="question"
            name="question"
            defaultValue={question.question}
          />

          <InputWithHook
            register={register}
            layout="radio"
            label="Sim"
            id="sim"
            name="answer"
            value="S"
            onChange={() => onChangeAnswer(true)}
          />

          <InputWithHook
            register={register}
            margin="0 0 24px"
            layout="radio"
            border="none"
            label="Não"
            id="nao"
            name="answer"
            value="N"
            onChange={() => onChangeAnswer(false)}
          />

          {answer === 'S' && diagnosisJustification}
        </InterUIContainer>
      </InterUIContainer>
    </form>
  )
}

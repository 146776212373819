export enum DPSTypes {
  GET_DOCUMENTS_LIST = `@dps/GET_DOCUMENTS_LIST`,
  SET_DOCUMENTS_LIST = `@dps/SET_DOCUMENTS_LIST`,
  GET_DISEASES_CONDITIONS_QUESTIONS = `@dps/GET_DISEASES_CONDITIONS_QUESTIONS`,
  SET_DISEASES_CONDITIONS_QUESTIONS = `@dps/SET_DISEASES_CONDITIONS_QUESTIONS`,
  SET_DISEASES_CONDITIONS_ANSWERS = `@dps/SET_DISEASES_CONDITIONS_ANSWERS`,
  SET_QUIZ_STEP = `@dps/SET_QUIZ_STEP`,
  SEND_QUIZ_ANSWERS = `@dps/SEND_QUIZ_ANSWERS`,
  SET_HEIGHT_WEIGHT = `@dps/SET_HEIGHT_WEIGHT`,
  SET_HAS_PREVIOUS_PLAN = `@dps/SET_HAS_PREVIOUS_PLAN`,
  SET_DOCUMENTS_ATTACHED = `@dps/SET_DOCUMENTS_ATTACHED`,
  SET_SELECTED_DOC_ATTACHED = `@dps/SET_SELECTED_DOC_ATTACHED`,
  SET_DOCUMENT_GROUP = `@dps/SET_DOCUMENT_GROUP`,
  SEND_DOCUMENTS_ATTACHED = `@dps/SEND_DOCUMENTS_ATTACHED`,
}

export interface IDPSQuestion {
  order: number
  code: number
  type: string
  question: string
}

export interface IDPSQuiz {
  guid: string
  quiz: IDPSQuestion[]
}

export interface IQuizStep {
  current: number
  length: number
  amountProgress: number
}

export interface IQuizAnswer {
  code: number
  question: string
  answer: boolean | string | number
  justification?: {
    date: string
    description: string
  }
}

export interface IDPSQuizAnswers {
  guid: string
  quiz: IQuizAnswer[]
}

export interface IDPSDoc {
  id: string
  title: string
}

export interface DPSDocsResponse {
  arquivos: IDPSDoc[]
}

export interface DPSDocsAttachedRequest {
  documents: IDocsAttached
}

export interface IDocInfoAttached {
  name: string
  extension: string
  stream: string
  note?: string

  size?: number
}

export interface IDocsAttached {
  id: string
  title: string
  files: IDocInfoAttached[]
}

export interface IHeightWeight {
  height: number
  weight: number
}

export interface DPSState {
  readonly quiz: IDPSQuiz
  readonly quizStep: IQuizStep
  readonly quizAnswers: IDPSQuizAnswers
  readonly documentsList: IDPSDoc[]
  readonly heightWeight: IHeightWeight
  readonly hasPreviousPlan?: boolean
  readonly documentsAttached: IDocsAttached[]
  readonly selectedDocAttached: IDocsAttached
  readonly documentGroup?: string
}

/**
 * Títulos e mensagens do endereço.
 */
export enum AddressMessages {
  TITLE_UPDATE_ADDRESS = 'Atualização de endereço solicitada',
  MSG_UPDATE_ADDRESS = 'Agora, analisaremos sua solicitação. Você receberá o retorno em até 2 dias úteis. Enquanto isso, você continua sua simulação com o novo endereço informado.',

  TITLE_CEP_NOT_COVERAGE = 'CEP sem cobertura',
  MSG_CEP_NOT_COVERAGE = 'No momento, infelizmente não temos nenhuma opção de plano de saúde para o seu endereço residencial informado.',

  TITLE_CEP_NOT_SAME_CITY = 'O novo CEP deve estar na mesma cidade',
  MSG_CEP_NOT_SAME_CITY = 'Para manter o mesmo valor e a mesma abrangência no seu plano, é preciso que o novo endereço seja na mesma cidade do CEP anterior.',
}

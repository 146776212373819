import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIIcon,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { AddressActions } from '../../../../store/ducks/address/actions'
import { H3, PSmall } from '../../../../styles/commons'
import { TypesPendingRoutes as Routes } from '../../routes'

export const EditAddress: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { colors } = useTheme()

  const address = useSelector(AddressActions.getSelectedAddress)

  const goToUpdateAddress = () => {
    dispatch(AddressActions.setSearchedAddress(null))

    history.push(Routes.UPDATE_ADDRESS)
  }

  const iconLeft = <InterUIIcon name="home" size="md" color={colors.neutral.grayscale.A500} />

  const iconRight = (
    <InterUIIcon
      className="icon"
      name="arrow-chevron-right"
      size="md"
      color={colors.primary.A500}
    />
  )

  return (
    <>
      <InterUIContainer>
        <H3>Edite o endereço, se for preciso:</H3>

        <InterUIListDescription
          role="link"
          margin="24px 0"
          iconLeft={iconLeft}
          iconRight={iconRight}
          onClick={goToUpdateAddress}
        >
          <PSmall bold marginBottom="2px">
            {address?.address}
          </PSmall>

          <PSmall scale={400} marginBottom="0">
            {`${address?.neighborhood}, ${address?.city} - ${address?.district}, Brasil`}
          </PSmall>
        </InterUIListDescription>
      </InterUIContainer>

      <InterUISeparator />
    </>
  )
}

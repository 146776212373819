import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../../utils/providerSaga'
import {
  BindingStepData,
  DocumentationStepData,
  DPSStepData,
  PersistedHiringTypes,
  SimulationStepData,
} from './types'

const getAllState = (state: RootState) => state.persistedHiring

const clearAllState = () => action(PersistedHiringTypes.CLEAR_ALL)

const setTimestamp = (data: number) => action(PersistedHiringTypes.SET_TIMESTAMP, data)

const getTimestamp = (state: RootState) => state.persistedHiring.timestamp

const setFirstPage = (data: string) => action(PersistedHiringTypes.SET_FIRST_PAGE, data)

const getFirstPage = (state: RootState) => state.persistedHiring.firstPage

const setSimulationStateRequest = (data?: SagaWithHistory) =>
  action(PersistedHiringTypes.SET_SIMULATION_STATE_REQUEST, data)

const setSimulationState = (data: SimulationStepData) =>
  action(PersistedHiringTypes.SET_SIMULATION_STATE, data)

const getSimulationState = (state: RootState) => state.persistedHiring.simulation

const setBindingStateRequest = (data?: SagaWithHistory) =>
  action(PersistedHiringTypes.SET_BINDING_STATE_REQUEST, data)

const setBindingState = (data: BindingStepData) =>
  action(PersistedHiringTypes.SET_BINDING_STATE, data)

const getBindingState = (state: RootState) => state.persistedHiring.binding

const setDPSStateRequest = (data?: SagaWithHistory) =>
  action(PersistedHiringTypes.SET_DPS_STATE_REQUEST, data)

const setDPSState = (data: DPSStepData) => action(PersistedHiringTypes.SET_DPS_STATE, data)

const getDPSState = (state: RootState) => state.persistedHiring.dps

const setDocumentationStateRequest = (data?: SagaWithHistory) =>
  action(PersistedHiringTypes.SET_DOC_STATE_REQUEST, data)

const setDocumentationState = (data: DocumentationStepData) =>
  action(PersistedHiringTypes.SET_DOC_STATE, data)

const getDocumentationState = (state: RootState) => state.persistedHiring.documentation

const validatePersistence = (data?: SagaWithHistory) =>
  action(PersistedHiringTypes.VALIDATE_PERSISTENCE, data)

const loadStoreWithData = (data?: SagaWithHistory) =>
  action(PersistedHiringTypes.LOAD_STORE_WITH_DATA, data)

export const PersistedHiringActions = {
  getAllState,
  clearAllState,
  setTimestamp,
  getTimestamp,
  setFirstPage,
  getFirstPage,
  setSimulationStateRequest,
  setSimulationState,
  getSimulationState,
  setBindingStateRequest,
  setBindingState,
  getBindingState,
  setDPSStateRequest,
  setDPSState,
  getDPSState,
  setDocumentationStateRequest,
  setDocumentationState,
  getDocumentationState,
  validatePersistence,
  loadStoreWithData,
}

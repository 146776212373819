import React from 'react'

import debounce from 'lodash.debounce'
import pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry'
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist/types/display/api'

import { PdfContainer } from './InterUIPdfReader.styles'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

/**
 * Componente Inter UI PdfReader.
 * @param props Propriedades disponíveis para definição do layout.
 */

/**
 * Props disponíveis para o IInterUIPdfReaderProps.
 */ interface IInterUIPdfReaderProps {
  /**
   * Arquivo PDF.
   */
  file: File | string
  /**
   * Largura da página do PDF.
   */
  width?: string
  /**
   * Altura da página do PDF.
   */
  height?: string
  /**
   * Mensagem do Loading.
   */
  loadingMessage?: string
}

export const InterUIPdfReader: React.FC<
  IInterUIPdfReaderProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ file, width = '100%', height = '100%', loadingMessage }) => {
  const [numberPages, setNumberPages] = React.useState<number>()
  const [pageWidth, setPageWidth] = React.useState<number>()
  const pdfWrapperRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setDivSize()
    const setDivDebounce = debounce(setDivSize, 100)
    window.addEventListener('resize', setDivDebounce)
    return () => window.removeEventListener('resize', setDivDebounce)
  }, [])

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumberPages(numPages)
  }

  const setDivSize = () => setPageWidth(pdfWrapperRef.current?.getBoundingClientRect().width)

  return (
    <PdfContainer width={width} height={height} ref={pdfWrapperRef} data-testid="pdf-container">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={loadingMessage}>
        {Array.from(new Array(numberPages), (_, index) => (
          <Page width={pageWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </PdfContainer>
  )
}

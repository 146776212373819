import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIContainer, InterUIRadio } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { P, PMedium, PSmall } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const EffectiveDate: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [effectiveDate, setEffectiveDate] = useState('')

  const availableEffectiveDates = useSelector(HealthPlanActions.getAvailableEffectiveDates)

  useBasePage({
    navbarTitle: PageTitles.PAYMENT,
    backNavigationHandler: history.goBack,
  })

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Pag_T_DefinirData')
    dispatch(
      ProposalActions.updateProposalRequest({
        history,
        goBack: true,
        effectiveDate,
      }),
    )
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!effectiveDate}>
      Definir data
    </Button>
  )

  const renderAvailableDates = availableEffectiveDates.map((item, index) => {
    const key = `date-${index}`

    return (
      <InterUIRadio
        variant="choose-item"
        radioPosition="right"
        id={key}
        key={key}
        name="bond"
        value={item}
        onChange={(e) => setEffectiveDate(e.target.value)}
        checked={effectiveDate === item}
      >
        <PMedium bold marginBottom="0">
          {item}
        </PMedium>
      </InterUIRadio>
    )
  })

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="40px 24px 0">
        <P bold marginBottom="9px">
          Data de início do plano
        </P>

        <PSmall scale={400} marginBottom="38px">
          Escolha a melhor data de vigência, quando o seu plano começará a valer. A cada mês, os
          débitos serão realizados nesse mesmo dia.
        </PSmall>

        {renderAvailableDates}
      </InterUIContainer>
    </InterUIContainer>
  )
}

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { InterUIAccordion, InterUIBottomSheet } from '@interco/inter-ui-react-lib'

import { IHealthPlanDetails } from '../../store/ducks/healthPlan/types'
import { BtnLink, P } from '../../styles/commons'
import { Content } from './PlanDetailsBS.styles'

interface PlanDetailsBSProps {
  componentName: string
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
  details: IHealthPlanDetails
}

const Coparticipation: React.FC<{ details: IHealthPlanDetails }> = ({ details }) => {
  const last = (details.participationRules?.length || 0) - 1

  return (
    <>
      {details.participationRules?.map((item, index) => {
        const key = `participation-${index}`

        return (
          <InterUIAccordion
            key={key}
            id={key}
            header={item.procedure}
            border={index === last ? 'none' : 'bottom'}
          >
            {item.princingListUrl && (
              <Content>
                <P scale={300} marginBottom="0">
                  Tabela
                </P>
                <BtnLink>Baixar tabela</BtnLink>
              </Content>
            )}

            <Content>
              <P scale={300} marginBottom="0">
                Valor
              </P>
              <P scale={400} bold marginBottom="0">
                {item.value}
              </P>
            </Content>
          </InterUIAccordion>
        )
      })}
    </>
  )
}

const References: React.FC<{ details: IHealthPlanDetails }> = ({ details }) => {
  const last = (details.referenceNetwork?.length || 0) - 1

  return (
    <>
      {details.referenceNetwork?.map((item, index) => {
        const key = `reference-${index}`

        return (
          <Content key={key} padding="15px 0" borderBottom={index !== last}>
            <P bold marginBottom="0">
              {item}
            </P>
          </Content>
        )
      })}
    </>
  )
}

const Reimbursable: React.FC<{ details: IHealthPlanDetails }> = ({ details }) => {
  const last = (details.reimbursementRules?.length || 0) - 1

  return (
    <>
      {details.reimbursementRules?.map((item, index) => {
        const key = `reimbursable-${index}`

        return (
          <InterUIAccordion
            key={key}
            id={key}
            header={item.procedure}
            border={index === last ? 'none' : 'bottom'}
          >
            <Content>
              <P scale={300} marginBottom="0">
                Valor
              </P>
              <P scale={400} bold marginBottom="0">
                {item.value}
              </P>
            </Content>
            <Content padding="16px 0 16px">
              <P scale={300} marginBottom="0">
                Valor limite
              </P>
              <P scale={400} bold marginBottom="0">
                {item.limitValue || '-'}
              </P>
            </Content>
            <Content>
              <P scale={300} marginBottom="0">
                Limite mensal
              </P>
              <P scale={400} bold marginBottom="0">
                {item.monthlyLimitValue || '-'}
              </P>
            </Content>
          </InterUIAccordion>
        )
      })}
    </>
  )
}

const WaitingPeriod: React.FC<{ details: IHealthPlanDetails }> = ({ details }) => {
  const last = (details.waitingPeriodRules?.length || 0) - 1

  return (
    <>
      {details.waitingPeriodRules?.map((item, index) => {
        const key = `waitingPeriod-${index}`

        return (
          <InterUIAccordion
            key={key}
            id={key}
            header={item.name}
            border={index === last ? 'none' : 'bottom'}
          >
            <Content padding="8px 0 0">
              <P scale={300} marginBottom="0">
                Tempo a ser aguardado
              </P>
              <P scale={400} bold marginBottom="0">
                {item.deadline} {item.type}
              </P>
            </Content>
          </InterUIAccordion>
        )
      })}
    </>
  )
}

export const PlanDetailsBS: React.FC<PlanDetailsBSProps> = ({
  componentName,
  toggleState,
  details,
}) => {
  const [toggle, setToggle] = toggleState
  const [header, setHeader] = useState<string>('')
  const [component, setComponent] = useState<JSX.Element>(<></>)

  useEffect(() => {
    switch (componentName) {
      case 'participation':
        setHeader('Coparticipação')

        setComponent(<Coparticipation details={details} />)
        break
      case 'references':
        setHeader('Hospitais')

        setComponent(<References details={details} />)
        break
      case 'reimbursable':
        setHeader('Reembolso')

        setComponent(<Reimbursable details={details} />)
        break
      case 'waitingPeriod':
        setHeader('Prazos de carência')

        setComponent(<WaitingPeriod details={details} />)
        break
      default:
        setHeader('')

        setComponent(<></>)
        break
    }
  }, [componentName])

  return (
    <InterUIBottomSheet title={header} toggle={toggle} onHide={() => setToggle(false)}>
      {component}
    </InterUIBottomSheet>
  )
}

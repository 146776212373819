import { SagaWithHistory } from '../../utils/providerSaga'
import { IEntity } from '../classEntity/types'

export enum HealthPlanTypes {
  GET_LIST_HEALTH_PLANS = `@health_plan/GET_LIST_HEALTH_PLANS`,
  SET_LIST_HEALTH_PLANS = `@health_plan/SET_LIST_HEALTH_PLANS`,
  SET_SELECTED_HEALTH_PLAN = `@health_plan/SET_SELECTED_PLAN`,
  GET_HEALTH_PLAN_DETAILS = `@health_plan/GET_HEALTH_PLAN_DETAILS`,
  SET_HEALTH_PLAN_DETAILS = `@health_plan/SET_HEALTH_PLAN_DETAILS`,
  SET_PROVIDERS_FILTER = `@health_plan/SET_PROVIDERS_FILTER`,
  SET_HEALTH_FILTERED_OPTIONS = `@health_plan/SET_HEALTH_FILTERED_OPTIONS`,
  GET_AVAILABLE_EFFECTIVE_DATES = `@health_plan/GET_AVAILABLE_EFFECTIVE_DATES`,
  SET_AVAILABLE_EFFECTIVE_DATES = `@health_plan/SET_AVAILABLE_EFFECTIVE_DATES`,
  GET_PLAN_MEMBERSHIP_FEE = `@health_plan/GET_PLAN_MEMBERSHIP_FEE`,
  CLEAR_ALL = `@health_plan/CLEAR_ALL`,
}

export interface GetHealthPlansRequest {
  accommodation?: string
  participation?: string | string[]
  association?: IEntity
  coverage?: string
  reimbursable?: string | boolean
  providers?: string | string[]
  city: string
  district: string
  page?: number
  occupation?: string
}
export interface IHealthPlansFilter extends SagaWithHistory {
  accommodation?: string
  participation?: string
  coverage?: string
  reimbursable?: string
  providers?: string | string[]
  page?: number
  countFiltered?: number

  onFiltered?: () => void
  onError?: () => void
}

export interface IHealthPlan {
  id: number
  ansCode: number
  displayName: string
  description: string
  accommodation: string
  provider: string
  logo: string
  price: string
  reimbursable: boolean
  participation: boolean
  coverage: string
  badges: string[]
  regulatoryNumber: number
  cashback?: number

  changedValue?: boolean
  readjustmentLetter?: string
}

export interface HealthPlansResponse {
  providers: string[]
  plans: IHealthPlan[]
}

export interface IHealthPlanDetails {
  benefits: string[]
  coverages: string[]
  referenceNetwork: string[]
  reimbursementRules: {
    limitValue: string
    monthlyLimitValue: string
    procedure: string
    value: string
  }[]
  waitingPeriodRules: {
    deadline: string
    name: string
    type: string
  }[]
  participationRules: {
    princingListUrl: string
    procedure: string
    value: string
  }[]
}

export interface IAvailableEffectiveDatesResponse {
  availableEffectiveDates?: string[]
}

export interface PlanMembershipFeeRequest extends SagaWithHistory {
  planId?: number
}

export interface PlanMembershipFeeResponse {
  association: IEntity
}

export interface IHealthPlanInfo extends SagaWithHistory {
  planId: number
}

export interface HealthPlanState {
  readonly healthPlanList: IHealthPlan[]
  readonly providersFilter?: string[]
  readonly healthPlanFilteredOptions?: IHealthPlansFilter
  readonly selectedHealthPlan?: IHealthPlan
  readonly healthPlanDetails?: IHealthPlanDetails
  readonly availableEffectiveDates: string[]
}

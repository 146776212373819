import React from 'react'

import { ContinueHiringRoutes } from './persistedHiring/routes'
import { SimulationRoutes } from './simulation/routes'
import { BindingRoutes } from './binding/routes'
import { DocumentationRoutes } from './documentation/routes'
import { PaymentRoutes } from './payment/routes'
import { PendingRoutes } from './pending/routes'

export const MixedRoutes: React.FC = () => (
  <>
    <ContinueHiringRoutes />
    <SimulationRoutes />
    <BindingRoutes />
    <DocumentationRoutes />
    <PaymentRoutes />
    <PendingRoutes />
  </>
)

import React, { useEffect } from 'react'

import { InterUIBottomSheet, InterUIButton } from '@interco/inter-ui-react-lib'

import { IPending } from '../../../../store/ducks/pending/types'
import { PMedium } from '../../../../styles/commons'
import BaseBridge from '../../../../config/bridge/BaseBridge'

interface IViewPendingIssuesBSProps {
  pendencies: IPending[]
  toggle: boolean
  setToggle: React.Dispatch<React.SetStateAction<boolean>>
  planName: string
}

export const ViewPendingIssuesBS: React.FC<IViewPendingIssuesBSProps> = ({
  pendencies,
  toggle,
  setToggle,
  planName,
}) => {
  useEffect(() => {
    BaseBridge.requestAnalytics('C_PlanoSaude_PendeciasNoMeuPedido_DC', {
      content_name: planName,
    })
  }, [])

  const renderPending = pendencies?.map((pendingType, i) => {
    const keyType = `${pendingType.type.toLowerCase()}-${i}`

    return (
      <div id={keyType} key={keyType}>
        <PMedium bold marginBottom="0">
          {pendingType.type}
        </PMedium>

        {pendingType.pending.map((pendingDesc, j) => {
          const keyDescription = `${keyType}-${j}`

          return (
            <PMedium key={keyDescription} scale={400} marginBottom="16px">
              {pendingDesc}
            </PMedium>
          )
        })}
      </div>
    )
  })

  return (
    <InterUIBottomSheet
      title="Pendências no meu pedido"
      toggle={toggle}
      onHide={() => setToggle(false)}
      id="order-pendencies"
      aria-labelledby="order-pendencies"
    >
      {renderPending}

      <InterUIButton type="button" onClick={() => setToggle(false)} margin="32px 0px 0px">
        Entendi
      </InterUIButton>
    </InterUIBottomSheet>
  )
}

import React from 'react'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import BaseBridge from '../../../../config/bridge/BaseBridge'
import { useBasePage } from '../../../../hooks/useBasePage'
import { Button } from '../../../../components'
import { H1, Hero, PMedium } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import Personas from '../../../../assets/img/personas-success.png'

export const ShutDownDpsOffline: React.FC = () => {
  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    backNavigationHandler: BaseBridge.requestGoBack,
    hideHomeBtn: true,
  })

  const stickyFooter = (
    <Button margin="0" onClick={BaseBridge.requestGoBack}>
      Voltar para home de Seguros
    </Button>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Hero>
        <img src={Personas} alt="Personas" />
      </Hero>

      <H1 marginBottom="8px" textAlign="center">
        Tudo certo
      </H1>

      <PMedium scale={400} textAlign="center">
        Agora é com você: ligue para a Qualicorp, no (11) 4004-4400 para capitais e regiões
        metropolitana ou 0800 016 2000 para as demais regiões, e fale com um dos corretores, que te
        acompanhará na contratação do seu plano de saúde.
      </PMedium>
    </InterUIContainer>
  )
}

import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  InterUIIcon,
  InterUIBottomSheet,
  InterUIListDescription,
} from '@interco/inter-ui-react-lib'

import BaseBridge from '../../../../config/bridge/BaseBridge'
import { Button } from '../../../../components'
import { UserActions } from '../../../../store/ducks/user/actions'
import { PersistedHiringActions } from '../../../../store/ducks/persistedHiring/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { useBasePage } from '../../../../hooks/useBasePage'
import { Hero, HomeSection, TextContent } from './Home.styles'
import { PMedium } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { HomeMessages } from '../../../../enums/HomeMessages'
import { ProposalStatus } from '../../../../enums/ProposalStatus'
import { TypesSimulationRoutes as Routes } from '../../routes'
import { TypesContinueHiringRoutes as HiringRoutes } from '../../../persistedHiring/routes'
import { TypesPendingRoutes as PendingRoutes } from '../../../pending/routes'
import HeroImg from '../../../../assets/img/hero-home.png'

export const Home: React.FC = () => {
  const [bottomSheet, setBottomSheet] = useState<boolean>(false)
  const [titleBottomSheet, setTitleBottomSheet] = useState<string>('')
  const [messageBottomSheet, setMessageBottomSheet] = useState<string>('')

  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const user = useSelector(UserActions.getUserDetails)
  const persistedData = useSelector(PersistedHiringActions.getTimestamp)
  const proposal = useSelector(ProposalActions.getProposal)

  const [buttonLabel, setButtonLabel] = useState('')

  useBasePage({
    navbarTitle: PageTitles.HEALTH_PLAN,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  useEffect(() => {
    dispatch(PersistedHiringActions.validatePersistence())
  }, [])

  useEffect(() => {
    if (contractedPlan()) {
      setButtonLabel('Ver plano contratado')
    } else if (user && persistedData) {
      setButtonLabel('Continuar simulação')
    } else {
      setButtonLabel('Fazer simulação')
    }
  }, [user, persistedData, proposal])

  const contractedPlan = () =>
    proposal?.status &&
    proposal?.status?.toUpperCase() !== ProposalStatus.COTACAO &&
    proposal?.status?.toUpperCase() !== ProposalStatus.CANCELADO

  const doToSimulation = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Home_T_FazerSimulacao')

    if (contractedPlan()) {
      history.push(PendingRoutes.PENDING)
    } else if (user.activeInsurance) {
      setTitleBottomSheet(HomeMessages.TITLE_ERROR_ACTIVE_PLAN)
      setMessageBottomSheet(HomeMessages.MSG_ERROR_ACTIVE_PLAN)
      setBottomSheet(true)
    } else if (user.child) {
      setTitleBottomSheet(HomeMessages.TITLE_ERROR_AGE)
      setMessageBottomSheet(HomeMessages.MSG_ERROR_AGE)
      setBottomSheet(true)
    } else if (persistedData) {
      history.push(HiringRoutes.CONTINUE_HIRING)
    } else {
      history.push(Routes.SELECT_ADDRESS)
    }
  }

  const iconCredit = <InterUIIcon name="credit" size="md" />
  const iconHealth = <InterUIIcon name="health" size="md" />
  const iconPhone = <InterUIIcon name="smartphone" size="md" />

  return (
    <>
      <HomeSection>
        <Hero>
          <img src={HeroImg} alt="Homem afro feliz, contratando um seguro" />
        </Hero>

        <TextContent>
          <h1>Mais que um plano, o parceiro da sua saúde</h1>

          <InterUIListDescription margin="24px 0" iconLeft={iconCredit} role="group">
            <PMedium margin="0 0 8px 0" bold>
              Você contrata direto pelo app
            </PMedium>
            <PMedium scale={400}>
              Aqui no Inter, você garante o melhor pra sua saúde em apenas 5 minutos.
            </PMedium>
          </InterUIListDescription>

          <InterUIListDescription margin="24px 0 16px" iconLeft={iconPhone} role="group">
            <PMedium margin="0 0 8px 0" bold>
              Os melhores planos do Brasil
            </PMedium>
            <PMedium scale={400}>
              Apresentamos várias opções de planos das principais operadoras do país, de acordo com
              seus dados de cadastro e outras informações que fornecer. Você quem escolhe o plano
              que preferir.
            </PMedium>
          </InterUIListDescription>

          <InterUIListDescription margin="24px 0" iconLeft={iconHealth} role="group">
            <PMedium margin="0 0 8px 0" bold>
              Sua saúde em dia e seu bolso também
            </PMedium>
            <PMedium scale={400}>
              Os planos são coletivos, com valores mais baixos. Simule e contrate sem burocracia. É
              aqui pelo app mesmo, e a gente te explica o passo a passo.
            </PMedium>
          </InterUIListDescription>
        </TextContent>
      </HomeSection>

      <Button margin="24px" alignContent="space-between" onClick={doToSimulation}>
        <p>{buttonLabel}</p>

        <InterUIIcon name="arrow-chevron-right" size="md" color={theme.colors.base.white} />
      </Button>

      <InterUIBottomSheet
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
        title={titleBottomSheet}
      >
        <PMedium scale={400}>{messageBottomSheet}</PMedium>
        <Button margin="20px 0" onClick={() => BaseBridge.openDeepLink('bancointer://seguros')}>
          {user.activeInsurance ? 'Entendi' : 'Ver outros seguros'}
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

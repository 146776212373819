import BaseService from '../config/bridge/BaseService'
import { IAddress } from '../store/ducks/address/types'
import {
  EntitiesResponse,
  IClassEntityResponse,
  IEntity,
  OccupationsResponse,
} from '../store/ducks/classEntity/types'

export default class ClassEntityService {
  static async getListOccupations(address: IAddress) {
    const district = encodeURIComponent(address?.district)
    const city = encodeURIComponent(address?.city)

    const queryString = `?district=${district}&city=${city}`

    return BaseService.doExecute<OccupationsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/profissoes${queryString}`,
    })
  }

  static async getListEntities(address: IAddress, occupation: string, digitalMembership = false) {
    const districtURI = encodeURIComponent(address?.district)
    const cityURI = encodeURIComponent(address?.city)
    const occupationURI = encodeURIComponent(occupation)

    const queryString = `?occupation=${occupationURI}&district=${districtURI}&city=${cityURI}`

    const headers = {
      'digital-membership-filter': String(digitalMembership),
    }

    try {
      return await BaseService.doExecute<EntitiesResponse>({
        method: 'GET',
        endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/entidades${queryString}`,
        headers,
      })
    } catch (error) {
      if (
        (error as { status: number }).status === 404 ||
        (error as { status: number }).status === 400
      ) {
        return { data: { associations: [] as IEntity[] } }
      }
      return Promise.reject(error)
    }
  }

  static async getClassEntityByPlanId(planId: number) {
    try {
      return await BaseService.doExecute<IClassEntityResponse>({
        method: 'GET',
        endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/planos/${planId}/taxa`,
      })
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        return { data: { association: {} as IEntity } }
      }
      return Promise.reject(error)
    }
  }
}

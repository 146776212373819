import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUIRadio, InterUISeparator } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { PageTitles } from '../../../../enums/pageTitles'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { P, PMedium } from '../../../../styles/commons'
import { numberFormat } from '../../../../utils/commons'
import { TotalTag } from '../CloseOrder/CloseOrder.styles'
import { CashbackMessage, PSpecial } from './FormPayment.styles'

export const FormPayment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const theme = useTheme().colors

  const paymentPropose = useSelector(ProposalActions.getProposal)
  const formPaymentInitial = useSelector(ProposalActions.getFormPayment)

  const [formPayment, setFormPayment] = useState(formPaymentInitial)

  const [hasCashback] = useState(false)

  const formatedValuePerMonth = numberFormat(+paymentPropose.plan.price)

  useBasePage({
    navbarTitle: PageTitles.PAYMENT,
    backNavigationHandler: history.goBack,
  })

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Pag_T_DefinirFormaPagamento', {
      content_id: paymentPropose.plan.displayName,
      content_name: 'account-debit',
    })
    dispatch(ProposalActions.setFormPayment(formPayment))

    history.goBack()
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!formPayment}>
      Definir forma de pagamento
    </Button>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="24px 24px 0">
        <TotalTag margin="0 0 8px 0">
          <P scale={400}> Total </P>
          <h1>{formatedValuePerMonth} por mês</h1>

          {hasCashback ? (
            <InterUIContainer margin="8px 0 0 0" color={theme.primary.A500}>
              <CashbackMessage>Você ganhará R$ 15,00 de cashback</CashbackMessage>
            </InterUIContainer>
          ) : null}
        </TotalTag>
        <InterUISeparator variant="small" />

        <InterUIContainer margin="27px 0 16px 0">
          <PSpecial>Forma de pagamento:</PSpecial>
        </InterUIContainer>

        <InterUIRadio
          variant="choose-item"
          id="account-debit"
          name="bond"
          value="account-debit"
          onChange={(e) => setFormPayment(e.target.value)}
          checked={formPayment === 'account-debit'}
        >
          <PMedium bold>Débito automático em conta</PMedium>
        </InterUIRadio>
      </InterUIContainer>
    </InterUIContainer>
  )
}

export enum FieldTypes {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  ENUM = 'enum',
  CHECKBOX = 'checkbox',
}

export enum QuestionGroups {
  PERSONAL_DATA = 'pessoais',
  ACADEMIC_FORMATION = 'formacao',
  PROFESSIONAL_DATA = 'profissionais',
}

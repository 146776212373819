import React from 'react'

import { UseFormRegister, RegisterOptions, FieldValues } from 'react-hook-form'

import {
  IInterUIInputGroupProps,
  InterUIInputGroup,
  InterUIRadio,
} from '@interco/inter-ui-react-lib'

import { PSmall } from '../../styles/commons'

interface InterUIInput extends IInterUIInputGroupProps {
  layout?: 'default' | 'textarea' | 'radio'
  border?: 'none'
  label?: string

  register?: UseFormRegister<FieldValues>

  validation?: RegisterOptions
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  InterUIInput

export const InputWithHook: React.FC<Omit<InputProps, 'variant'>> = ({
  layout,
  margin,
  label,
  name,
  register = (_data: unknown) => undefined,
  validation,
  ...props
}) =>
  layout === 'radio' ? (
    <InterUIRadio {...register(name || '', { ...validation })} {...props} margin={margin}>
      <PSmall marginBottom="0">{label}</PSmall>
    </InterUIRadio>
  ) : (
    <InterUIInputGroup margin={margin} disabled={props.disabled}>
      <label htmlFor={props.id}>{label}</label>

      {layout === 'textarea' ? (
        <textarea {...register(name || '', { ...validation })} {...props} />
      ) : (
        <input {...register(name || '', { ...validation })} {...props} />
      )}
    </InterUIInputGroup>
  )

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  IInterUIDropdownOption as DropdownOption,
  InterUIBottomSheet,
  InterUIContainer,
  InterUIDropdown,
  InterUIProgressBar,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PMedium, PSmall, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes as Routes } from '../../routes'
import { isNoneOfThese } from '../../../../utils/commons'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const SelectClassEntity: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const hasBond = useSelector(ClassEntityActions.getSelectedBond)
  const occupations = useSelector(ClassEntityActions.getListOccupations)
  const selectedOccupation = useSelector(ClassEntityActions.getSelectedOccupation)
  const entities = useSelector(ClassEntityActions.getListEntities)
  const selectedEntity = useSelector(ClassEntityActions.getSelectedClassEntity)

  const [entityOptions, setEntityOptions] = useState<DropdownOption[]>([])
  const [occupation, setOccupation] = useState<string>()
  const [entity, setEntity] = useState<string>()
  const [unhedgedBottomSheet, setUnhedgedBottomSheet] = useState<boolean>(false)

  const occupationOpts = occupations.map((item, index) => ({
    label: item.name,
    value: index.toString(),
  }))

  useBasePage({
    navbarTitle: PageTitles.CLASS_ENTITY,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    if (selectedOccupation) {
      const occupationIndex = occupations.findIndex((item) => item.name === selectedOccupation.name)

      if (occupationIndex !== -1) {
        setOccupation(occupationIndex.toString())
      }
    }

    if (selectedOccupation && selectedEntity && hasBond === 'S') {
      const entityIndex = entities.findIndex((item) => item === selectedEntity)

      if (entityIndex !== -1) {
        setEntity(entityIndex.toString())
      }
    }
  }, [])

  useEffect(() => {
    const options = entities.map((item, index) => ({
      label: item.name,
      value: index.toString(),
    }))

    setEntityOptions(options)
  }, [entities])

  const onFilter = (search: string, item: DropdownOption) => {
    if (isNoneOfThese(item.label)) {
      return true
    }
    return RemoveAccents(item.label).includes(search)
  }

  const onChangeOccupation = (value?: string | number) => {
    if (value) {
      const selected = occupations[+value]

      setOccupation(value.toString())
      setEntityOptions([])
      setEntity(undefined)

      dispatch(ClassEntityActions.setSelectedOccupation(selected))

      if (hasBond === 'S' && !isNoneOfThese(selected?.name)) {
        dispatch(ClassEntityActions.getListEntitiesRequest())
      }
    }
  }

  const btnDisabled = () =>
    (!occupation && !entity) ||
    (hasBond === 'S' && occupation && !isNoneOfThese(occupations[+occupation]?.name) && !entity) ||
    (hasBond === 'N' && !occupation)

  const showUnhedgedBottomSheet = () => setUnhedgedBottomSheet(true)

  const onContinue = () => {
    const selectEntity = entity ? entities[+entity] : null

    dispatch(ClassEntityActions.setSelectedClassEntity(selectEntity))

    if (isNoneOfThese(selectedOccupation?.name) || isNoneOfThese(selectEntity?.name)) {
      history.push(Routes.WITHOUT_COVER)
    } else if (hasBond === 'S') {
      BaseBridge.requestAnalytics('PlanoSaude_EntidaClasse_T_ContCompleta')
      dispatch(HealthPlanActions.clearAllHealthPlanState())
      dispatch(
        HealthPlanActions.getAllHealthPlansRequest({
          history,
          pathname: Routes.HEALTH_PLAN_LIST,
          onError: showUnhedgedBottomSheet,
        }),
      )
    } else if (hasBond === 'N') {
      BaseBridge.requestAnalytics('C_PlanoSaude_EntidaClasse_T_Categoria')
      dispatch(
        ClassEntityActions.getListEntitiesRequest({
          digitalMembership: true,
          history,
          pathname: Routes.BIND_TO_ENTITY,
        }),
      )
    }
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={btnDisabled()}>
      Continuar
    </Button>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="70%" aria-valuenow={2} aria-valuemin={1} aria-valuemax={5} />

        <InterUIContainer>
          <PSmall scale={300} bold>
            Passo 2/3
          </PSmall>

          {hasBond === 'S' ? (
            <StepHeading>
              Selecione sua categoria profissional e a entidade de classe à qual você está
              vinculado.
            </StepHeading>
          ) : (
            <StepHeading>Selecione sua categoria profissional.</StepHeading>
          )}

          <InterUIDropdown
            margin="0 0 16px"
            name="occupation"
            label="Categoria profissional"
            placeholder="Selecione uma categoria"
            options={occupationOpts}
            value={occupation}
            onChange={onChangeOccupation}
            onFilter={onFilter}
          />

          {hasBond === 'S' && (
            <InterUIDropdown
              name="entity"
              label="Entidade de classe"
              placeholder="Selecione a entidade"
              options={entityOptions}
              truncateOption={false}
              value={entity}
              onChange={(value) => setEntity(value?.toString())}
              onFilter={onFilter}
              disabled={!occupation || !!isNoneOfThese(occupations[+occupation]?.name)}
            />
          )}
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        toggle={unhedgedBottomSheet}
        onHide={() => setUnhedgedBottomSheet(false)}
        title="Entidade sem cobertura no endereço"
        data-testid="unhedged-BS"
      >
        <PMedium margin="0 0 12px 0" scale={400}>
          No momento não há cobertura disponível nesse endereço para a entidade selecionada. Tente
          escolher outra entidade.
        </PMedium>
        <Button margin="20px 0" onClick={() => setUnhedgedBottomSheet(false)}>
          Entendi
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

import styled from 'styled-components'

export const CashbackMessage = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.feedback.success.A500};
`

export const PSpecial = styled.div`
  font-family: 'Sora', 'Lato', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
`

import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import BaseBridge from '../config/bridge/BaseBridge'
import { NavbarActions } from '../store/ducks/navbar/actions'
import { PageTitles } from '../enums/pageTitles'

interface BasePage {
  navbarTitle: PageTitles
  backNavigationHandler: () => void
  hideHomeBtn?: boolean
}

export const useBasePage = (data: BasePage) => {
  const dispatch = useDispatch()

  const { navbarTitle, backNavigationHandler } = data

  useEffect(() => {
    dispatch(NavbarActions.setTitle(navbarTitle))

    if (data.hideHomeBtn) {
      dispatch(NavbarActions.hideHomeBtn())
    } else {
      dispatch(NavbarActions.showHomeBtn())
    }

    dispatch(NavbarActions.setBackNavigationHandler(backNavigationHandler))

    BaseBridge.addBackListener(backNavigationHandler)
  }, [])
}

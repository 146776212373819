import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIContainer, InterUIProgressBar, InterUIRadio } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PMedium, StepHeading, PSmall } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesDocumentationRoutes as Routes } from '../../routes/types'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const InformHistoric: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const selectedBond = useSelector(DPSActions.hasPreviousPlan)

  const quizStep = useSelector(DPSActions.getQuizStep)

  const [radioBond, setRadioBond] = useState('')

  useBasePage({
    navbarTitle: PageTitles.HISTORIC,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    let radioBondValue

    if (selectedBond === undefined) {
      radioBondValue = ''
    } else {
      radioBondValue = selectedBond ? 'S' : 'N'
    }

    setRadioBond(radioBondValue)
  }, [selectedBond])

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_Historico_T_Botao', {
      content_name: 'Continuar',
    })

    if (radioBond) {
      const hasPreviousPlan = radioBond === 'S'
      dispatch(DPSActions.setHasPreviousPlan(hasPreviousPlan))
      dispatch(
        ProposalActions.updateProposalRequest({
          history,
          pathname: Routes.HEIGHT_WEIGHT,
          hasPreviousPlan,
        }),
      )
    }
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!radioBond}>
      Continuar
    </Button>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar
        progress={`${quizStep?.amountProgress}%`}
        aria-valuenow={1}
        aria-valuemin={1}
        aria-valuemax={quizStep?.length + 2}
      />

      <InterUIContainer margin="63px 24px 24px">
        <StepHeading>Você tem plano de saúde atualmente ou teve nos últimos 60 dias?</StepHeading>

        <InterUIRadio
          variant="choose-item"
          id="bond-s"
          name="bond"
          value="S"
          onChange={(e) => setRadioBond(e.target.value)}
          checked={radioBond === 'S'}
        >
          <PMedium bold marginBottom="4px">
            Sim
          </PMedium>
          <PSmall scale={400}>
            Nesse caso, você poderá enviar a<strong> Carta de tempo de permanência </strong>
            do seu antigo plano e ter alguns prazos de carência reduzidos. Você pode pedir essa
            carta à operadora do plano.
          </PSmall>
        </InterUIRadio>

        <InterUIRadio
          variant="choose-item"
          id="bond-n"
          name="bond"
          value="N"
          onChange={(e) => setRadioBond(e.target.value)}
          checked={radioBond === 'N'}
        >
          <PMedium bold marginBottom="4px">
            Não
          </PMedium>
          <PSmall scale={400}>
            Nesse caso, os prazos de carência serão mantidos, sem alterações.
          </PSmall>
        </InterUIRadio>
      </InterUIContainer>
    </InterUIContainer>
  )
}

import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { BindingTypes, IBindingDoc, IBondField, IBondFieldGroup } from './types'
import { SagaWithHistory } from '../../utils/providerSaga'
import { QuestionGroups } from '../../../enums/DynamicFieldConfig'

const getDocumentsListRequest = (data?: SagaWithHistory) =>
  action(BindingTypes.GET_DOCUMENTS_LIST, data)

const getDocumentsList = (state: RootState) => state.binding.documentsList

const setDocumentsList = (data: IBindingDoc[]) => action(BindingTypes.SET_DOCUMENTS_LIST, data)

const getDynamicQuestionsRequest = (data?: SagaWithHistory) =>
  action(BindingTypes.GET_DYNAMIC_QUESTIONS, data)

const getDynamicQuestions = (state: RootState) => state.binding.dynamicQuestions

const setDynamicQuestions = (data: IBondFieldGroup[]) =>
  action(BindingTypes.SET_DYNAMIC_QUESTIONS, data)

const getCurrentGroupQuestions = (state: RootState) => state.binding.currentGroupQuestions

const setCurrentGroupQuestions = (data: QuestionGroups) =>
  action(BindingTypes.SET_CURRENT_GROUP_QUESTIONS, data)

const setQuestionsProfessional = (data: IBondFieldGroup) =>
  action(BindingTypes.SET_QUESTIONS_OCCUPATION, data)

const getQuestionsProfessional = (state: RootState) => state.binding.questionsProfessional

const setQuestionsPersonal = (data: IBondFieldGroup) =>
  action(BindingTypes.SET_QUESTIONS_PERSONAL, data)

const getQuestionsPersonal = (state: RootState) => state.binding.questionsPersonal

const setQuestionsAcademic = (data: IBondFieldGroup) =>
  action(BindingTypes.SET_QUESTIONS_ACADEMIC, data)

const getQuestionsAcademic = (state: RootState) => state.binding.questionsAcademic

const setAnsweredDynamicQuestions = (data: IBondField[]) =>
  action(BindingTypes.SET_ANSWERS_DYNAMIC_QUESTIONS, data)

const getAnsweredDynamicQuestions = (state: RootState) => state.binding.answersDynamicQuestions

const createFiliation = (data?: SagaWithHistory) => action(BindingTypes.CREATE_FILIATION, data)

const createAssociationSignUp = (data?: SagaWithHistory) =>
  action(BindingTypes.CREATE_ASSOCIATION_SIGN_UP, data)

const getCheckFiliationRequest = (data?: SagaWithHistory) =>
  action(BindingTypes.GET_CHECK_FILIATION, data)

const getCheckFiliation = (state: RootState) => state.binding.checkFiliation

const setCheckFiliation = (data: boolean) => action(BindingTypes.SET_CHECK_FILIATION, data)

export const BindingActions = {
  getDocumentsListRequest,
  getDocumentsList,
  setDocumentsList,
  getDynamicQuestionsRequest,
  getDynamicQuestions,
  setDynamicQuestions,
  getCurrentGroupQuestions,
  setCurrentGroupQuestions,
  setQuestionsProfessional,
  getQuestionsProfessional,
  setQuestionsPersonal,
  getQuestionsPersonal,
  setQuestionsAcademic,
  getQuestionsAcademic,
  setAnsweredDynamicQuestions,
  getAnsweredDynamicQuestions,
  createFiliation,
  createAssociationSignUp,
  getCheckFiliationRequest,
  getCheckFiliation,
  setCheckFiliation,
}

import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../../utils/providerSaga'
import {
  CancelProposalRequest,
  ICheckoutProposal,
  IUpdateProposalRequest,
  IProposal,
  ICheckoutProposalRequest,
  ProposalTypes,
  ProposalPDFResponse,
} from './types'

const createProposalRequest = (data?: SagaWithHistory) =>
  action(ProposalTypes.CREATE_PROPOSAL, data)

const getProposalRequest = (data?: SagaWithHistory) => action(ProposalTypes.GET_PROPOSAL, data)

const getProposal = (state: RootState) => state.proposal.proposal

const setProposal = (data?: IProposal) => action(ProposalTypes.SET_PROPOSAL, data)

const cancelProposalRequest = (data?: CancelProposalRequest) =>
  action(ProposalTypes.CANCEL_PROPOSAL, data)

const updateProposalRequest = (data: IUpdateProposalRequest) =>
  action(ProposalTypes.UPDATE_PROPOSAL_REQUEST, data)

const setEffectiveDate = (data?: string) => action(ProposalTypes.SET_EFFECTIVE_DATE, data)

const getEffectiveDate = (state: RootState) => state.proposal.effectiveDate

const getFormPayment = (state: RootState) => state.proposal.formPayment

const setFormPayment = (data?: string) => action(ProposalTypes.SET_FORM_PAYMENT, data)

const getCheckoutProposalRequest = (data?: ICheckoutProposalRequest) =>
  action(ProposalTypes.GET_CHECKOUT_PROPOSAL, data)

const getCheckoutProposal = (state: RootState) => state.proposal.checkoutProposal

const setCheckoutProposal = (data: ICheckoutProposal) =>
  action(ProposalTypes.SET_CHECKOUT_PROPOSAL, data)

const getProposalPDF = (state: RootState) => state.proposal.proposalPDF

const setProposalPDF = (data?: ProposalPDFResponse) => action(ProposalTypes.SET_PROPOSAL_PDF, data)

const getProposalPDFRequest = (data?: SagaWithHistory) =>
  action(ProposalTypes.GET_PROPOSAL_PDF, data)

export const ProposalActions = {
  createProposalRequest,
  getProposalRequest,
  getProposal,
  setProposal,
  cancelProposalRequest,
  updateProposalRequest,
  getEffectiveDate,
  setEffectiveDate,
  getFormPayment,
  setFormPayment,
  getCheckoutProposalRequest,
  getCheckoutProposal,
  setCheckoutProposal,
  getProposalPDFRequest,
  getProposalPDF,
  setProposalPDF,
}

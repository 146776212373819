import { IWbISafeResponse } from '@interco/inter-webview-bridge'

import BaseService from '../config/bridge/BaseService'
import {
  CreateProposalRequest,
  ICheckoutProposal,
  IProposal,
  IUpdateEffectiveDateResponse,
  ProposalPDFResponse,
} from '../store/ducks/proposal/types'

export default class ProposalService {
  static async createProposal(cpf: string, data: CreateProposalRequest) {
    const headers = {
      'x-inter-cpf': cpf,
    }

    return BaseService.doExecute<void>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/propostas`,
      headers,
      data,
    })
  }

  static async getProposal() {
    return BaseService.doExecute<IProposal>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/propostas`,
    })
  }

  static async cancelProposal() {
    return BaseService.doExecute<void>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/propostas/cancelar`,
    })
  }

  static async updateProposal(effectiveDate?: string, hasPreviousPlan?: boolean) {
    return BaseService.doExecute<IUpdateEffectiveDateResponse>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/propostas`,
      data: { effectiveDate, hasPreviousPlan },
    })
  }

  static async getProposalPDFContract() {
    return BaseService.doExecute<ProposalPDFResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/propostas/contrato`,
    })
  }

  static async checkoutProposal(iSafeResponse: IWbISafeResponse, signatureLocation?: string) {
    const headers = {
      'x-isafe-token': iSafeResponse.authenticationToken,
      'x-isafe-type': `${iSafeResponse.authenticationType}`,
      'x-isafe-value': iSafeResponse.authenticationValue,
    }

    return BaseService.doExecute<ICheckoutProposal>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/propostas/checkout`,
      headers,
      data: { signatureLocation },
    })
  }
}

import React, { useEffect, useState } from 'react'

import {
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'

import {
  IInterUIDropdownOption as DropdownOption,
  InterUICheckBox,
  InterUIDropdown,
  InterUIInputGroup,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

import { FieldTypes } from '../../enums/DynamicFieldConfig'
import { IBondField } from '../../store/ducks/binding/types'
import { ErrorMessage } from '../../styles/commons'
import { normalizeMask, onlyNumber } from '../../utils/masks'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  IBondField & {
    register?: UseFormRegister<FieldValues>
    getValues?: UseFormGetValues<FieldValues>
    setValue?: UseFormSetValue<FieldValues>
    errors?: FieldError
    validation?: RegisterOptions
    margin?: string
  }

export const DynamicField: React.FC<InputProps> = ({
  type,
  name,
  description,
  format,
  enumValues,
  margin = '0 0 40px',
  validation,
  children,
  register = (_data: unknown) => undefined,
  getValues = (_data: unknown) => undefined,
  setValue = (_data: unknown) => undefined,
  errors,
  ...props
}) => {
  // Remover o atributo value para que não seja passado como props ao input.
  delete props.value

  const [options, setOptions] = useState<DropdownOption[]>([])

  useEffect(() => {
    if (type === FieldTypes.ENUM && enumValues) {
      register(name, { ...validation })

      setOptions(enumValues)
    } else {
      setOptions([])
    }
  }, [type, enumValues])

  const onFilter = (search: string, item: DropdownOption) =>
    RemoveAccents(item.label).includes(search)

  const inputType = () => {
    if (type === 'string' || type === 'number') {
      return 'text'
    }

    return type
  }

  const renderFieldByType = () => {
    switch (type) {
      case FieldTypes.ENUM: {
        return (
          <InterUIDropdown
            margin={margin}
            name={name}
            label={description || ''}
            placeholder="Selecione"
            options={options}
            value={getValues(name) as unknown as string | number | undefined}
            onChange={(data) => setValue(name, data)}
            onFilter={onFilter}
          />
        )
      }
      case FieldTypes.CHECKBOX: {
        return (
          <InterUICheckBox
            name={name}
            id={name}
            margin={margin}
            {...register(name, { ...validation })}
            onChange={(event) => setValue(name, event.target.checked)}
            checked={getValues(name) as unknown as boolean}
          >
            {children}
          </InterUICheckBox>
        )
      }
      default: {
        return (
          <InterUIInputGroup margin={margin} invalid={!!errors}>
            <label htmlFor={name}>{description}</label>
            <input
              {...register(name, {
                ...validation,
                valueAsNumber: type === 'number',
              })}
              {...props}
              type={inputType()}
              id={name}
              autoComplete="off"
              onChange={(event) => {
                if (type === 'number') {
                  onlyNumber(event)
                }

                const valueInput = event.target.value

                const formatedValue = format ? normalizeMask(valueInput, format) : valueInput
                let numberValue: number | undefined

                if (type === 'number') {
                  numberValue = Number.parseInt(formatedValue, 10)
                }

                setValue(name, numberValue || formatedValue, {
                  shouldValidate: true,
                })
              }}
              maxLength={format?.length || 50}
              inputMode={type === 'number' ? 'numeric' : 'text'}
              pattern={type === 'number' ? '[0-9]*' : undefined}
            />

            <ErrorMessage>
              {errors && errors.message ? <span>{errors.message}</span> : ''}
            </ErrorMessage>
          </InterUIInputGroup>
        )
      }
    }
  }

  return renderFieldByType()
}

import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIContainer, InterUIProgressBar } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PageTitles } from '../../../../enums/pageTitles'
import { InputGroup } from '../../../../components/InputGroup/InputGroup'
import { DPSActions } from '../../../../store/ducks/dps/actions'
import { IHeightWeight } from '../../../../store/ducks/dps/types'
import { TypesDocumentationRoutes as Routes } from '../../routes'
import { onlyNumber } from '../../../../utils/masks'

export const HeightAndWeight: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const numberInputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

  const quizStep = useSelector(DPSActions.getQuizStep)

  const initialHeightWeight = {
    height: 0,
    weight: 0,
  }

  const [heightWeight, setHeightWeight] = useState<IHeightWeight>(initialHeightWeight)

  const handleChangeFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    onlyNumber(e)
    const { name, value } = e.target
    let newValue = name === 'number' ? Number(value) : value

    if (name === 'height') {
      newValue = Number.parseInt(value, 10) / 100
    }

    setHeightWeight((prevState: IHeightWeight) => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  const inputValid =
    heightWeight.weight > 2 &&
    heightWeight.weight < 600 &&
    heightWeight.height > 0.54 &&
    heightWeight.height < 3.0

  useBasePage({
    navbarTitle: PageTitles.HEIGHT_WEIGHT,
    backNavigationHandler: history.goBack,
  })

  const onContinue = () => {
    dispatch(DPSActions.setHeightWeight(heightWeight))

    history.push(Routes.DISEASES_CONDITIONS_FORM)
  }

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue} disabled={!inputValid}>
      Continuar
    </Button>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar
        progress={`${quizStep?.amountProgress * 2}%`}
        aria-valuenow={2}
        aria-valuemin={1}
        aria-valuemax={quizStep?.length + 2}
      />

      <InterUIContainer margin="63.5px 24px 24px">
        <InputGroup margin="0 0 29px" data-testid="height-inputgroup">
          <label htmlFor="height">Qual é a sua altura, em centímetros?</label>
          <input
            type="text"
            name="height"
            id="height"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={3}
            minLength={2}
            ref={numberInputRef}
            placeholder="Digite apenas números"
            onInput={handleChangeFields}
          />
        </InputGroup>
        <InputGroup margin="0 0 24.5px" data-testid="weight-inputgroup">
          <label htmlFor="weight">Qual é o seu peso atual, em quilos?</label>
          <input
            type="text"
            name="weight"
            id="weight"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={3}
            minLength={2}
            ref={numberInputRef}
            placeholder="Digite apenas números"
            onInput={handleChangeFields}
          />
        </InputGroup>
      </InterUIContainer>
    </InterUIContainer>
  )
}

import styled from 'styled-components'

export const BtnHome = styled.button.attrs({ type: 'button' })`
  justify-content: flex-end;
`

export const BtnPDF = styled.a`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  text-decoration: none;
`

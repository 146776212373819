import { Reducer } from 'redux'

import { AddressState, AddressTypes, IAddress } from './types'

const INITIAL_STATE: AddressState = {
  addressList: [],
  selectedAddress: undefined,
  searchedAddress: {} as IAddress,
}

const reducer: Reducer<AddressState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AddressTypes.SET_ADDRESS_LIST:
      return { ...state, addressList: action.payload }
    case AddressTypes.SET_SELECTED_ADDRESS:
      return { ...state, selectedAddress: action.payload }
    case AddressTypes.SET_SEARCHED_ADDRESS:
      return { ...state, searchedAddress: action.payload }
    default:
      return state
  }
}

export default reducer

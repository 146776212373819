import BaseService from '../config/bridge/BaseService'
import { UserState } from '../store/ducks/user/types'

export default class UserService {
  static async getUserDetails() {
    return BaseService.doExecute<UserState>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/clientes`,
    })
  }
}

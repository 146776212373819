import styled from 'styled-components'

import { PSmall } from '../../../../styles/commons'

export const QuestionHeader = styled.h3`
  margin: 24px 0 8px;
`

export const ConditionalInfo = styled(PSmall)`
  margin: 24px 0 40px;
`

import React, { useEffect, useState } from 'react'

import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { InterUIContainer, InterUIIcon } from '@interco/inter-ui-react-lib'

import BaseBridge from '../../config/bridge/BaseBridge'
import { ErrorActions } from '../../store/ducks/error/actions'
import { PersistedHiringActions } from '../../store/ducks/persistedHiring/actions'
import { ProposalActions } from '../../store/ducks/proposal/actions'
import { HealthPlanActions } from '../../store/ducks/healthPlan/actions'
import { NavbarActions } from '../../store/ducks/navbar/actions'
import { BtnBack, ErrorContainer } from './Error.styles'
import { H1, Hero, PMedium } from '../../styles/commons'
import { TypesSimulationRoutes as SimuRoutes } from '../../modules/simulation/routes'
import { TypesPendingRoutes as PendRoutes } from '../../modules/pending/routes'
import HeroFirstImg from '../../assets/img/personas-error.png'
import HeroSecondImg from '../../assets/img/afro-personas-error.png'

export const Error: React.FC = () => {
  const theme = useTheme().colors
  const location = useLocation()
  const dispatch = useDispatch()

  const showError = useSelector(ErrorActions.get)
  const viewedHero = useSelector(ErrorActions.getViewedHero)
  const firstPage = useSelector(PersistedHiringActions.getFirstPage)
  const backNavigation = useSelector(NavbarActions.getBackNavigationHandler)

  const proposal = useSelector(ProposalActions.getProposal)
  const planDetails = useSelector(HealthPlanActions.getHealthPlanDetails)

  const [heroImg, setHeroImg] = useState<string>()

  useEffect(() => {
    if (viewedHero === 'HeroFirstImg') {
      setHeroImg(HeroSecondImg)

      dispatch(ErrorActions.setViewedHero('HeroSecondImg'))
    } else {
      setHeroImg(HeroFirstImg)

      dispatch(ErrorActions.setViewedHero('HeroFirstImg'))
    }

    BaseBridge.addBackListener(goBack)
  }, [])

  const goBack = () => {
    const path = location?.pathname

    if (
      path === SimuRoutes.SIMULATION ||
      (path === PendRoutes.PENDING && (!proposal?.plan || !planDetails)) ||
      path === firstPage
    ) {
      BaseBridge.requestGoBack()
    } else {
      dispatch(ErrorActions.hide())
    }

    BaseBridge.addBackListener(backNavigation)
  }

  /**
   * @warning Funcionalidade não disponibilizada no momento.
   */
  // const stickyFooter = (
  //   <>
  //     <Button margin='0 0 16px' onClick={() => {}}>
  //       Tentar novamente
  //     </Button>
  //     <Button onClick={goBack}>Voltar para o passo anterior</Button>
  //   </>
  // );

  return showError ? (
    <ErrorContainer>
      <InterUIContainer>
        <BtnBack type="button" onClick={goBack} aria-label="Botão voltar erro">
          <InterUIIcon name="arrow-left" size="md" color={theme.primary.A500} />
        </BtnBack>

        <Hero>
          <img src={heroImg} alt="Personas" />
        </Hero>

        <H1 marginBottom="8px">Houve um erro por aqui</H1>

        <PMedium scale={400}>
          No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns
          minutos.
        </PMedium>
      </InterUIContainer>
    </ErrorContainer>
  ) : null
}

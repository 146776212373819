import axios, { Method } from 'axios'

import InterWebviewBridge, {
  interWbHttp,
  IWbHttpData,
  IWbHttpOptions,
} from '@interco/inter-webview-bridge'

import NewRelicUtils from '../monitoring/NewRelicUtils'

export interface BaseRequestConfig {
  method: Method
  endpoint: string
  headers?: Record<string, string>
  data?: unknown
  options?: IWbHttpOptions
}

export interface BaseResponseBack<T> {
  status: number
  dateTime: string
  data: T
}

export class BaseService {
  static async authDev(): Promise<void> {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_API_AUTH_USER}`,
    }

    const response = await axios({
      url: `${process.env.REACT_APP_API_HOST}/oauth/token?grant_type=client_credentials`,
      method: 'POST',
      headers,
    })

    axios.defaults.headers['x-api-token'] = response.data.access_token
  }

  static async callByAxios<T>(config: BaseRequestConfig): Promise<BaseResponseBack<T>> {
    const { endpoint, ...data } = config

    try {
      if (!axios.defaults.headers['x-api-token']) {
        await BaseService.authDev()
      }

      const response = await axios({
        url: `${process.env.REACT_APP_API_HOST}/${endpoint}`,
        ...data,
      })

      return response.data
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseService.callByAxios',
        endpoint,
      })

      return Promise.reject((error as { response: { data: string } })?.response?.data)
    }
  }

  static async callByBridge<T>(config: BaseRequestConfig) {
    const { endpoint, headers, data, options } = config

    try {
      const method = config.method.toLowerCase()

      let bridgeResponse = {} as IWbHttpData

      switch (method) {
        case 'post':
        case 'put':
          bridgeResponse = await interWbHttp[method](
            endpoint,
            data as Record<string, unknown>,
            headers,
            options,
          )
          break
        case 'get':
        case 'delete':
          bridgeResponse = await interWbHttp[method](endpoint, headers)
          break
        default:
          break
      }

      let parsedData = bridgeResponse.response as string

      if (typeof parsedData === 'string') {
        parsedData = JSON.parse(parsedData)
      }

      return parsedData as unknown as BaseResponseBack<T>
    } catch (error) {
      let parsedError = error

      if (typeof error === 'string') {
        parsedError = JSON.parse(error)
      }

      const errorObj = {
        status: (parsedError as { httpStatus: number })?.httpStatus || 500,
        message: (parsedError as { response: string })?.response,
      }

      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseService.callByBridge',
        endpoint,
      })

      return Promise.reject(errorObj)
    }
  }

  static async doExecute<T>(config: BaseRequestConfig): Promise<BaseResponseBack<T>> {
    const iWb = InterWebviewBridge.getInstance()

    if (iWb.getEnvironment() === 'BROWSER') {
      return BaseService.callByAxios(config)
    }
    return BaseService.callByBridge<T>(config)
  }
}

export default BaseService

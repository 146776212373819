import React from 'react'

import { useHistory } from 'react-router-dom'

import {
  InterUIContainer,
  InterUIIcon,
  InterUIListDescription,
  InterUIProgressBar,
} from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PMedium, StepHeading } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { OrangePage } from './ShippingTips.style'
import { Colors } from '../../../../styles/colors'
import { TypesDocumentationRoutes as DocumentationRoutes } from '../../routes'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const ShippingTips: React.FC = () => {
  const history = useHistory()

  const iconSendImage = <InterUIIcon name="send-image" size="md" color={Colors.white} />

  const iconImages = <InterUIIcon name="images" size="md" color={Colors.white} />

  const iconCamera = <InterUIIcon name="camera" size="md" color={Colors.white} />

  const iconPdfFile = <InterUIIcon name="pdf-file" size="md" color={Colors.white} />

  useBasePage({
    navbarTitle: PageTitles.SHIPPING_TIPS,
    backNavigationHandler: history.goBack,
  })

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_DicasParaEnvio_T_Continuar')

    history.push(DocumentationRoutes.SEND_DOCUMENTATION)
  }

  const stickyFooter = (
    <Button variant="primaryNegative" margin="0 24px 24px" onClick={onContinue}>
      Continuar
    </Button>
  )

  return (
    <OrangePage>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar
          progressColor={Colors.white}
          barColor={Colors.yellowOrange}
          progress="50%"
          aria-valuenow={2}
          aria-valuemin={1}
          aria-valuemax={3}
        />

        <InterUIContainer>
          <StepHeading>
            É preciso que você tenha os documentos originais em mãos, ou digitalizados.
          </StepHeading>

          <InterUIListDescription margin="24px 0 0 0" iconLeft={iconSendImage} role="group">
            <PMedium bold>Envie todas as páginas</PMedium>
            <PMedium>
              Se o documento tiver frente e verso ou mais de uma página, envie todas.
            </PMedium>
          </InterUIListDescription>

          <InterUIListDescription margin="8px 0 0 0" iconLeft={iconImages} role="group">
            <PMedium bold>Escolha entre imagens e PDFs ou fotos</PMedium>
            <PMedium>
              Você poderá enviar arquivos do seu celular, como imagens e PDFs, ou tirar foto do
              documento, se estiver impresso.
            </PMedium>
          </InterUIListDescription>

          <InterUIListDescription margin="8px 0 0 0" iconLeft={iconCamera} role="group">
            <PMedium bold>Dicas para fotos</PMedium>
            <PMedium>
              Se for tirar fotos, elas devem ser bem iluminadas. E centralize o documento, sem
              deixar seu dedo aparecer.
            </PMedium>
          </InterUIListDescription>

          <InterUIListDescription margin="8px 0 0 0" iconLeft={iconPdfFile} role="group">
            <PMedium bold>Dicas para PDFs</PMedium>
            <PMedium>
              Se for enviar o documento em PDF, pode ser tanto um único arquivo com todas as páginas
              ou cada página separada.
            </PMedium>
          </InterUIListDescription>
        </InterUIContainer>
      </InterUIContainer>
    </OrangePage>
  )
}

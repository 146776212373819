import styled from 'styled-components'

export const PanelTitle = styled.h1`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;

  margin-bottom: 4px;
`

export const PanelMessage = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
`

export const AcceptTermsMessage = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  padding: 5px;
`

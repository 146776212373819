import { Reducer } from 'redux'

import { ClassEntityState, ClassEntityTypes } from './types'

const INITIAL_STATE: ClassEntityState = {
  selectedBond: undefined,
  listOccupations: [],
  selectedOccupation: undefined,
  listEntities: [],
  selectedEntity: undefined,
}

const reducer: Reducer<ClassEntityState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClassEntityTypes.SET_SELECTED_BOND:
      return { ...state, selectedBond: action.payload }

    case ClassEntityTypes.SET_LIST_OCCUPATIONS:
      return { ...state, listOccupations: action.payload }

    case ClassEntityTypes.SET_SELECTED_OCCUPATION:
      return { ...state, selectedOccupation: action.payload }

    case ClassEntityTypes.SET_LIST_CLASS_ENTITIES:
      return { ...state, listEntities: action.payload }

    case ClassEntityTypes.SET_SELECTED_CLASS_ENTITY:
      return { ...state, selectedEntity: action.payload }

    default:
      return state
  }
}

export default reducer

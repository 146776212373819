import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { InterUIContainer, InterUILoading } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { PageTitles } from '../../../../enums/pageTitles'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PdfContainer, LoadContainer } from './ReadjustmentLetter.styles'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { InterUIPdfReader } from '../../../../components/InterUIPdfReader/InterUIPdfReader'
import { isStringNotEmpty } from '../../../../utils/commons'

export const ReadjustmentLetter: React.FC = () => {
  const history = useHistory()
  const [readjustmentLetter, setReadjustmentLetter] = useState<string>('')
  const proposal = useSelector(ProposalActions.getProposal)
  const [isShownPDF, setIsShownPDF] = useState<boolean>(false)

  useBasePage({
    navbarTitle: PageTitles.READJUSTMENT_LETTER,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    const letter = proposal.plan.readjustmentLetter

    if (letter && isStringNotEmpty(letter)) {
      setIsShownPDF(true)
      setReadjustmentLetter(letter)
    }
  }, [proposal])

  const sticky = (
    <Button
      margin="24px"
      onClick={() => {
        history.goBack()
      }}
    >
      Entendi
    </Button>
  )

  return isShownPDF ? (
    <InterUIContainer stickyFooter={sticky} margin="0px">
      <PdfContainer>
        <InterUIPdfReader
          file={readjustmentLetter}
          height="100%"
          loadingMessage="Carregando Contrato..."
        />
      </PdfContainer>
    </InterUIContainer>
  ) : (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )
}

import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { useBasePage } from '../../../../hooks/useBasePage'
import { BindingActions } from '../../../../store/ducks/binding/actions'
import { PMedium, Hero, H1 } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesBindingRoutes as Routes } from '../../routes/types'
import HeroImg from '../../../../assets/img/personas.png'

export const PendingConfirmation: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    backNavigationHandler: history.goBack,
  })

  const onConfirm = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_VinculFiliacao_T_Botao', {
      content_name: 'Confirmei no WhatsApp',
      content_id: 'Confirmação pendente',
    })

    dispatch(
      BindingActions.getCheckFiliationRequest({
        history,
        pathname: Routes.BINDING_CONFIRMED,
      }),
    )
  }

  const resendMessage = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_VinculFiliacao_T_Botao', {
      content_name: 'Reenviar mensagem',
      content_id: 'Confirmação pendente',
    })

    dispatch(
      BindingActions.createFiliation({
        history,
        pathname: Routes.RESENT_MESSAGE,
      }),
    )
  }

  const stickyFooter = (
    <>
      <Button margin="0px 0px 16px 0px" onClick={onConfirm}>
        <p>Confirmei no WhatsApp</p>
      </Button>

      <Button onClick={resendMessage} variant="secondary">
        <p>Reenviar mensagem</p>
      </Button>
    </>
  )

  return (
    <>
      <InterUIContainer stickyFooter={stickyFooter}>
        <Hero>
          <img src={HeroImg} alt="Persona segurando um celular" />
        </Hero>

        <H1 marginBottom="8px">Confirmação pendente</H1>

        <PMedium scale={400}>
          Parece que você ainda não confirmou sua vinculação à UNE na conversa pelo WhatsApp. É
          preciso que você vá até o WhatsApp, confirme, e volte aqui para continuar a contratação do
          seu plano.
        </PMedium>
      </InterUIContainer>
    </>
  )
}

import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import ClassEntityService from '../../../services/ClassEntityService'
import { ClassEntityTypes } from './types'
import { ClassEntityActions } from './actions'
import { LoadingActions } from '../loading/actions'
import { AddressActions } from '../address/actions'
import { TypesSimulationRoutes as Routes } from '../../../modules/simulation/routes/types'

function* getListOccupations({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const address = yield* select(AddressActions.getSelectedAddress)

    if (address) {
      const response = yield* call(ClassEntityService.getListOccupations, address)

      const data = response.data.occupations

      if (data && data.length > 0) {
        data.push({ name: 'Nenhuma dessas' })
      }

      yield* put(ClassEntityActions.setListOccupations(data))

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'ClassEntityActionsSaga.getListOccupations')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ClassEntityActionsSaga.getListOccupations')
  }
}

function* getListEntities({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const address = yield* select(AddressActions.getSelectedAddress)
    const occupation = yield* select(ClassEntityActions.getSelectedOccupation)

    const digitalMembership = payload?.digitalMembership

    if (address && occupation) {
      const response = yield* call(
        ClassEntityService.getListEntities,
        address,
        occupation.name,
        digitalMembership,
      )

      const data = response.data.associations

      if (data && data.length > 0) {
        data.push({ name: 'Nenhuma dessas', tradingName: '' })
      }

      const redirect = { ...payload }

      if (data.length === 0 && redirect?.pathname === Routes.BIND_TO_ENTITY) {
        redirect.pathname = Routes.WITHOUT_COVER
      }

      yield* put(ClassEntityActions.setListEntities(data))

      redirectThroughSaga(redirect)

      yield* put(LoadingActions.hide())
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'ClassEntityActionsSaga.getListEntities')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ClassEntityActionsSaga.getListEntities')
  }
}

function* getProposalClassEntity({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { planId } = payload

    const response = yield* call(ClassEntityService.getClassEntityByPlanId, planId)

    yield* put(ClassEntityActions.setSelectedClassEntity(response.data.association))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'ClassEntityActionsSaga.getProposalClassEntity')
  }
}

export default function* watchClassEntity() {
  yield* takeLatest(ClassEntityTypes.GET_LIST_OCCUPATIONS, getListOccupations)
  yield* takeLatest(ClassEntityTypes.GET_LIST_CLASS_ENTITIES, getListEntities)
  yield* takeLatest(ClassEntityTypes.GET_PROPOSAL_CLASS_ENTITY, getProposalClassEntity)
}

import { SagaWithHistory } from '../../utils/providerSaga'
import { IAddress } from '../address/types'
import { IHealthPlan } from '../healthPlan/types'

export enum ProposalTypes {
  CREATE_PROPOSAL = `@proposal/CREATE_PROPOSAL`,
  GET_PROPOSAL = `@proposal/GET_PROPOSAL`,
  SET_PROPOSAL = `@proposal/SET_PROPOSAL`,
  CANCEL_PROPOSAL = `@proposal/CANCEL_PROPOSAL`,
  GET_EFFECTIVE_DATE = `@proposal/GET_EFFECTIVE_DATE`,
  SET_EFFECTIVE_DATE = `@proposal/SET_EFFECTIVE_DATE`,
  UPDATE_PROPOSAL_REQUEST = `@proposal/UPDATE_PROPOSAL_REQUEST`,
  SET_FORM_PAYMENT = `@proposal/SET_FORM_PAYMENT`,
  GET_FORM_PAYMENT = `@proposal/GET_FORM_PAYMENT`,
  SET_CHECKOUT_PROPOSAL = `@proposal/SET_CHECKOUT_PROPOSAL`,
  GET_CHECKOUT_PROPOSAL = `@proposal/GET_CHECKOUT_PROPOSAL`,
  GET_PROPOSAL_PDF = `@proposal/GET_PROPOSAL_PDF`,
  SET_PROPOSAL_PDF = `@proposal/SET_PROPOSAL_PDF`,
}

export interface CreateProposalRequest {
  planId: string
  association: string
  occupation: string
  address: IAddress
}

export interface CancelProposalRequest extends SagaWithHistory {
  goToNative?: boolean
}

export interface IProposal {
  status: string
  contractPreviewUrl: string
  plan: IHealthPlan
  insuranceHolder: IInsuranceHolder
}

export interface IInsuranceHolder {
  name: string
  mothersName: string
  gender: string
  maritalStatus: string
  rg: string
  address: IAddress[]
  email: string
  phones: IPhone[]
}

interface IPhone {
  number: string
  type: string
}

export interface IUpdateProposalRequest extends SagaWithHistory {
  effectiveDate?: string
  hasPreviousPlan?: boolean
}

export interface IUpdateEffectiveDateResponse {
  plan: {
    valuePerMonth: string
    cashback?: number
    readjustmentLetter?: string
  }
}

export interface ICheckoutProposal {
  readyTransmission: boolean
  pending: string[]
  protocol: string
  transmitted: string
}

export interface ICheckoutProposalRequest extends SagaWithHistory {
  onTransmission?: (data: ICheckoutProposal) => void
}

export interface ProposalPDFResponse {
  proposalPDF?: string
}

export interface ProposalState {
  readonly proposal: IProposal
  readonly effectiveDate: string
  readonly formPayment?: string
  readonly checkoutProposal: ICheckoutProposal
  readonly proposalPDF?: ProposalPDFResponse
}

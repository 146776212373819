import React from 'react'

import { Route } from 'react-router-dom'

import { TypesSimulationRoutes as Routes } from './types'
import {
  Home,
  SelectAddress,
  SelectBond,
  UpdateAddress,
  SelectClassEntity,
  BindToEntity,
  HealthPlanList,
  HealthPlanDetails,
  WithoutCover,
} from '../pages'

export const SimulationRoutes: React.FC = () => (
  <>
    <Route path={Routes.SIMULATION} exact component={Home} />
    <Route path={Routes.SELECT_ADDRESS} exact component={SelectAddress} />
    <Route path={Routes.UPDATE_ADDRESS} exact component={UpdateAddress} />
    <Route path={Routes.SELECT_BOND} exact component={SelectBond} />
    <Route path={Routes.SELECT_CLASS_ENTITY} exact component={SelectClassEntity} />
    <Route path={Routes.BIND_TO_ENTITY} exact component={BindToEntity} />
    <Route path={Routes.HEALTH_PLAN_LIST} exact component={HealthPlanList} />
    <Route path={Routes.HEALTH_PLAN_DETAILS} exact component={HealthPlanDetails} />
    <Route path={Routes.WITHOUT_COVER} exact component={WithoutCover} />
  </>
)

export * from './types'

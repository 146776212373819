import { Reducer } from 'redux'
import { persistReducer } from 'redux-persist'

import { PersistedHiringState, PersistedHiringTypes } from './types'
import { persistConfig } from '../../utils/encryptTransform'

const INITIAL_STATE: PersistedHiringState = {
  timestamp: undefined,
  firstPage: undefined,
  simulation: undefined,
  binding: undefined,
  dps: undefined,
  documentation: undefined,
}

const reducer: Reducer<PersistedHiringState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PersistedHiringTypes.CLEAR_ALL:
      return INITIAL_STATE

    case PersistedHiringTypes.SET_TIMESTAMP:
      return { ...state, timestamp: action.payload }

    case PersistedHiringTypes.SET_FIRST_PAGE:
      return { ...state, firstPage: action.payload }

    case PersistedHiringTypes.SET_SIMULATION_STATE:
      return { ...state, simulation: action.payload }

    case PersistedHiringTypes.SET_BINDING_STATE:
      return { ...state, binding: action.payload }

    case PersistedHiringTypes.SET_DPS_STATE:
      return { ...state, dps: action.payload }

    case PersistedHiringTypes.SET_DOC_STATE:
      return { ...state, documentation: action.payload }

    default:
      return state
  }
}

const blackList = ['firstPage']

export const persistedPersistedHiring = persistReducer<PersistedHiringState>(
  persistConfig('persistedHiring', undefined, blackList),
  reducer,
)

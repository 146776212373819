import React from 'react'

import { InterUIAccordion, InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

import { IPending } from '../../../../store/ducks/pending/types'
import { H3, PSmall } from '../../../../styles/commons'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const PendingList: React.FC<{
  pendencies: IPending[]
  planName: string
}> = ({ pendencies, planName }) => {
  const renderPending = pendencies?.map((item, i) => {
    const keyAccordion = `pending-list-${i}`

    return (
      <InterUIAccordion
        id={keyAccordion}
        key={keyAccordion}
        header={item.type}
        border="bottom"
        onClick={() => {
          BaseBridge.requestAnalytics('C_PlanoSaude_Pendecias_T', {
            content_name: planName,
            content_id: item.type,
          })
        }}
      >
        {item.pending.map((pending, j) => {
          const keyDescription = `${keyAccordion}-${j}`

          return (
            <PSmall
              key={keyDescription}
              scale={400}
              style={j > 0 ? { marginTop: '8px' } : { marginTop: '0px' }}
              marginBottom="0px"
            >
              {pending}
            </PSmall>
          )
        })}
      </InterUIAccordion>
    )
  })
  return (
    <>
      <InterUIContainer>
        <H3 marginBottom="8px">Essas são suas pendências:</H3>

        {renderPending}
      </InterUIContainer>

      <InterUISeparator height="8px" />
    </>
  )
}

import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import BindingService from '../../../services/BindingService'
import {
  BindingTypes,
  CreateFiliationRequest,
  CreateAssociationSignUpRequest,
  IBondField,
} from './types'
import { BindingActions } from './actions'
import { LoadingActions } from '../loading/actions'
import { UserActions } from '../user/actions'
import { ClassEntityActions } from '../classEntity/actions'
import { PersistedHiringActions } from '../persistedHiring/actions'
import { QuestionGroups } from '../../../enums/DynamicFieldConfig'
import { BaseBridge } from '../../../config/bridge/BaseBridge'
import { AddressActions } from '../address/actions'
import { convertDateToBrazil } from '../../../utils/masks'

function* getDocumentsList({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Chama_Servico_Lista_De_Documentos')
    const response = yield* call(BindingService.getDocumentsList)

    yield* put(BindingActions.setDocumentsList(response.data.arquivos))

    BaseBridge.requestAnalytics('C_PlanoSaude_Simulacao_Chama_Persistencia_Da_Simulacao')
    yield* put(PersistedHiringActions.setSimulationStateRequest(payload))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'BindingActionsSaga.getDocumentsList')
  }
}

function* getDynamicQuestions({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const user = yield* select(UserActions.getUserDetails)
    const entity = yield* select(ClassEntityActions.getSelectedClassEntity)

    if (user && entity) {
      const response = yield* call(BindingService.getDynamicQuestions, user.cpf, entity)

      const data = response.data.questions

      yield* put(BindingActions.setDynamicQuestions(data))

      let answers: IBondField[] = []

      yield* data.map((item) => {
        const fields = item.fields.filter((e) => !e.value)

        const question = { ...item, fields }

        answers = answers.concat(item.fields)

        switch (item.group) {
          case QuestionGroups.PROFESSIONAL_DATA:
            return put(BindingActions.setQuestionsProfessional(question))
          case QuestionGroups.PERSONAL_DATA:
            return put(BindingActions.setQuestionsPersonal(question))
          case QuestionGroups.ACADEMIC_FORMATION:
            return put(BindingActions.setQuestionsAcademic(question))
          default:
            return null
        }
      })

      yield* put(BindingActions.setAnsweredDynamicQuestions(answers))

      redirectThroughSaga(payload)

      yield* put(LoadingActions.hide())
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'BindingActionsSaga.getDynamicQuestions')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'BindingActionsSaga.getDynamicQuestions')
  }
}

function* createFiliation({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const association = yield* select(ClassEntityActions.getSelectedClassEntity)

    let associationName = ''

    if (association) {
      associationName = association?.tradingName
    }

    const answers = yield* select(BindingActions.getAnsweredDynamicQuestions)
    const professional = yield* select(BindingActions.getQuestionsProfessional)
    const personal = yield* select(BindingActions.getQuestionsPersonal)
    const academic = yield* select(BindingActions.getQuestionsAcademic)

    if (answers) {
      const iSafeRequest = {
        category: '16',
        checkType: '5',
        feature: 'AUTORIZACAO_SEGURO',
        value: 0,
      }

      const iSafeResponse = yield* call(BaseBridge.requestISafe, iSafeRequest)

      let formAnswers = professional?.fields || []

      if (personal?.fields) {
        formAnswers = formAnswers.concat(personal?.fields)
      }

      if (academic?.fields) {
        formAnswers = formAnswers.concat(academic?.fields)
      }

      let answersBody = answers

      if (formAnswers.length > 0) {
        answersBody = answers.map((item) => {
          const newObj = item

          if (!newObj.value) {
            const found = formAnswers.find((e) => e.name === newObj.name)
            let value = found?.value || ''

            if (found?.type === 'date' && value) {
              value = convertDateToBrazil(value)
            }

            newObj.value = value
          }

          return newObj
        })
      }

      const body: CreateFiliationRequest = {
        fields: answersBody,
      }

      yield* call(BindingService.createFiliation, iSafeResponse, associationName, body)

      if (associationName !== 'UNE') {
        const request = {
          ...payload,
          signature: iSafeResponse.authenticationToken,
        }

        yield* put(BindingActions.createAssociationSignUp(request))
      } else {
        yield* put(PersistedHiringActions.setBindingStateRequest(payload))
      }
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'BindingActionsSaga.createFiliation')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'BindingActionsSaga.createFiliation')
  }
}

function* checkUpFiliation({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const association = yield* select(ClassEntityActions.getSelectedClassEntity)

    let associationName = ''

    if (association) {
      associationName = association?.tradingName
    }

    const response = yield* call(BindingService.checkFiliation, associationName)

    yield* put(BindingActions.setCheckFiliation(response.data.filiated))

    if (response.data.filiated === true) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'BindingActionsSaga.checkUpFiliation')
  }
}

function* createAssociationSignUp({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const user = yield* select(UserActions.getUserDetails)
    const association = yield* select(ClassEntityActions.getSelectedClassEntity)

    const address = yield* select(AddressActions.getSelectedAddress)

    if (user && association) {
      const body: CreateAssociationSignUpRequest = {
        documentNumber: user.cpf,
        signature: payload.signature,
        signatureLocation: address?.city,
      }

      yield* call(BindingService.createAssociationSignUp, association.tradingName, body)

      yield* put(PersistedHiringActions.setBindingStateRequest(payload))
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'BindingActionsSaga.createAssociationSignUp')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'BindingActionsSaga.createAssociationSignUp')
  }
}

export default function* watchBinding() {
  yield* takeLatest(BindingTypes.GET_DOCUMENTS_LIST, getDocumentsList)
  yield* takeLatest(BindingTypes.GET_DYNAMIC_QUESTIONS, getDynamicQuestions)
  yield* takeLatest(BindingTypes.CREATE_FILIATION, createFiliation)
  yield* takeLatest(BindingTypes.CREATE_ASSOCIATION_SIGN_UP, createAssociationSignUp)
  yield* takeLatest(BindingTypes.GET_CHECK_FILIATION, checkUpFiliation)
}

export enum ErrorTypes {
  SHOW = `@error/SHOW`,
  HIDE = `@error/HIDE`,
  SET_VIEWED_HERO = `@error/SET_VIEWED_HERO`,
  SET_NUMBER_ATTEMPTS = `@error/SET_NUMBER_ATTEMPTS`,
}

export interface ErrorState {
  readonly toggle: boolean
  readonly viewedHero?: string
  readonly numberAttempts: number
}

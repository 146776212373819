import BaseService from '../config/bridge/BaseService'
import { IAddress } from '../store/ducks/address/types'

export default class AddressService {
  static async getAddressByCep(cep: string) {
    try {
      return await BaseService.doExecute<IAddress>({
        method: 'GET',
        endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/enderecos/${cep}`,
      })
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        return { data: { address: {} } }
      }
      return Promise.reject(error)
    }
  }

  static async updateAddress(address: IAddress) {
    return BaseService.doExecute<IAddress>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_INSURANCE_HEALTH_V1}/enderecos`,
      data: address,
    })
  }
}

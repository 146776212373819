import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import {
  InterUIContainer,
  InterUIInputGroup,
  InterUIBottomSheet,
  InterUICheckBox,
} from '@interco/inter-ui-react-lib'

import { Button } from '..'

import BaseBridge from '../../config/bridge/BaseBridge'
import { AddressActions } from '../../store/ducks/address/actions'
import { IAddress } from '../../store/ducks/address/types'
import { ProposalActions } from '../../store/ducks/proposal/actions'
import { InputGroup } from '../InputGroup/InputGroup'
import { PMedium } from '../../styles/commons'
import { ErrorMessage, NumberContainer } from './AddressForm.styles'
import { AddressMessages } from '../../enums/AddressMessages'
import { onlyNumber } from '../../utils/masks'

interface AddressFormProps {
  onSubmit: (data: IAddress) => void
  goBackHome: () => void

  disableBtn?: boolean
  defaultValues?: IAddress
  gtmEvent?: string

  blockCEP?: boolean
}

interface IAddressForm extends IAddress {
  noNumber?: boolean
}

export const AddressForm: React.FC<AddressFormProps> = ({
  onSubmit,
  goBackHome,
  disableBtn,
  gtmEvent,
  defaultValues,
  blockCEP,
}) => {
  const dispatch = useDispatch()

  const initialAddressState = {
    zipcode: '',
    typeAddress: 'Residencial',
    address: '',
    number: undefined,
    complement: '',
    neighborhood: '',
    district: '',
    city: '',
  }

  const searchedAddress = useSelector(AddressActions.getSearchedAddress)

  const [invalidZipCode, setInvalidZipCode] = useState(false)
  const [blockFields, setBlockFields] = useState(false)
  const [uncoveredZipCodeBS, setUncoveredZipCodeBS] = useState(false)
  const [noReceiveAddress, setNoReceiveAddress] = useState(false)

  const { register, handleSubmit, setValue, reset, watch } = useForm<IAddressForm>({
    mode: 'onChange',
  })

  const zipCode = watch('zipcode')
  const number = watch('number')
  const noNumber = watch('noNumber')
  const neighborhood = watch('neighborhood')
  const addressValue = watch('address')
  const proposal = useSelector(ProposalActions.getProposal)

  useEffect(() => {
    if (searchedAddress?.zipcode) {
      reset(searchedAddress)

      setBlockFields(true)
    } else if (searchedAddress && zipCode) {
      setUncoveredZipCodeBS(true)
    }
  }, [searchedAddress])

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
      setBlockFields(true)
    }
  }, [defaultValues])

  const onChangeZipCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidZipCode(false)

    onlyNumber(event)

    if (event.target.value.length === 8) {
      event.target.blur()

      getSearchAddress(event.target.value)
    }
  }

  const syncActionWithSaga = (data: IAddress) => {
    if (data.address === '') {
      setNoReceiveAddress(true)
    }
  }

  const getSearchAddress = (value: string) => {
    setNoReceiveAddress(false)

    const payload = {
      zipcode: value,
      syncActionWithSaga,
    }

    dispatch(AddressActions.getAddressByCep(payload))

    reset({ ...initialAddressState, zipcode: value })

    setBlockFields(false)
  }

  const onBlurZipCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value?.length < 8) {
      setInvalidZipCode(true)
    }
  }

  const onSubmitForm = (data: IAddressForm) => {
    const address = data

    delete address?.noNumber

    if (gtmEvent) {
      BaseBridge.requestAnalytics('C_PlanoSaude_Endereco_T_SolicitarAtual', {
        content_name: proposal.plan.displayName,
      })
    }

    if (blockCEP && defaultValues) {
      data = {
        zipcode: defaultValues.zipcode,
        address: defaultValues.address,
        city: defaultValues.city,
        district: defaultValues.district,
        neighborhood: defaultValues.neighborhood,
        typeAddress: defaultValues.typeAddress,
        complement: data.complement,
        number: data.number,
      }
    }

    onSubmit(data)
  }

  const anotherZipCode = () => {
    reset(initialAddressState)

    setInvalidZipCode(false)
    setUncoveredZipCodeBS(false)
  }

  const stickyFooter = (
    <Button
      type="submit"
      disabled={
        (!noNumber && !number) ||
        (!blockCEP &&
          (zipCode?.length < 8 ||
            !blockFields ||
            !neighborhood ||
            addressValue === '' ||
            disableBtn))
      }
    >
      Solicitar atualização
    </Button>
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <InterUIContainer stickyFooter={stickyFooter}>
          <input type="hidden" {...register('typeAddress')} />

          <InputGroup invalid={invalidZipCode} readOnly={blockCEP}>
            <label htmlFor="zipcode">CEP</label>
            <input
              {...register('zipcode', { required: true })}
              type="text"
              id="zipcode"
              inputMode="numeric"
              maxLength={8}
              onChange={onChangeZipCode}
              onBlur={onBlurZipCode}
              autoComplete="off"
              disabled={blockCEP}
              readOnly={blockCEP}
            />
          </InputGroup>

          <ErrorMessage>{invalidZipCode ? 'Insira um CEP válido' : ''}</ErrorMessage>

          <InterUIInputGroup
            margin="0 0 35px"
            disabled={!blockFields || blockCEP}
            readOnly={(!noReceiveAddress && blockFields) || blockCEP}
          >
            <label htmlFor="address">Endereço</label>
            <input
              {...register('address')}
              type="text"
              id="address"
              readOnly={(!noReceiveAddress && blockFields) || blockCEP}
              disabled={!blockFields || blockCEP}
              autoComplete="off"
            />
          </InterUIInputGroup>

          <NumberContainer>
            <InterUIInputGroup margin="0 18px 0 0" disabled={!blockFields || noNumber}>
              <label htmlFor="number">Número</label>
              <input
                {...register('number')}
                type="number"
                id="number"
                disabled={!blockFields || noNumber}
                autoComplete="off"
              />
            </InterUIInputGroup>

            <InterUICheckBox
              {...register('noNumber')}
              margin="18px 0 0"
              variant="simple"
              id="noNumber"
              disabled={!blockFields}
              onChange={() => {
                setValue('noNumber', !noNumber)
                setValue('number', undefined)
              }}
            >
              <PMedium marginBottom="0">Sem número</PMedium>
            </InterUICheckBox>
          </NumberContainer>

          <InterUIInputGroup margin="0 0 35px" disabled={!blockFields}>
            <label htmlFor="complement">Complemento</label>
            <input
              {...register('complement')}
              type="text"
              id="complement"
              disabled={!blockFields}
              autoComplete="off"
            />
          </InterUIInputGroup>

          <InterUIInputGroup
            margin="0 0 35px"
            disabled={!blockFields || blockCEP}
            readOnly={blockCEP}
          >
            <label htmlFor="neighborhood">Bairro</label>
            <input
              {...register('neighborhood')}
              type="text"
              id="neighborhood"
              readOnly={blockCEP}
              disabled={!blockFields || blockCEP}
              autoComplete="off"
            />
          </InterUIInputGroup>

          <InterUIInputGroup
            margin="0 0 35px"
            disabled={!blockFields || blockCEP}
            readOnly={blockFields || blockCEP}
          >
            <label htmlFor="district">Estado</label>
            <input
              {...register('district')}
              type="text"
              id="district"
              readOnly={blockFields || blockCEP}
              disabled={!blockFields || blockCEP}
              autoComplete="off"
            />
          </InterUIInputGroup>

          <InterUIInputGroup
            margin="0 0 35px"
            disabled={!blockFields || blockCEP}
            readOnly={blockFields || blockCEP}
          >
            <label htmlFor="city">Cidade</label>
            <input
              {...register('city')}
              type="text"
              id="city"
              readOnly={blockFields || blockCEP}
              disabled={!blockFields || blockCEP}
              autoComplete="off"
            />
          </InterUIInputGroup>
        </InterUIContainer>
      </form>

      <InterUIBottomSheet
        toggle={uncoveredZipCodeBS}
        onHide={() => setUncoveredZipCodeBS(false)}
        title={AddressMessages.TITLE_CEP_NOT_COVERAGE}
      >
        <PMedium scale={400}>{AddressMessages.MSG_CEP_NOT_COVERAGE}</PMedium>

        <Button margin="20px 0" onClick={anotherZipCode}>
          Informar outro CEP
        </Button>

        <Button variant="secondary" onClick={goBackHome}>
          Voltar para home
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

/**
 * Rotas disponíveis na simulação.
 */
export enum TypesSimulationRoutes {
  SIMULATION = '/simulation',
  ONBOARDING = '/simulation/onboarding',
  SELECT_ADDRESS = '/simulation/select-address',
  UPDATE_ADDRESS = '/simulation/update-address',
  SELECT_BOND = '/simulation/select-bond',
  SELECT_CLASS_ENTITY = '/simulation/select-class-entity',
  BIND_TO_ENTITY = '/simulation/bind-to-entity',
  HEALTH_PLAN_LIST = '/simulation/health-plan-list',
  HEALTH_PLAN_DETAILS = '/simulation/health-plan-details',
  WITHOUT_COVER = '/simulation/without-cover',
}

import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { InterUIContainer, InterUILoading } from '@interco/inter-ui-react-lib'

import { Button } from '../../../../components'
import { PageTitles } from '../../../../enums/pageTitles'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PdfContainer, LoadContainer } from './ProposalContract.styles'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { InterUIPdfReader } from '../../../../components/InterUIPdfReader/InterUIPdfReader'

export const ProposalContract: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const getProposalPDF = useSelector(ProposalActions.getProposalPDF)
  const [isShownPDF, setIsShownPDF] = useState<boolean>(false)

  useBasePage({
    navbarTitle: PageTitles.PROPOSAL_CONTRACT,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    setTimeout(() => {
      setIsShownPDF(true)
    }, 1000)
    if (getProposalPDF !== undefined && Object.entries(getProposalPDF).length === 0) {
      dispatch(ProposalActions.getProposalPDFRequest())
    }
  }, [dispatch])

  const sticky = (
    <Button
      margin="24px"
      onClick={() => {
        history.goBack()
      }}
    >
      Entendi
    </Button>
  )

  return isShownPDF && getProposalPDF?.proposalPDF ? (
    <InterUIContainer stickyFooter={sticky} margin="0px">
      <PdfContainer>
        <InterUIPdfReader
          file={getProposalPDF?.proposalPDF}
          height="100%"
          loadingMessage="Carregando Contrato..."
        />
      </PdfContainer>
    </InterUIContainer>
  ) : (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )
}

import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { InterUIIcon, InterUINavbar } from '@interco/inter-ui-react-lib'

import { NavbarActions } from '../../store/ducks/navbar/actions'
import { PersistedHiringActions } from '../../store/ducks/persistedHiring/actions'
import { PageDirections } from '../../routes/pageDirections'
import { TypesDocumentationRoutes } from '../../modules/documentation/routes/types'
import { BtnHome } from './Navbar.styles'
import BaseBridge from '../../config/bridge/BaseBridge'

export const Navbar: React.FC = () => {
  const theme = useTheme().colors
  const location = useLocation()
  const dispatch = useDispatch()

  const title = useSelector(NavbarActions.getTitle)
  const toggleHomeBtn = useSelector(NavbarActions.getToggleHomeBtn)
  const firstPage = useSelector(PersistedHiringActions.getFirstPage)
  const backNavigation = useSelector(NavbarActions.getBackNavigationHandler)

  const [className, setClassName] = useState<string>('')

  useEffect(() => {
    setClassName('')

    if (location.pathname === TypesDocumentationRoutes.SHIPPING_TIPS) {
      setClassName('orange-page')
    }
  }, [location.pathname])

  const onClickedBack = () => {
    dispatch(NavbarActions.setDirection(PageDirections.BACKWARD))

    if (location.pathname === firstPage) {
      BaseBridge.requestGoBack()
    } else {
      backNavigation()
    }

    setTimeout(() => {
      dispatch(NavbarActions.setDirection(PageDirections.FORWARD))
    }, 550)
  }

  const onClickedHome = () => BaseBridge.requestGoBack()

  const iconHome = (
    <BtnHome aria-label="Botão home" onClick={onClickedHome}>
      <InterUIIcon name="home" size="md" color={theme.primary.A500} />
    </BtnHome>
  )

  return (
    <InterUINavbar
      title={title}
      onClickedBack={onClickedBack}
      className={className}
      iconRight={toggleHomeBtn ? iconHome : undefined}
      data-testid="navbar-container"
    />
  )
}

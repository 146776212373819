import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { InterUIContainer, InterUIProgressBar } from '@interco/inter-ui-react-lib'

import { Button, DynamicField, FormWithHook } from '../../../../components'
import BaseBridge from '../../../../config/bridge/BaseBridge'
import { QuestionGroups } from '../../../../enums/DynamicFieldConfig'
import { PageTitles } from '../../../../enums/pageTitles'
import { ValidationMessages } from '../../../../enums/ValidationMessages'
import { BindingActions } from '../../../../store/ducks/binding/actions'
import { IBondField, IBondFieldGroup } from '../../../../store/ducks/binding/types'
import { NavbarActions } from '../../../../store/ducks/navbar/actions'
import { PSmall } from '../../../../styles/commons'
import { TypesBindingRoutes as Routes } from '../../routes/types'

interface IGroupInfo {
  navbarTitle: PageTitles
  step: number
  questions: IBondFieldGroup
  nextGroup: QuestionGroups

  backNavigation: () => void
}

export const DynamicQuestionsForm: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const currentGroup = useSelector(BindingActions.getCurrentGroupQuestions)
  const professional = useSelector(BindingActions.getQuestionsProfessional)
  const personal = useSelector(BindingActions.getQuestionsPersonal)
  const academic = useSelector(BindingActions.getQuestionsAcademic)

  const [groupInfo, setGroupInfo] = useState({} as IGroupInfo)
  const [defaultValues, setDefaultValues] = useState<Record<string, unknown>>()

  const methodsForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })

  useEffect(() => {
    const { navbarTitle, questions, nextGroup, backNavigation } = loadGroupInfo()

    if (questions?.fields?.length > 0) {
      dispatch(NavbarActions.setTitle(navbarTitle))

      dispatch(NavbarActions.setBackNavigationHandler(backNavigation))

      loadPreviousReplies(questions?.fields)
    } else if (nextGroup) {
      dispatch(BindingActions.setCurrentGroupQuestions(nextGroup))

      history.push(Routes.DYNAMIC_QUESTIONS)
    } else {
      history.push(Routes.ENTITY_TAX)
    }
  }, [])

  const loadGroupInfo = () => {
    const data = {} as IGroupInfo

    switch (currentGroup) {
      case QuestionGroups.PROFESSIONAL_DATA:
        data.navbarTitle = PageTitles.PROFESSIONAL_DATA
        data.step = 1
        data.questions = professional

        if (personal.fields?.length > 0) {
          data.nextGroup = QuestionGroups.PERSONAL_DATA
        } else if (academic.fields?.length > 0) {
          data.nextGroup = QuestionGroups.ACADEMIC_FORMATION
        }

        data.backNavigation = backNavigation(QuestionGroups.PROFESSIONAL_DATA)
        break
      case QuestionGroups.PERSONAL_DATA:
        data.navbarTitle = PageTitles.PERSONAL_DATA
        data.questions = personal

        if (academic.fields?.length > 0) {
          data.nextGroup = QuestionGroups.ACADEMIC_FORMATION
        }

        if (professional?.fields?.length > 0) {
          data.step = 2

          data.backNavigation = backNavigation(QuestionGroups.PROFESSIONAL_DATA)
        } else {
          data.step = 1

          data.backNavigation = backNavigation(QuestionGroups.PROFESSIONAL_DATA, Routes.BINDING)
        }
        break
      case QuestionGroups.ACADEMIC_FORMATION:
        data.navbarTitle = PageTitles.ACADEMIC_FORMATION
        data.questions = academic

        if (personal?.fields?.length > 0) {
          data.step = 3

          data.backNavigation = backNavigation(QuestionGroups.PERSONAL_DATA)
        } else if (professional?.fields?.length > 0) {
          data.step = 2

          data.backNavigation = backNavigation(QuestionGroups.PROFESSIONAL_DATA)
        } else {
          data.step = 1

          data.backNavigation = backNavigation(QuestionGroups.PROFESSIONAL_DATA, Routes.BINDING)
        }
        break
      default:
        break
    }

    setGroupInfo(data)

    return data
  }

  const backNavigation = (group: QuestionGroups, pathname?: string) => () => {
    dispatch(BindingActions.setCurrentGroupQuestions(group))

    pathname ? history.push(pathname) : history.goBack()
  }

  const loadPreviousReplies = (fields: IBondField[]) => {
    const formValues = {} as Record<string, unknown>

    fields.forEach((item) => {
      formValues[item.name] = item.value
    })

    setDefaultValues(formValues)
  }

  const setAnswers = (data: IBondFieldGroup, form: Record<string, unknown>) => {
    const answers = data.fields.map((item) => ({
      ...item,
      value: form[item.name],
    }))

    return {
      ...data,
      fields: answers,
    }
  }

  const onSubmit = (data: Record<string, unknown>) => {
    const newValue = setAnswers(groupInfo?.questions, data)
    const { nextGroup } = groupInfo

    if (currentGroup === QuestionGroups.PROFESSIONAL_DATA) {
      BaseBridge.requestAnalytics('C_PlanoSaude_DadosProf_T_Continuar')
      dispatch(BindingActions.setQuestionsProfessional(newValue as IBondFieldGroup))

      dispatch(BindingActions.setCurrentGroupQuestions(nextGroup))

      history.push(Routes.DYNAMIC_QUESTIONS)
    } else if (currentGroup === QuestionGroups.PERSONAL_DATA) {
      BaseBridge.requestAnalytics('C_PlanoSaude_DadosPessoais_T_Continuar')
      dispatch(BindingActions.setQuestionsPersonal(newValue as IBondFieldGroup))

      dispatch(BindingActions.setCurrentGroupQuestions(nextGroup))

      history.push(Routes.DYNAMIC_QUESTIONS)
    } else {
      BaseBridge.requestAnalytics('C_PlanoSaude_Formacao_T_Continuar')
      dispatch(BindingActions.setQuestionsAcademic(newValue as IBondFieldGroup))

      history.push(Routes.ENTITY_TAX)
    }
  }

  const formHeader = (
    <>
      <InterUIProgressBar
        progress={`${20 * groupInfo?.step}%`}
        aria-valuenow={2}
        aria-valuemin={1}
        aria-valuemax={5}
      />

      <InterUIContainer>
        <PSmall scale={300} bold>
          {`Passo ${groupInfo?.step}/5`}
        </PSmall>
      </InterUIContainer>
    </>
  )

  const stickyFooter = (
    <Button type="submit" margin="24px">
      Continuar
    </Button>
  )

  const renderFields = groupInfo?.questions?.fields.map((item) => (
    <DynamicField
      key={item.name}
      {...item}
      validation={{
        required: { value: true, message: ValidationMessages.REQUIRED },
      }}
    />
  ))

  return (
    <FormWithHook
      header={formHeader}
      stickyFooter={stickyFooter}
      methods={methodsForm}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    >
      {renderFields}
    </FormWithHook>
  )
}

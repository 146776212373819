import React from 'react'

import { Route } from 'react-router-dom'

import { TypesBindingRoutes as Routes } from './types'
import {
  BindingHome,
  EntityTax,
  DynamicQuestionsForm,
  Whatsapp,
  PendingConfirmation,
  ResentMessage,
  BindingConfirmed,
} from '../pages'

export const BindingRoutes: React.FC = () => (
  <>
    <Route path={Routes.BINDING} exact component={BindingHome} />
    <Route path={Routes.DYNAMIC_QUESTIONS} exact component={DynamicQuestionsForm} />
    <Route path={Routes.ENTITY_TAX} exact component={EntityTax} />
    <Route path={Routes.WHATSAPP} exact component={Whatsapp} />
    <Route path={Routes.PENDING_CONFIRMATION} exact component={PendingConfirmation} />
    <Route path={Routes.RESENT_MESSAGE} exact component={ResentMessage} />
    <Route path={Routes.BINDING_CONFIRMED} exact component={BindingConfirmed} />
  </>
)

export * from './types'

import { Reducer } from 'redux'

import { ErrorState, ErrorTypes } from './types'

const INITIAL_STATE: ErrorState = {
  toggle: false,
  viewedHero: undefined,
  numberAttempts: 0,
}

const reducer: Reducer<ErrorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorTypes.SHOW:
      return { ...state, toggle: true, numberAttempts: 0 }

    case ErrorTypes.HIDE:
      return { ...state, toggle: false, numberAttempts: 0 }

    case ErrorTypes.SET_VIEWED_HERO:
      return { ...state, viewedHero: action.payload }

    case ErrorTypes.SET_NUMBER_ATTEMPTS:
      return { ...state, numberAttempts: action.payload }

    default:
      return state
  }
}

export default reducer

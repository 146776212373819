import React, { useState } from 'react'

import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIIcon,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { IHealthPlan, IHealthPlanDetails } from '../../store/ducks/healthPlan/types'
import { IEntity } from '../../store/ducks/classEntity/types'

import { PlanCard, PlanDetailsBS } from '..'

import { DetailsContent } from './PlanDetails.styles'
import { BtnLink, ContainerFullPage, P, PMedium } from '../../styles/commons'
import { numberFormat } from '../../utils/commons'
import BaseBridge from '../../config/bridge/BaseBridge'

interface PlanDetailsProps {
  footer?: JSX.Element
  footerHeight?: string

  plan: IHealthPlan
  planDetails: IHealthPlanDetails

  showEntityInfo?: boolean
  entity?: IEntity

  showAdditionalBenefits?: boolean
  showAdditionalCoverages?: boolean
  showCollectivePlans?: boolean
}

export const PlanDetails: React.FC<PlanDetailsProps> = ({
  plan,
  planDetails,
  showEntityInfo = false,
  entity = {} as IEntity,
  showAdditionalBenefits = true,
  showAdditionalCoverages = true,
  showCollectivePlans = true,
  footer,
  footerHeight,
}) => {
  const theme = useTheme()

  const toggleBS = useState<boolean>(false)
  const [componentNameBS, setComponentNameBS] = useState<string>('')

  const showBS = (component: string) => {
    toggleBS[1](true)

    setComponentNameBS(component)
  }

  const iconBed = <InterUIIcon name="bed" size="md" />
  const iconLocation = <InterUIIcon name="location" size="md" />
  const iconWallet = <InterUIIcon name="wallet" size="md" />
  const iconTerminals = <InterUIIcon name="nearby-terminals" size="md" />
  const iconCashback = <InterUIIcon name="cashback" size="md" />
  const iconAgenda = <InterUIIcon name="agenda" size="md" />

  const iconCheck = (
    <InterUIIcon name="check-underlined" size="sm" color={theme.colors.primary.A500} />
  )

  const justNumbers = (waitingPeriodRules: { deadline: string; name: string; type: string }[]) => {
    let isJustNumber = true

    waitingPeriodRules.forEach((period) => {
      const numberFormated = Number(period.deadline)
      if (Number.isNaN(numberFormated)) {
        isJustNumber = false
      }
    })

    return isJustNumber
  }

  const coverageEntitiesInformation = () => {
    const municipalCoverage = (
      <>
        <P bold marginBottom="4px">
          Abrangência municipal
        </P>
        <PMedium scale={400}>
          A cobertura do atendimento é válida para o município ou grupo de municípios de abrangência
          do plano contratado.
        </PMedium>
      </>
    )

    const stateCoverage = (
      <>
        <P bold marginBottom="4px">
          Abrangência estadual
        </P>
        <PMedium scale={400}>
          A cobertura do atendimento é válida para todo o estado do plano contratado.
        </PMedium>
      </>
    )

    const nationalCoverage = (
      <>
        <P bold marginBottom="4px">
          Abrangência nacional
        </P>
        <PMedium scale={400}>
          A cobertura do atendimento é válida em todo o território nacional.
        </PMedium>
      </>
    )

    if (plan.coverage && plan.coverage.toUpperCase() === 'MUNICIPAL') {
      return municipalCoverage
    }
    if (plan.coverage?.toUpperCase() === 'ESTADUAL') {
      return stateCoverage
    }

    return nationalCoverage
  }
  return (
    <>
      <ContainerFullPage>
        <DetailsContent footerHeight={footerHeight}>
          <InterUIContainer>
            <PlanCard plan={plan} onClickedDetail={() => undefined} isDetails hasCashback />
          </InterUIContainer>

          {showEntityInfo && (
            <>
              <InterUISeparator height="8px" />

              <InterUIContainer margin="32px 24px 24px">
                <P margin="32px 0 0" bold>
                  Entidade de classe
                </P>

                <InterUIListDescription margin="24px 0 32px" iconLeft={iconLocation} role="group">
                  <PMedium bold>{entity?.tradingName}</PMedium>

                  <PMedium scale={400} marginBottom="16px">
                    {entity?.name}
                  </PMedium>

                  <PMedium scale={400} marginBottom="0">
                    Taxa de filiação de entidade de classe{' '}
                    <strong>
                      {entity?.membershipFee
                        ? `${numberFormat(entity?.membershipFee)}/mês`
                        : 'GRATUITA'}
                    </strong>
                  </PMedium>
                </InterUIListDescription>
              </InterUIContainer>
            </>
          )}

          <InterUISeparator height="8px" />

          <InterUIContainer margin="32px 24px 24px">
            {/* Acomodação */}
            <InterUIListDescription margin="32px 0 21px" iconLeft={iconBed} role="group">
              {plan.accommodation?.toUpperCase() === 'ENFERMARIA' ? (
                <>
                  <P bold marginBottom="4px">
                    Acomodação em enfermaria
                  </P>
                  <PMedium scale={400}>
                    A acomodação será em quarto coletivo, ou seja, dividida com outros pacientes.
                  </PMedium>
                </>
              ) : (
                <>
                  <P bold marginBottom="4px">
                    Acomodação em apartamento
                  </P>
                  <PMedium scale={400}>A acomodação será em quarto particular individual.</PMedium>
                </>
              )}
            </InterUIListDescription>

            <InterUISeparator variant="small" />

            {/* Coparticipação */}
            <InterUIListDescription margin="21px 0 24px" iconLeft={iconWallet} role="group">
              {plan.participation ? (
                <>
                  <P bold marginBottom="4px">
                    Mensalidade menor, adicionando o que usar (com coparticipação)
                  </P>
                  <PMedium scale={400}>
                    Sua mensalidade será menor, e você pagará um valor adicional por consultas,
                    exames e outros procedimentos.{' '}
                    <strong>Ideal para quem utiliza com menos frequência.</strong>
                  </PMedium>

                  {planDetails.participationRules?.length > 0 && (
                    <BtnLink
                      onClick={() => {
                        BaseBridge.requestAnalytics(' C_PlanoSaude_DetalhesPlano_T_ValorCopart')
                        showBS('participation')
                      }}
                    >
                      Ver valores de coparticipação
                    </BtnLink>
                  )}
                </>
              ) : (
                <>
                  <P bold marginBottom="4px">
                    Pagar por tudo na mensalidade (sem coparticipação)
                  </P>
                  <PMedium scale={400}>
                    Sua mensalidade será maior, e você não pagará nenhum valor adicional por
                    consultas, exames e outros procedimentos incluídos na cobertura do seu plano.{' '}
                    <strong>Ideal pra quem utiliza com mais frequência.</strong>
                  </PMedium>
                </>
              )}
            </InterUIListDescription>

            <InterUISeparator variant="small" />

            {/* Abrangência */}
            <InterUIListDescription margin="21px 0 24px" iconLeft={iconTerminals} role="group">
              {coverageEntitiesInformation()}

              {planDetails.referenceNetwork?.length > 0 && (
                <BtnLink
                  onClick={() => {
                    BaseBridge.requestAnalytics('C_PlanoSaude_DetalhesPlano_T_Hospitais')
                    showBS('references')
                  }}
                >
                  Ver hospitais e laboratórios inclusos
                </BtnLink>
              )}
            </InterUIListDescription>

            <InterUISeparator variant="small" />

            {/* Reembolso */}
            <InterUIListDescription margin="21px 0 24px" iconLeft={iconCashback} role="group">
              {plan.reimbursable ? (
                <>
                  <P bold marginBottom="4px">
                    Com reembolso
                  </P>
                  <PMedium scale={400}>
                    Se você realizar atendimentos ou procedimentos fora da rede médica e hospitalar
                    disponível em seu plano, poderá solicitar reembolso do valor gasto de acordo com
                    os limites estipulados pela operadora.
                  </PMedium>

                  {planDetails.reimbursementRules?.length > 0 && (
                    <BtnLink
                      onClick={() => {
                        BaseBridge.requestAnalytics('C_PlanoSaude_DetalhesPlano_T_ValorReemb')
                        showBS('reimbursable')
                      }}
                    >
                      Ver valores de reembolso
                    </BtnLink>
                  )}
                </>
              ) : (
                <>
                  <P bold marginBottom="4px">
                    Sem reembolso
                  </P>
                  <PMedium scale={400}>
                    Se você realizar atendimentos ou procedimentos fora da rede médica e hospitalar
                    disponível em seu plano, não será possível solicitar reembolso do valor gasto.
                  </PMedium>
                </>
              )}
            </InterUIListDescription>

            <InterUISeparator variant="small" />

            {/* Carência */}
            <InterUIListDescription margin="21px 0 32px" iconLeft={iconAgenda} role="group">
              <P bold marginBottom="4px">
                Carência
              </P>
              <PMedium scale={400}>
                Quanto tempo levará para você começar a ser atendido pelo plano de saúde em um
                determinado procedimento.
              </PMedium>

              {planDetails.waitingPeriodRules?.length > 0 &&
                justNumbers(planDetails.waitingPeriodRules) && (
                  <BtnLink
                    onClick={() => {
                      BaseBridge.requestAnalytics('C_PlanoSaude_DetalhesPlano_T_PrazoCarenc')
                      showBS('waitingPeriod')
                    }}
                  >
                    Ver prazos de carência
                  </BtnLink>
                )}
            </InterUIListDescription>
          </InterUIContainer>

          {showAdditionalBenefits && (
            <>
              <InterUISeparator height="8px" />

              <InterUIContainer>
                {/* Benefícios adicionais */}
                {planDetails?.benefits?.length > 0 && (
                  <>
                    <InterUIContainer margin="32px 0">
                      <P bold marginBottom="24px">
                        Benefícios adicionais
                      </P>

                      {planDetails?.benefits?.map((item, index) => {
                        const key = `benefits-${index}`

                        return (
                          <InterUIListDescription
                            key={key}
                            margin="16px 0"
                            iconLeft={iconCheck}
                            role="group"
                          >
                            <PMedium scale={400} marginBottom="0">
                              {item}
                            </PMedium>
                          </InterUIListDescription>
                        )
                      })}
                    </InterUIContainer>

                    <InterUISeparator variant="small" />
                  </>
                )}

                {/* Tipos de atendimento */}
                <InterUIContainer margin="32px 0">
                  <P bold marginBottom="24px">
                    Tipos de atendimento
                  </P>

                  <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
                    <PMedium scale={400} marginBottom="0">
                      Atendimento em consultório ou ambulatório
                    </PMedium>
                  </InterUIListDescription>

                  <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
                    <PMedium scale={400} marginBottom="0">
                      Todas as modalidades de internação hospitalares
                    </PMedium>
                  </InterUIListDescription>

                  <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
                    <PMedium scale={400} marginBottom="0">
                      Atendimento de urgência e emergência
                    </PMedium>
                  </InterUIListDescription>

                  <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
                    <PMedium scale={400} marginBottom="0">
                      Procedimentos de pré-natal
                    </PMedium>
                  </InterUIListDescription>

                  <InterUIListDescription margin="16px 0" iconLeft={iconCheck} role="group">
                    <PMedium scale={400} marginBottom="0">
                      Assistência ao parto e puerpério
                    </PMedium>
                  </InterUIListDescription>
                </InterUIContainer>
              </InterUIContainer>
            </>
          )}

          {showAdditionalCoverages && (
            <>
              {/* Coberturas adicionais */}
              {planDetails?.coverages?.length > 0 && (
                <>
                  <InterUISeparator height="8px" />

                  <InterUIContainer margin="32px 24px">
                    <P bold marginBottom="24px">
                      Coberturas adicionais
                    </P>

                    {planDetails?.coverages?.map((item, index) => {
                      const key = `coverages-${index}`

                      return (
                        <PMedium key={key} scale={400}>
                          {item}
                        </PMedium>
                      )
                    })}
                  </InterUIContainer>
                </>
              )}
            </>
          )}

          {showCollectivePlans && (
            <>
              <InterUISeparator height="8px" />

              {/* Planos coletivos */}
              <InterUIContainer margin="32px 24px 16px">
                <P bold marginBottom="24px">
                  Sobre planos coletivos
                </P>

                <PMedium scale={400} marginBottom="10px">
                  Planos de saúde coletivo são comercializados de acordo com a regra da ANS;
                </PMedium>

                <PMedium scale={400} marginBottom="10px">
                  A comercialização respeita a área de abrangência dos produtos de acordo com a
                  regra das operadoras;
                </PMedium>

                <PMedium scale={400} marginBottom="10px">
                  A disponibilidade pode variar de acordo com a região;
                </PMedium>

                <PMedium scale={400} marginBottom="10px">
                  As informações são de responsabilidade da operadora;
                </PMedium>

                <PMedium scale={400} marginBottom="10px">
                  A rede dos planos é uma amostra e pode sofrer alteração de acordo com a
                  necessidade da operadora sem aviso prévio mesmo após a adesão.
                </PMedium>
              </InterUIContainer>
            </>
          )}
        </DetailsContent>

        {footer}
      </ContainerFullPage>

      <PlanDetailsBS toggleState={toggleBS} componentName={componentNameBS} details={planDetails} />
    </>
  )
}

/**
 * Rotas disponíveis na vinculação.
 */
export enum TypesPaymentRoutes {
  PAYMENT = '/payment',
  CLOSE_ORDER = '/payment/close-order',
  EFFECTIVE_DATE = '/payment/effective-date',
  FORM_PAYMENT = '/payment/form-payment',
  PROPOSAL_CONTRACT = '/payment/proposal-contract',
  HEALTH_PLAN_DETAILS = '/payment/health-plan-details',
  RECEIPT = '/payment/receipt',
  READJUSTMENT_LETTER = '/payment/readjustment-letter',
}

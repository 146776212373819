import { all } from 'typed-redux-saga'

import watchPersistedHiring from './persistedHiring/saga'
import watchUser from './user/saga'
import watchAddress from './address/saga'
import watchClassEntity from './classEntity/saga'
import watchHealthPlan from './healthPlan/saga'
import watchBinding from './binding/saga'
import watchDPS from './dps/saga'
import watchProposal from './proposal/saga'
import watchPending from './pending/saga'

export default function* rootSaga() {
  return yield* all([
    watchPersistedHiring(),
    watchUser(),
    watchAddress(),
    watchClassEntity(),
    watchHealthPlan(),
    watchBinding(),
    watchDPS(),
    watchProposal(),
    watchPending(),
  ])
}

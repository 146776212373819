import React from 'react'

import { Route } from 'react-router-dom'

import { TypesPendingRoutes as Routes } from './types'
import {
  CheckPendings,
  HealthPlanDetails,
  UpdateInitialDate,
  UpdateAddress,
  ResolvedPendings,
} from '../pages'

export const PendingRoutes: React.FC = () => (
  <>
    <Route path={Routes.PENDING} exact component={HealthPlanDetails} />
    <Route path={Routes.CHECK_PENDINGS} exact component={CheckPendings} />
    <Route path={Routes.UPDATE_INITIAL_DATE} exact component={UpdateInitialDate} />
    <Route path={Routes.UPDATE_ADDRESS} exact component={UpdateAddress} />
    <Route path={Routes.RESOLVED_PENDINGS} exact component={ResolvedPendings} />
  </>
)

export * from './types'

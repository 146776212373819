import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUIIcon } from '@interco/inter-ui-react-lib'

import BaseBridge from '../../../../config/bridge/BaseBridge'
import { useBasePage } from '../../../../hooks/useBasePage'
import { PersistedHiringActions } from '../../../../store/ducks/persistedHiring/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { Button } from '../../../../components'
import { PSmall, Hero, H1 } from '../../../../styles/commons'
import { PageTitles } from '../../../../enums/pageTitles'
import { TypesSimulationRoutes } from '../../../simulation/routes'
import HeroImg from '../../../../assets/img/afro-personas-binoculars.png'

export const ContinueHiring: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme().colors

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_ContContratacao_T_Botao', {
      content_name: 'Continuar de onde parei',
    })
    dispatch(
      PersistedHiringActions.loadStoreWithData({
        history,
        pathname: '',
      }),
    )
  }

  const cancelProposal = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_ContContratacao_T_Botao', {
      content_name: 'Fazer uma nova simulação',
    })
    dispatch(
      ProposalActions.cancelProposalRequest({
        history,
        pathname: TypesSimulationRoutes.SELECT_ADDRESS,
      }),
    )
  }

  const stickyFooter = (
    <InterUIContainer>
      <Button alignContent="space-between" margin="0px 0px 16px" onClick={onContinue}>
        <p>Continuar de onde parei</p>

        <InterUIIcon name="arrow-right" size="md" color={theme.base.black} />
      </Button>

      <Button variant="secondary" alignContent="space-between" onClick={cancelProposal}>
        <p>Fazer uma nova simulação</p>

        <InterUIIcon name="arrow-right" size="md" color={theme.primary.A500} />
      </Button>
    </InterUIContainer>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <Hero>
        <img src={HeroImg} alt="Personas afro com binóculo" />
      </Hero>

      <InterUIContainer>
        <H1 marginBottom="8px">Vamos continuar sua contratação</H1>

        <PSmall scale={400}>
          Agora, você vai continuar a enviar pra gente os dados necessários pra contratar o plano
          escolhido. Vamos te levar pra onde você parou.
        </PSmall>
      </InterUIContainer>
    </InterUIContainer>
  )
}

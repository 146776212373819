import React from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Button, PlanDetails } from '../../../../components'
import { useBasePage } from '../../../../hooks/useBasePage'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { IHealthPlan, IHealthPlanDetails } from '../../../../store/ducks/healthPlan/types'
import { PageTitles } from '../../../../enums/pageTitles'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const HealthPlanDetails: React.FC = () => {
  const history = useHistory()

  const proposal = useSelector(ProposalActions.getProposal)
  const hasBond = useSelector(ClassEntityActions.getSelectedBond)
  const entity = useSelector(ClassEntityActions.getSelectedClassEntity)
  const planDetails = useSelector(HealthPlanActions.getHealthPlanDetails)

  useBasePage({
    navbarTitle: PageTitles.HEALTH_PLAN_DETAILS,
    backNavigationHandler: history.goBack,
  })

  const footer = (
    <Button
      margin="16px 24px 24px"
      onClick={() => {
        BaseBridge.requestAnalytics('C_PlanoSaude_T_Contratar')
        history.goBack()
      }}
    >
      Voltar à contratação
    </Button>
  )

  return (
    <PlanDetails
      plan={proposal.plan || ({} as IHealthPlan)}
      planDetails={planDetails || ({} as IHealthPlanDetails)}
      showEntityInfo={hasBond === 'N'}
      entity={entity}
      footer={footer}
    />
  )
}

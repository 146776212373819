import styled from 'styled-components'

interface StyledProps {
  margin?: string
  marginBottom?: string
  scale?: number
  bold?: boolean
  textAlign?: string
}

/**
 * Container a ser utilizado nas páginas que não possuem stick footer.
 */
export const ContainerFullPage = styled.div<StyledProps>`
  min-height: calc(100vh - 56px);

  ${(props) => props.margin && `margin: ${props.margin}`};
`

/**
 * Parágrafo com margin-bottom 8px, bold via props e cor.
 */
export const P = styled.p<StyledProps>`
  ${(props) => props.margin && `margin: ${props.margin}`};

  ${(props) => !props.margin && `margin-bottom: ${props.marginBottom || `8px`}`};

  ${(props) => props.scale === 300 && `color: ${props.theme.colors.neutral.grayscale.A300}`};

  ${(props) => props.scale === 400 && `color: ${props.theme.colors.neutral.grayscale.A400}`};

  ${(props) => props.bold && `font-weight: bold;`}

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`

/**
 * Parágrafo com font-size 10px.
 */
export const PXSmall = styled(P)`
  font-size: 0.625rem;
  line-height: 0.75rem;
`

/**
 * Parágrafo com font-size 12px.
 */
export const PSmall = styled(P)`
  font-size: 0.75rem;
  line-height: 0.9375rem;
`

/**
 * Parágrafo com font-size 14px.
 */
export const PMedium = styled(P)`
  font-size: 0.875rem;
  line-height: 1.063rem;
`

/**
 * Figure com largura padrão para personas.
 */
export const Hero = styled.figure`
  width: 204px;
  margin: 24px auto;
`

export const H1 = styled.h1<StyledProps>`
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`

export const H3 = styled.h3<StyledProps>`
  ${(props) => props.margin && `margin: ${props.margin};`}

  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`

/**
 * Título descritivo do step.
 */
export const StepHeading = styled.h3`
  margin: 24px 0 16px;
`

/**
 * Mensagem a ser inserida dentro do alerta.
 */
export const AlertMessage = styled.p<StyledProps>`
  display: block;
  font-size: 0.75rem;
  line-height: 0.9375rem;

  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.bold && `font-weight: bold;`}
`

/**
 * Botão com layout de link.
 */
export const BtnLink = styled.button.attrs({ type: 'button' })`
  color: ${(props) => props.theme.colors.primary.A500};
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-align: left;
`

/**
 * Mensagem de erro a exibida em casos de validaçoes de formulário
 */
export const ErrorMessage = styled.div`
  min-height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.feedback.error.A500};
`

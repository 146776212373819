import styled from 'styled-components'

import { PSmall } from '../../../../styles/commons'

export const MembershipFee = styled(PSmall)<{ free?: boolean }>`
  margin-bottom: 0;
  color: ${(props) =>
    props.free
      ? props.theme.colors.feedback.success.A500
      : props.theme.colors.neutral.grayscale.A400};
`

import { SagaWithHistory } from '../../utils/providerSaga'

export enum AddressTypes {
  SET_ADDRESS_LIST = `@address/SET_ADDRESS_LIST`,
  SET_SELECTED_ADDRESS = `@address/SET_SELECTED_ADDRESS`,
  GET_ADDRESS_BY_CEP = `@address/GET_ADDRESS_BY_CEP`,
  UPDATE_ADDRESS = `@address/UPDATE_ADDRESS`,
  SET_SEARCHED_ADDRESS = `@address/SET_SEARCHED_ADDRESS`,
}

export interface UpdateAddressRequest extends SagaWithHistory {
  address: IAddress
}

export interface IAddress {
  zipcode: string
  typeAddress: string
  address: string
  number?: number
  sendToSydle?: boolean
  complement: string
  neighborhood: string
  district: string
  city: string
}

export interface ISearchAddressRequest {
  zipcode: string
  syncActionWithSaga?: (data: IAddress) => void
}

export interface AddressState {
  readonly addressList: IAddress[]
  readonly selectedAddress?: IAddress
  readonly searchedAddress: IAddress
}

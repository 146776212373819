export function formatCep(e: string) {
  let value = e

  value = value.replace(/\D/g, '')
  value = value.replace(/^(\d{5})(\d)/, '$1-$2')

  return value
}

export function normalizeMask(value: string, mask: string) {
  try {
    let regString = '^'
    let rest = mask
    let count = 1
    let format = ''

    while (rest) {
      const from = rest.search('#')
      const nextSymbol = rest.substr(from).search(/[.\-/() ]/g)

      const leng = from + (nextSymbol > -1 ? nextSymbol : 0)

      if (leng > 0) {
        const symbolBegin = from > 0 ? rest.substr(0, from) : ''
        const symbolEnd = rest.substr(leng, 1)

        regString += `(\\d{${rest.substr(from, leng).length - from}})`
        format += `${symbolBegin}$${count}${symbolEnd}`

        rest = rest.substr(leng + 1)
      } else {
        regString += `(\\d{${rest.substr(from).length}})`
        format += `$${count}`

        rest = ''
      }

      count += 1
    }

    const regex = new RegExp(regString, 'g')

    return value.replace(regex, format)
  } catch {
    return value
  }
}

export function onlyNumber(event: React.ChangeEvent<HTMLInputElement>) {
  const cepValues = event.target.value
  event.target.value = cepValues.replace(/\D/g, '')
}

export function convertDateToBrazil(date: string) {
  try {
    const formatDate = new Date(date)

    return formatDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
  } catch {
    return date
  }
}

import styled from 'styled-components'

import { P } from '../../../../styles/commons'

export const Flex = styled.div<{
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  height?: string
  width?: string
  margin?: string
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
`

export const PhoneNumber = styled(P)`
  color: ${(props) => props.theme.colors.primary.A500};
`

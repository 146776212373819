/**
 * Títulos disponíveis na aplicação.
 */
export enum PageTitles {
  HEALTH_PLAN = 'Plano de saúde',
  ADDRESS = 'Endereço',
  CLASS_ENTITY = 'Entidade de classe',
  HEALTH_PLAN_DETAILS = 'Detalhes do plano',
  DOCUMENTS = 'Documentos',
  DOCUMENTATION = 'Documentação',
  PROFESSIONAL_DATA = 'Dados profissionais',
  PERSONAL_DATA = 'Dados pessoais',
  ACADEMIC_FORMATION = 'Formação',
  ENTITY_TAX = 'Taxa de filiação',
  MONTHLY_PAYMENT = 'Mensalidade da entidade',
  HISTORIC = 'Histórico',
  HEIGHT_WEIGHT = 'Altura e peso',
  DISEASES_AND_CONDITIONS = 'Doenças e condições',
  SEND_DOCUMENTATION = 'Envio de documentação',
  SHIPPING_TIPS = 'Dicas para o envio',
  PAYMENT = 'Pagamento',
  PENDINGS = 'Pendências',
  ORIENTATION_LETTER = 'Orientação ao beneficiário',
  PROPOSAL_CONTRACT = 'Contrato Plano de Saúde',
  READJUSTMENT_LETTER = 'Condições de Reajuste',
  UPDATE_INITIAL_DATE = 'Data de início',
  RECEIPT = 'Comprovante',
  EMPTY = '',
}

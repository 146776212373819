import styled from 'styled-components'

/**
 * Container em que deve ser exibido o pdf
 * 145px = 56px(header) + 89px(stickyFooter)
 */
export const PdfContainer = styled.div`
  height: calc(100vh - 145px);
`

export const LoadContainer = styled(PdfContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
`

import { combineReducers } from 'redux'

import address from './address'
import binding from './binding'
import classEntity from './classEntity'
import dps from './dps'
import error from './error'
import healthPlan from './healthPlan'
import loading from './loading'
import navbar from './navbar'
import pending from './pending'
import proposal from './proposal'
import user from './user'
import pathparams from './pathparams'
import { persistedPersistedHiring } from './persistedHiring'

const rootReducer = combineReducers({
  navbar,
  loading,
  error,
  user,
  address,
  classEntity,
  healthPlan,
  binding,
  dps,
  proposal,
  pending,
  pathparams,
  persistedHiring: persistedPersistedHiring,
})

export default rootReducer

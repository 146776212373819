import React, { useEffect, useLayoutEffect, useState } from 'react'

import { DefaultTheme, ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import { GlobalUIInterStyle, InterUIFonts, InterTheme } from '@interco/inter-ui-react-lib'

import NewRelicUtils from './config/monitoring/NewRelicUtils'
import { ErrorActions } from './store/ducks/error/actions'
import { Error, FallbackError, Navbar, Overlay } from './components'
import { GlobalStyle } from './styles/global-style'
import Routes from './routes'
import BaseBridge from './config/bridge/BaseBridge'
import { ThemesEnum } from './enums/ThemesEnum'

const App: React.FC = () => {
  const dispatch = useDispatch()

  const [theme, setTheme] = useState<DefaultTheme>(InterTheme(ThemesEnum.LIGHT))

  useLayoutEffect(() => {
    chooseTheme()
  }, [])

  useEffect(() => {
    NewRelicUtils.setInitSettings()
  }, [])

  const onErrorBoundary = (error: Error, info: { componentStack: string }) => {
    dispatch(ErrorActions.show())

    NewRelicUtils.noticeError(error, {
      errorCodeRef: info.componentStack,
    })
  }

  const chooseTheme = () => {
    BaseBridge.getAppInfo()
      .then((response) => {
        const isDarkMode = (response as unknown as { isDarkMode: boolean })?.isDarkMode

        isDarkMode ? setTheme(InterTheme(ThemesEnum.DARK)) : setTheme(InterTheme(ThemesEnum.LIGHT))
      })
      .catch(() => dispatch(ErrorActions.show()))
  }

  InterUIFonts()

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <GlobalUIInterStyle />
        <GlobalStyle />

        <ErrorBoundary FallbackComponent={FallbackError} onError={onErrorBoundary}>
          <Overlay show={false} />
          <BrowserRouter>
            <Error />
            <Navbar />
            <Routes />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    )
  )
}

export default App

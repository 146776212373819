/**
 * Rotas disponíveis na vinculação.
 */
export enum TypesDocumentationRoutes {
  DOCUMENTATION = '/documentation',
  HISTORIC = '/documentation/historic',
  HEIGHT_WEIGHT = '/documentation/height-weight',
  DISEASES_CONDITIONS_FORM = '/documentation/diseases-conditions-form',
  DOCUMENT_LIST = '/documentation/list',
  SEND_DOCUMENTATION = '/documentation/send-documentation',
  SHIPPING_TIPS = '/documentation/shipping-tips',
  FILE_DOCUMENTATION = '/documentation/file-documentation',
  ORIENTATION_LETTER = '/documentation/orientation-letter',
  CONFIRM_DPS_OFFLINE = '/documentation/confirm-dps-offline',
  SHUTDOWN_DPS_OFFLINE = '/documentation/shutdown-dps-offline',
}

import React, { useEffect } from 'react'

import { FieldValues, UseFormReturn } from 'react-hook-form'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

interface FormWithHookConfig {
  methods: UseFormReturn<FieldValues>
  header?: JSX.Element
  stickyFooter: JSX.Element
  defaultValues?: Record<string, unknown>
  margin?: string

  onSubmit: (data: Record<string, unknown>) => void
}

/**
 * @param header Elemento Jsx que virá acima do fomulário;
 * @param stickyFooter Elemento Jsx que virá no final do formulário, geralmente um botão;
 * @param methods Definição do useForm customizada,
 * caso não informado o componente já provê uma versão default;
 * @param defaultValues Preenche os campos gerenciáveis do formulário com valores defaults;
 * Essa propriedade NÃO deve ser provida caso o parametro methods seja provido.
 * Nesse caso o defaultValues deve ser informado na definição da origem do useForm;
 * @param margin Margem a ser adicionada no formulário @default '24px'
 * @function onSubmit função a ser chamada caso o formulário esteja válido à ser submetido;
 * @returns Componente de formulário que usa a lib react-hook-form
 * no gerenciamento de seus campos;
 */
export const FormWithHook: React.FC<FormWithHookConfig> = ({
  margin = '24px',
  header,
  children,
  stickyFooter,
  methods,
  defaultValues,
  onSubmit,
}) => {
  const { handleSubmit, reset, formState } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        {header}

        <InterUIContainer margin={margin}>
          {children &&
            (children as JSX.Element[]).map((child) =>
              child?.props.name
                ? React.createElement(child.type, {
                    ...{
                      ...child.props,
                      register: methods.register,
                      setValue: methods.setValue,
                      getValues: methods.getValues,
                      errors: formState?.errors[child.props.name],
                      key: child.props.name,
                    },
                  })
                : child,
            )}
        </InterUIContainer>
      </InterUIContainer>
    </form>
  )
}

import { AnyAction } from 'redux'
import { call, put, select, take, takeLatest } from 'typed-redux-saga'

import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga'
import {
  BindingStepData,
  DocumentationStepData,
  DPSStepData,
  PersistedHiringTypes,
  SimulationStepData,
} from './types'
import ProposalService from '../../../services/ProposalService'
import { UserActions } from '../user/actions'
import { AddressTypes } from '../address/types'
import { PersistedHiringActions } from './actions'
import { LoadingActions } from '../loading/actions'
import { AddressActions } from '../address/actions'
import { ClassEntityActions } from '../classEntity/actions'
import { HealthPlanActions } from '../healthPlan/actions'
import { BindingActions } from '../binding/actions'
import { DPSActions } from '../dps/actions'
import { IDocsAttached } from '../dps/types'
import { ProposalActions } from '../proposal/actions'
import { getTimestampForPersistence, persistenceIsValid } from '../../../utils/commons'

function* setSimulationState({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const address = yield* select(AddressActions.getSelectedAddress)
    const bond = yield* select(ClassEntityActions.getSelectedBond)
    const occupation = yield* select(ClassEntityActions.getSelectedOccupation)
    const entity = yield* select(ClassEntityActions.getSelectedClassEntity)
    const plan = yield* select(HealthPlanActions.getSelectedHealthPlan)
    const planDetails = yield* select(HealthPlanActions.getHealthPlanDetails)
    const bindingDoc = yield* select(BindingActions.getDocumentsList)

    if (address && bond && occupation && entity && plan && planDetails) {
      const timestamp = getTimestampForPersistence()

      const simulation = {
        selectedAddress: address,
        selectedBond: bond,
        selectedOccupation: occupation,
        selectedEntity: entity,
        selectedHealthPlan: plan,
        selectedPlanDetails: planDetails,
        bindingDoc: bindingDoc?.length > 0 ? bindingDoc : undefined,
      }

      yield* put(PersistedHiringActions.setTimestamp(timestamp))

      yield* put(PersistedHiringActions.setSimulationState(simulation))

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'PersistedHiringActionsSaga.setSimulationState')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PersistedHiringActionsSaga.setSimulationState')
  }
}

function* setBindingState({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const documentList = yield* select(BindingActions.getDocumentsList)

    if (documentList) {
      const timestamp = getTimestampForPersistence()

      const binding = { documentList }

      yield* put(PersistedHiringActions.setTimestamp(timestamp))

      yield* put(PersistedHiringActions.setBindingState(binding))

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'PersistedHiringActionsSaga.setBindingState')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PersistedHiringActionsSaga.setBindingState')
  }
}

function* setDPSState({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const documentList = yield* select(DPSActions.getDocumentsList)

    if (documentList) {
      const timestamp = getTimestampForPersistence()
      const docsAttached: IDocsAttached[] = []

      documentList.forEach((item) => docsAttached.push({ ...item, files: [] }))

      const dpsDoc = { documentList, docsAttached }

      yield* put(PersistedHiringActions.setTimestamp(timestamp))

      yield* put(PersistedHiringActions.setDPSState(dpsDoc))

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'PersistedHiringActionsSaga.setDPSState')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PersistedHiringActionsSaga.setDPSState')
  }
}

function* setDocumentationState({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const documentList = yield* select(DPSActions.getDocumentsList)

    if (documentList) {
      const timestamp = getTimestampForPersistence()

      const dpsDoc = { documentList }

      yield* put(PersistedHiringActions.setTimestamp(timestamp))

      yield* put(PersistedHiringActions.setDocumentationState(dpsDoc))

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'PersistedHiringActionsSaga.setDocumentationState')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PersistedHiringActionsSaga.setDocumentationState')
  }
}

function* validatePersistence({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    yield* put(UserActions.getUserDetailsRequest())

    yield take(AddressTypes.SET_ADDRESS_LIST)

    const persisted = yield* select(PersistedHiringActions.getAllState)
    const { timestamp, simulation } = persisted

    try {
      const response = yield* call(ProposalService.getProposal)

      yield* put(ProposalActions.setProposal(response.data))
    } catch {
      yield* put(PersistedHiringActions.clearAllState())
    }

    if (!timestamp || (timestamp && !persistenceIsValid(timestamp)) || !simulation) {
      yield* put(PersistedHiringActions.clearAllState())
    }

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PersistedHiringActionsSaga.validatePersistence')
  }
}

function* loadStoreWithData({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const persisted = yield* select(PersistedHiringActions.getAllState)
    const { simulation, binding, dps, documentation } = persisted

    let redirect = ''

    if (simulation) {
      const { selectedBond } = simulation

      yield* call(fillSimulationData, simulation)

      redirect = selectedBond === 'N' ? '/binding' : '/documentation'
    }

    if (binding) {
      yield* call(fillBinding, binding)

      redirect = '/documentation'
    }

    if (dps) {
      yield* call(fillDPSData, dps)

      redirect = '/documentation/list'
    }

    if (documentation) {
      yield* call(fillDocumentationData, documentation)

      redirect = '/payment/close-order'
    }

    yield* put(PersistedHiringActions.setFirstPage(redirect))

    yield* put(LoadingActions.hide())

    redirectThroughSaga({ ...payload, pathname: redirect })
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'PersistedHiringActionsSaga.loadStoreWithData')
  }
}

function* fillSimulationData(data: SimulationStepData) {
  const {
    selectedAddress,
    selectedBond,
    selectedOccupation,
    selectedEntity,
    selectedHealthPlan,
    selectedPlanDetails,
    bindingDoc,
  } = data

  yield* put(AddressActions.setSelectedAddress(selectedAddress))
  yield* put(ClassEntityActions.setSelectedBond(selectedBond))
  yield* put(ClassEntityActions.setSelectedOccupation(selectedOccupation))
  yield* put(ClassEntityActions.setSelectedClassEntity(selectedEntity))
  yield* put(HealthPlanActions.setSelectedHealthPlan(selectedHealthPlan))
  yield* put(HealthPlanActions.setHealthPlanDetails(selectedPlanDetails))

  if (bindingDoc) {
    yield* put(BindingActions.setDocumentsList(bindingDoc))
  }
}

function* fillBinding(data: BindingStepData) {
  const { documentList } = data

  yield* put(BindingActions.setDocumentsList(documentList))
}

function* fillDPSData(data: DPSStepData) {
  const { documentList, docsAttached } = data

  yield* put(DPSActions.setDocumentsList(documentList))
  yield* put(DPSActions.setDocumentsAttached(docsAttached))
}

function* fillDocumentationData(data: DocumentationStepData) {
  const { documentList } = data

  yield* put(DPSActions.setDocumentsList(documentList))

  const response = yield* call(ProposalService.getProposalPDFContract)

  yield* put(ProposalActions.setProposalPDF(response.data))
}

export default function* watchPersistedHiring() {
  yield* takeLatest(PersistedHiringTypes.SET_SIMULATION_STATE_REQUEST, setSimulationState)
  yield* takeLatest(PersistedHiringTypes.SET_BINDING_STATE_REQUEST, setBindingState)
  yield* takeLatest(PersistedHiringTypes.SET_DPS_STATE_REQUEST, setDPSState)
  yield* takeLatest(PersistedHiringTypes.SET_DOC_STATE_REQUEST, setDocumentationState)
  yield* takeLatest(PersistedHiringTypes.VALIDATE_PERSISTENCE, validatePersistence)
  yield* takeLatest(PersistedHiringTypes.LOAD_STORE_WITH_DATA, loadStoreWithData)
}

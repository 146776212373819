import { Reducer } from 'redux'

import {
  ICheckoutProposal,
  IProposal,
  ProposalPDFResponse,
  ProposalState,
  ProposalTypes,
} from './types'

const INITIAL_STATE: ProposalState = {
  proposal: {} as IProposal,
  effectiveDate: '',
  formPayment: undefined,
  checkoutProposal: {} as ICheckoutProposal,
  proposalPDF: {} as ProposalPDFResponse,
}

const reducer: Reducer<ProposalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProposalTypes.SET_PROPOSAL:
      return { ...state, proposal: action.payload }

    case ProposalTypes.SET_EFFECTIVE_DATE:
      return { ...state, effectiveDate: action.payload }

    case ProposalTypes.SET_FORM_PAYMENT:
      return { ...state, formPayment: action.payload }

    case ProposalTypes.SET_CHECKOUT_PROPOSAL:
      return { ...state, checkoutProposal: action.payload }

    case ProposalTypes.SET_PROPOSAL_PDF:
      return { ...state, proposalPDF: action.payload }

    default:
      return state
  }
}

export default reducer

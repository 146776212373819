import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUICheckBox,
  InterUIContainer,
  InterUIProgressBar,
  InterUIRadio,
} from '@interco/inter-ui-react-lib'

import { useBasePage } from '../../../../hooks/useBasePage'
import { ClassEntityActions } from '../../../../store/ducks/classEntity/actions'
import { BindingActions } from '../../../../store/ducks/binding/actions'
import { IEntity } from '../../../../store/ducks/classEntity/types'
import { Button } from '../../../../components/Button/Button'
import { PanelTitle, PanelMessage, AcceptTermsMessage } from './EntityTax.styles'
import { PMedium, PSmall } from '../../../../styles/commons'
import { MembershipFee } from '../../../simulation/pages/BindToEntity/BindToEntity.styles'
import { PageTitles } from '../../../../enums/pageTitles'
import { numberFormat } from '../../../../utils/commons'
import { QuestionGroups } from '../../../../enums/DynamicFieldConfig'
import { TypesBindingRoutes as Routes } from '../../routes/types'
import { LoadingActions } from '../../../../store/ducks/loading/actions'
import BaseBridge from '../../../../config/bridge/BaseBridge'

export const EntityTax: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const selectedEntity = useSelector(ClassEntityActions.getSelectedClassEntity)
  const professional = useSelector(BindingActions.getQuestionsProfessional)
  const personal = useSelector(BindingActions.getQuestionsPersonal)
  const academic = useSelector(BindingActions.getQuestionsAcademic)
  const loading = useSelector(LoadingActions.get)

  const [bottomSheet, setBottomSheet] = useState<boolean>(false)
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const [entity, setEntity] = useState<IEntity>(selectedEntity || ({} as IEntity))

  const backNavigationHandler = () => {
    let currentGroup: QuestionGroups | null = null

    if (academic?.fields?.length > 0) {
      currentGroup = QuestionGroups.ACADEMIC_FORMATION
    } else if (personal?.fields?.length > 0) {
      currentGroup = QuestionGroups.PERSONAL_DATA
    } else if (professional?.fields?.length > 0) {
      currentGroup = QuestionGroups.PROFESSIONAL_DATA
    }

    if (currentGroup) {
      dispatch(BindingActions.setCurrentGroupQuestions(currentGroup))

      history.goBack()
    } else {
      history.push(Routes.BINDING)
    }
  }

  useBasePage({
    navbarTitle:
      entity && entity.tradingName !== 'UNE' ? PageTitles.ENTITY_TAX : PageTitles.MONTHLY_PAYMENT,
    backNavigationHandler,
  })

  const onContinue = () => {
    BaseBridge.requestAnalytics('C_PlanoSaude_ConfirFiliacao_T_Autenticar')

    if (!loading) {
      setBottomSheet(false)
    }

    dispatch(
      BindingActions.createFiliation({
        history,
        pathname:
          entity && entity.tradingName !== 'UNE' ? Routes.BINDING_CONFIRMED : Routes.WHATSAPP,
      }),
    )
  }

  const stickyFooter = (
    <>
      <InterUICheckBox
        margin="0 24px 6px"
        name="accept_term"
        id="accept_term"
        onChange={() => setAcceptTerms(!acceptTerms)}
        checked={acceptTerms}
      >
        <AcceptTermsMessage>
          {!entity.membershipFee
            ? 'Declaro que forneci informações verdadeiras.'
            : 'Declaro que forneci informações verdadeiras e entendi que a taxa de filiação será cobrada junto com a mensalidade do plano de saúde.'}
        </AcceptTermsMessage>
      </InterUICheckBox>

      <Button
        margin="0 24px 24px"
        disabled={!acceptTerms}
        onClick={() => {
          BaseBridge.requestAnalytics('C_PlanoSaude_TaxaFiliacao_T_Continuar')
          setBottomSheet(true)
        }}
      >
        Continuar
      </Button>
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar progress="100%" aria-valuenow={5} aria-valuemin={1} aria-valuemax={5} />
      <InterUIContainer>
        <PSmall scale={300} bold>
          Passo 5/5
        </PSmall>

        <InterUIRadio
          variant="choose-item"
          name="entity"
          id="entity"
          value="entity"
          checked
          onChange={() => setEntity(entity)}
        >
          {entity?.name ? (
            <>
              <PMedium bold marginBottom="4px">
                {entity.tradingName}
              </PMedium>

              <PSmall scale={400} marginBottom="8px">
                {entity.name}
              </PSmall>

              <MembershipFee>Taxa de filiação da entidade de classe</MembershipFee>

              {!entity.membershipFee ? (
                <MembershipFee bold free>
                  GRATUITA
                </MembershipFee>
              ) : (
                <MembershipFee bold>{`${numberFormat(entity.membershipFee)}/mês`}</MembershipFee>
              )}
            </>
          ) : null}
        </InterUIRadio>

        {entity.membershipFee ? (
          <InterUIAlert type="attention">
            <PanelTitle>Mensalidades unificadas</PanelTitle>

            <PanelMessage>
              Você pagará a mensalidade dessa filiação junto com a mensalidade do seu plano de
              saúde, em uma única cobrança mensal.
            </PanelMessage>
          </InterUIAlert>
        ) : null}
      </InterUIContainer>
      <InterUIBottomSheet
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
        title="Confirmar filiação"
      >
        <PMedium scale={400}>Para seguir, é preciso autenticar sua filiação pelo i-safe.</PMedium>
        <Button margin="20px 0" onClick={onContinue}>
          Autenticar
        </Button>
      </InterUIBottomSheet>
    </InterUIContainer>
  )
}

import React from 'react'

import { useSelector } from 'react-redux'

import { InterUIInputGroup, IInterUIInputGroupProps } from '@interco/inter-ui-react-lib'

import { LoadingActions } from '../../store/ducks/loading/actions'

export const InputGroup: React.FC<IInterUIInputGroupProps> = ({ children, ...props }) => {
  const loading = useSelector(LoadingActions.get)

  return (
    <InterUIInputGroup {...props} loading={loading}>
      {children}
    </InterUIInputGroup>
  )
}

import styled from 'styled-components'

export const LoadingContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 48px 29px 24px;

  .cashback-message {
    font-size: 0.75rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.feedback.success.A500};

    margin-left: 16px;
    line-height: 14.52px;
  }

  .icon {
    justify-content: flex-end;
    display: flex;
    flex: 1;
  }
`

export const AroundIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  padding: 12px;
  background-color: ${(props) => props.theme.colors.feedback.success.A100};

  .cashback-icon {
    height: 23.9px;
    width: 24px;

    margin-top: 0.1rem;

    text-align: center;
  }
`

import React from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIIcon,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { HealthPlanActions } from '../../../../store/ducks/healthPlan/actions'
import { ProposalActions } from '../../../../store/ducks/proposal/actions'
import { H3, PSmall } from '../../../../styles/commons'
import { TypesPendingRoutes as Routes } from '../../routes/types'

export const UpdateEffectiveDate: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { colors } = useTheme()

  const effectiveDate = useSelector(ProposalActions.getEffectiveDate)

  const goToUpdateInitialDatePage = () => {
    dispatch(
      HealthPlanActions.getAvailableEffectiveDatesRequest({
        history,
        pathname: Routes.UPDATE_INITIAL_DATE,
      }),
    )
  }

  const iconLeft = <InterUIIcon name="agenda" size="md" color={colors.neutral.grayscale.A500} />

  const iconRight = (
    <InterUIIcon
      className="icon"
      name="arrow-chevron-right"
      size="md"
      color={colors.primary.A500}
    />
  )

  return (
    <>
      <InterUIContainer margin="32px 24px 8px 24px">
        <H3>Atualize a data de início do seu plano:</H3>

        <InterUIListDescription
          role="link"
          margin="24px 0"
          iconLeft={iconLeft}
          iconRight={iconRight}
          onClick={goToUpdateInitialDatePage}
        >
          {effectiveDate ? (
            <>
              <PSmall scale={400} marginBottom="4px">
                Data de início da vigência
              </PSmall>
              <PSmall bold marginBottom="0">
                {effectiveDate}
              </PSmall>
            </>
          ) : (
            <PSmall bold marginBottom="0">
              Data de início e do débito mensal do plano
            </PSmall>
          )}
        </InterUIListDescription>
      </InterUIContainer>

      <InterUISeparator />
    </>
  )
}

import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../../utils/providerSaga'
import {
  DPSTypes,
  IDocsAttached,
  IDPSDoc,
  IDPSQuiz,
  IDPSQuizAnswers,
  IHeightWeight,
  IQuizStep,
} from './types'

const getQuizInfoRequest = (data?: SagaWithHistory) =>
  action(DPSTypes.GET_DISEASES_CONDITIONS_QUESTIONS, data)

const getQuizInfo = (state: RootState) => state.dps.quiz

const getQuizList = (state: RootState) => state.dps.quiz?.quiz

const setQuizInfo = (data: IDPSQuiz) => action(DPSTypes.SET_DISEASES_CONDITIONS_QUESTIONS, data)

const getQuizStep = (state: RootState) => state.dps.quizStep

const setQuizStep = (data: IQuizStep) => action(DPSTypes.SET_QUIZ_STEP, data)

const getQuizAnswers = (state: RootState) => state.dps.quizAnswers

const setQuizAnswers = (data: IDPSQuizAnswers) =>
  action(DPSTypes.SET_DISEASES_CONDITIONS_ANSWERS, data)

const sendQuizAnswersRequest = (data?: SagaWithHistory) => action(DPSTypes.SEND_QUIZ_ANSWERS, data)

const getDocumentsListRequest = (data?: SagaWithHistory) =>
  action(DPSTypes.GET_DOCUMENTS_LIST, data)

const setDocumentGroup = (data: string) => action(DPSTypes.SET_DOCUMENT_GROUP, data)

const getDocumentGroup = (state: RootState): string | undefined => state.dps.documentGroup

const getDocumentsList = (state: RootState) => state.dps.documentsList

const setDocumentsList = (data: IDPSDoc[]) => action(DPSTypes.SET_DOCUMENTS_LIST, data)

const getHeightWeight = (state: RootState) => state.dps.heightWeight

const setHeightWeight = (data: IHeightWeight) => action(DPSTypes.SET_HEIGHT_WEIGHT, data)

const hasPreviousPlan = (state: RootState) => state.dps.hasPreviousPlan

const setHasPreviousPlan = (data: boolean) => action(DPSTypes.SET_HAS_PREVIOUS_PLAN, data)

const setDocumentsAttached = (data: IDocsAttached[]) =>
  action(DPSTypes.SET_DOCUMENTS_ATTACHED, data)

const getDocumentsAttached = (state: RootState) => state.dps.documentsAttached

const setSelectedDocAttached = (data: IDocsAttached) =>
  action(DPSTypes.SET_SELECTED_DOC_ATTACHED, data)

const getSelectedDocAttached = (state: RootState) => state.dps.selectedDocAttached

const sendDocumentsAttachedRequest = (data?: SagaWithHistory) =>
  action(DPSTypes.SEND_DOCUMENTS_ATTACHED, data)

export const DPSActions = {
  getQuizInfoRequest,
  getQuizInfo,
  getQuizList,
  setQuizInfo,
  getQuizStep,
  setQuizStep,
  getQuizAnswers,
  setQuizAnswers,
  sendQuizAnswersRequest,
  getDocumentsListRequest,
  getDocumentsList,
  setDocumentsList,
  getHeightWeight,
  setHeightWeight,
  hasPreviousPlan,
  setHasPreviousPlan,
  setDocumentsAttached,
  getDocumentsAttached,
  setSelectedDocAttached,
  getSelectedDocAttached,
  setDocumentGroup,
  getDocumentGroup,
  sendDocumentsAttachedRequest,
}

import styled from 'styled-components'

export const Content = styled.div<{ padding?: string; borderBottom?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => props.padding && `padding: ${props.padding};`}

  ${(props) =>
    props.borderBottom && `border-bottom: 1px solid ${props.theme.colors.neutral.grayscale.A200};`}
`

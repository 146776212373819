import { action } from 'typesafe-actions'

import { RootState } from '../..'
import {
  ClassEntityTypes,
  GetEntitiesRequest,
  IClassEntityInfo,
  IEntity,
  IOccupation,
} from './types'
import { SagaWithHistory } from '../../utils/providerSaga'

const setSelectedBond = (data: string) => action(ClassEntityTypes.SET_SELECTED_BOND, data)

const getSelectedBond = (state: RootState) => state.classEntity.selectedBond

const getListOccupationsRequest = (data?: SagaWithHistory) =>
  action(ClassEntityTypes.GET_LIST_OCCUPATIONS, data)

const getListOccupations = (state: RootState) => state.classEntity.listOccupations

const setListOccupations = (data: IOccupation[]) =>
  action(ClassEntityTypes.SET_LIST_OCCUPATIONS, data)

const setSelectedOccupation = (data: IOccupation) =>
  action(ClassEntityTypes.SET_SELECTED_OCCUPATION, data)

const getSelectedOccupation = (state: RootState) => state.classEntity.selectedOccupation

const getListEntitiesRequest = (data?: GetEntitiesRequest) =>
  action(ClassEntityTypes.GET_LIST_CLASS_ENTITIES, data)

const getListEntities = (state: RootState) => state.classEntity.listEntities

const setListEntities = (data: IEntity[]) => action(ClassEntityTypes.SET_LIST_CLASS_ENTITIES, data)

const setSelectedClassEntity = (data: IEntity | null) =>
  action(ClassEntityTypes.SET_SELECTED_CLASS_ENTITY, data)

const getSelectedClassEntity = (state: RootState) => state.classEntity.selectedEntity

const getProposalClassEntityRequest = (data: IClassEntityInfo) =>
  action(ClassEntityTypes.GET_PROPOSAL_CLASS_ENTITY, data)

export const ClassEntityActions = {
  setSelectedBond,
  getSelectedBond,
  getListOccupationsRequest,
  getListOccupations,
  setListOccupations,
  setSelectedOccupation,
  getSelectedOccupation,
  getListEntitiesRequest,
  getListEntities,
  setListEntities,
  setSelectedClassEntity,
  getSelectedClassEntity,
  getProposalClassEntityRequest,
}
